import React from 'react';
import { usePepperContext } from '../../utils/GlobalState';
import FeedList from './FeedList';
import FeedForm from './FeedForm';

const FeedsRender = () => {
  const [{ userdata }] = usePepperContext();

  return (
    <div className="p-4">
      <FeedForm />
      {userdata.userfeeds && userdata.userfeeds.length > 0 ? (
        <FeedList data={userdata.userfeeds} />
      ) : null}
    </div>
  );
};

export default FeedsRender;
