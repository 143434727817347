import React, { useEffect } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import {
  MyPersonalInfo,
  ChangeMyPassword,
  ChangeMyProfilePicture,
} from './ProfileCard'
import Upload from './Upload'
// Query User
import { useQuery } from '@apollo/client'
import { QUERY_ME } from '../../utils/queries'
import { USER_DATA } from '../../utils/actions'
import './profile.scss'
import { useAuthContext } from '../../utils/AuthContext'


// Import globalstate
import { usePepperContext } from '../../utils/GlobalState'
import { Link } from 'react-router-dom'

const Profile = () => {
  // Global State Context
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = usePepperContext()
  const { loading, data, refetch } = useQuery(QUERY_ME)
  const { isAuthenticated } = useAuthContext()
  const loggedIn = isAuthenticated;
  // const { loading, data} = useQuery(QUERY_ME);
  // onload, check if data needs update
  useEffect(() => {
    if (data) {
      dispatch({
        type: USER_DATA,
        userdata: data,
      })
    }
  }, [data, dispatch])

  // Fetch data and declare variable user
  const user = data?.me || data?.user || {}
  const fullName = user.username
  if (loading) {
    return (
      <div className='text-center py-5'>
        <Spinner animation='border' variant='success' />
      </div>
    )
  }


  return (
    loggedIn ? (
    <div className=' my-5'>
      <div id='profile'>
        <Card>
          <div>
            <div className='profile-image text-center '>
              <div className='text-center'>
                <Upload
                  defaultImage={user.profilePicture}
                  className='profile-img'
                  name={fullName}
                  userID={user._id}
                  picture={user.profilePic}
                />
              </div>
            </div>
          </div>
          <div className='py-4 profiles'>
            <MyPersonalInfo
              fullName={fullName}
              email={user.email}
              phoneNumber={user.phoneNumber}
              refetch={refetch}
            />
            {!user.googleUser ? <ChangeMyPassword /> : null }
            <ChangeMyProfilePicture myPic={user.profilePic} refetch={refetch} />
          </div>
          <div id='text-save' className='text-right pt-3 pb-5 mr-4'>
          </div>
        </Card>
      </div>
    </div>
    ) : (
      <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of this page.</h4> 
    )
  )
}

export default Profile
