import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login (email: $email, password: $password) {
      token
    }
  }
`;
// v1

export const LOGOUT = gql`
  mutation {
    logout {
      token
    }
  }
`

// ADD MUTATIONS
export const ADD_USER = gql`
  mutation adduser ($firstName: String!, $lastName: String!, $email: String!, $password: String!, $uniqueid: String!) {
  adduser (firstName: $firstName, lastName: $lastName, email:$email, password: $password, uniqueid: $uniqueid ) {
    firstName
  }
}
`;
// v1

// mutation addoauthuser($FirstName: String!, $LastName: String!, $Email: String!, $Password: String!) {
//   addoauthuser(firstName: $FirstName, lastName: $LastName, email: $Email, password: $Password) {
//     token
//   }
// }

export const ADD_OAUTH_USER = gql`
  mutation addoauthuser ($firstName: String!, $lastName: String!, $email: String!, $profilePic: String, $password: String!) {
  addoauthuser (firstName: $firstName, lastName: $lastName, email:$email, profilePic: $profilePic, password: $password) {
    token
  }
}
`;
// v1

export const ADD_CONTACT = gql`
mutation addContact ($FirstName: String, $LastName: String, $Bio: String, $ProfilePic: String, $Gender: String, $Title: String, $Role: String, $Seniority: String, $Location: String, $City: String, $State: String, $Country: String, $LinkedIn: String, $Twitter: String, $Crunchbase: String, $Facebook: String, $Github: String, $About: String, $Usercompany: [ID], $Company: [ID]) {
  addcontact(firstName: $FirstName, lastName: $LastName, bio: $Bio, profilePic: $ProfilePic, gender: $Gender, title: $Title, role: $Role, seniority: $Seniority, location: $Location, city: $City, state: $State, country: $Country, linkedIn: $LinkedIn, twitter: $Twitter, crunchbase: $Crunchbase, facebook: $Facebook, github: $Github, about: $About, usercompany: $Usercompany, company: $Company) {
    _id
  }
}
`;
// v1


export const ADD_USERCONTACT = gql`
mutation addusercontact($firstName: String, $lastName: String, $bio: String, $profilePic: String, $gender: String, $title: String, $role: String, $seniority: String, $location: String, $city: String, $state: String, $country: String, $linkedIn: String, $twitter: String, $crunchbase: String, $facebook: String, $github: String, $about: String, $usercompanies: [ID], $companies: [ID]) {
  addusercontact(firstName: $firstName, lastName: $lastName, bio: $bio, profilePic: $profilePic, gender: $gender, title: $title, role: $role, seniority: $seniority, location: $location, city: $city, state: $state, country: $country, linkedIn: $linkedIn, twitter: $twitter, crunchbase: $crunchbase, facebook: $facebook, github: $github, about: $about, usercompanies: $usercompanies, companies: $companies) {
    _id
    firstName
    lastName
    bio
    profilePic
    gender
    title
    role
    seniority
    location
    city
    state
    country
    linkedIn
    twitter
    crunchbase
    facebook
    github
    about
    usercompanies {
      _id
      companyName
    }
    companies {
      _id
      companyName
    }
  }
}
`

export const ADD_USER_LETTER = gql`
mutation adduserletter($letterName: String!) {
  adduserletter(letterName: $letterName) {
    _id
    letterName
    letterCategory
    letterContent
  }
}
`
export const ADD_USER_FEED = gql`
mutation addUserFeed($FeedName: String!, $IsPublic: Boolean!, $Emoji: String, $EmployeeRangeMin: String, $EmployeeRangeMax: String, $Roles: String, $Tags: String) {
  adduserfeed(feedName: $FeedName, isPublic: $IsPublic, emoji: $Emoji, employeeRangeMin: $EmployeeRangeMin, employeeRangeMax: $EmployeeRangeMax, roles: $Roles, tags: $Tags) {
    _id
    feedName
  }
}
`

export const ADD_USER_BOARD = gql`
mutation addUserBoard($BoardName: String!, $IsArchived: Boolean) {
  adduserboard (boardName: $BoardName, isArchived: $IsArchived) {
    _id
    boardName
  }
}
`

export const ADD_USERCOMPANY = gql`
mutation addusercompany($companyName: String, $companyUrl: String, $companyLocation: String, $companyFoundedYear: String, $companyDomain: String, $companyDescription: String, $companySize: String, $companyCulture: [String], $companyValues: [String], $usercontacts: [ID], $contacts: [ID]) {
  addusercompany(companyName: $companyName, companyUrl: $companyUrl, companyLocation: $companyLocation, companyFoundedYear: $companyFoundedYear, companyDomain: $companyDomain, companyDescription: $companyDescription, companySize: $companySize, companyCulture: $companyCulture, companyValues: $companyValues, usercontacts: $usercontacts, contacts: $contacts) {
    _id
    companyName
    companyUrl
    companyLocation
    companyFoundedYear
    companyDomain
    companyDescription
    companySize
    companyCulture
    companyValues
    usercontacts {
      _id
      firstName
    }
    contacts {
      _id
      firstName
    }
  }
}
`
// export const ADD_USER_JOB = gql`
// mutation adduserjob($title: String, $description: String, $actionType: Int, $createdByWorkflow: Boolean, $jobLocation: String, $jobSalary: String, $jobUrl: String, $jobUrn: String, $deadline: String, $color: String, $jobnote: String, $lanesId: ID!) {
//   adduserjob(title: $title, description: $description, actionType: $actionType, createdByWorkflow: $createdByWorkflow, jobLocation: $jobLocation, jobSalary: $jobSalary, jobUrl: $jobUrl, jobUrn: $jobUrn, deadline: $deadline, color: $color, jobnote: $jobnote, lanesId: $lanesId) {
//     _id
//     actionType
//     createdByWorkflow
//     title
//     jobLocation
//     jobSalary
//     jobUrl
//     jobUrn
//     description
//     deadline
//     color
//     jobnote
//   }
// }
// `
//Add cards - add a user job mutation
export const ADD_USER_JOB = gql`
mutation adduserjob($title: String, $description: String, $actionType: Int, $createdByWorkflow: Boolean, $jobLocation: String, $jobSalary: String, $jobUrl: String, $jobUrn: String, $deadline: String, $color: String, $jobnote: String, $lanesId: ID!, $companyId: [ID], $usercompany: [ID]) {
  adduserjob(title: $title, description: $description, actionType: $actionType, createdByWorkflow: $createdByWorkflow, jobLocation: $jobLocation, jobSalary: $jobSalary, jobUrl: $jobUrl, jobUrn: $jobUrn, deadline: $deadline, color: $color, jobnote: $jobnote, lanesId: $lanesId, companyId: $companyId, usercompany: $usercompany) {
    _id
    actionType
    createdByWorkflow
    title
    jobLocation
    jobSalary
    jobUrl
    jobUrn
    description
    deadline
    color
    jobnote
    companyId {
      _id
    }
    usercompany {
      _id
    }
  }
}
`
// //add user activity
export const ADD_USER_ACTIVITY = gql`
mutation Mutation($boardId: ID!, $activityTitle: String, $activitynote: String, $activityCategory: Int, $activityCompleted: Boolean, $startAt: String, $endAt: String, $companyId: ID, $usercompany: ID, $cards: ID) {
  adduseractivity(boardId: $boardId, activityTitle: $activityTitle, activitynote: $activitynote, activityCategory: $activityCategory, activityCompleted: $activityCompleted, startAt: $startAt, endAt: $endAt, companyId: $companyId, usercompany: $usercompany, cards: $cards) {
    _id
    activityTitle
    activitynote
    activityCategory
    activityCompleted
    startAt
    endAt
    cards {
      _id
      actionType
      createdByWorkflow
      title
      jobLocation
      jobSalary
      jobUrl
      jobUrn
      description
      deadline
      color
      jobnote
      companyId {
        _id
        companyName
      }
      usercompany {
        _id
        companyName
      }
    }
    boardId {
      _id
      boardName
    }
    companyId {
      _id
      companyName
    }
    usercompany {
      _id
      companyName
    }
  }
}
`

//add user note
export const ADD_USER_NOTE_BOARD = gql`
mutation addusernoteboard($boardId: ID!, $noteText: String) {
  addusernoteboard(boardId: $boardId, noteText: $noteText) {
    _id
    noteText
  }
}
`
// UPDATE MUTATIONS
export const UPDATE_USER = gql`
mutation Mutation($username: String, $newPassword: String, $oldPassword: String, $phoneNumber: String, $profilePic: String, $uniqueId: String) {
  updateuser(username: $username, newPassword: $newPassword, oldPassword: $oldPassword, phoneNumber: $phoneNumber, profilePic: $profilePic, uniqueId: $uniqueId) {
    _id
  }
}
`

export const UPDATE_USERCONTACT = gql`
mutation updateusercontact($usercontactId: ID!, $firstName: String, $lastName: String, $bio: String, $profilePic: String, $gender: String, $title: String, $role: String, $seniority: String, $location: String, $city: String, $state: String, $country: String, $linkedIn: String, $twitter: String, $crunchbase: String, $facebook: String, $github: String, $about: String, $usercompanies: [ID], $companies: [ID]) {
  updateusercontact(usercontactId: $usercontactId, firstName: $firstName, lastName: $lastName, bio: $bio, profilePic: $profilePic, gender: $gender, title: $title, role: $role, seniority: $seniority, location: $location, city: $city, state: $state, country: $country, linkedIn: $linkedIn, twitter: $twitter, crunchbase: $crunchbase, facebook: $facebook, github: $github, about: $about, usercompanies: $usercompanies, companies: $companies) {
    _id
    firstName
    lastName
    bio
    profilePic
    gender
    title
    role
    seniority
    location
    city
    state
    country
    linkedIn
    twitter
    crunchbase
    facebook
    github
    about
    usercompanies {
      _id
      companyName
    }
    companies {
      _id
      companyName
    }
  }
}
`

export const UPDATE_CONTACT = gql`
mutation updateContact($ContactId: ID!, $FirstName: String, $LastName: String, $Bio: String, $ProfilePic: String, $Gender: String, $Title: String, $Role: String, $Seniority: String, $Location: String, $City: String, $State: String, $Country: String, $LinkedIn: String, $Twitter: String, $Crunchbase: String, $Facebook: String, $Github: String, $About: String, $Usercompany: [ID], $Company: [ID]) {
  updatecontact (contactId: $ContactId, firstName: $FirstName, lastName: $LastName, bio: $Bio, profilePic: $ProfilePic, gender: $Gender, title: $Title, role: $Role, seniority: $Seniority, location: $Location, city: $City, state: $State, country: $Country, linkedIn: $LinkedIn, twitter: $Twitter, crunchbase: $Crunchbase, facebook: $Facebook, github: $Github, about: $About, usercompany: $Usercompany, company: $Company) {
    _id
    firstName
    lastName
    bio
    profilePic
    gender
    title
    role
    location
    seniority
    city
    state
    country
    linkedIn
    twitter
    crunchbase
    facebook
    github
    about
    usercompany {
      _id
    }
    company {
      _id
    }
  }
}
`

export const UPDATE_USER_LETTER = gql`
mutation updateuserletter($letterId: ID!, $letterName: String, $letterContent: String, $letterCategory: String) {
  updateuserletter(letterId: $letterId, letterName: $letterName, letterContent: $letterContent, letterCategory: $letterCategory) {
    _id
    letterName
    letterCategory
    letterContent
  }
}
`

export const UPDATE_USER_FEED = gql`
mutation updateUserFeed($FeedId: ID!, $FeedName: String, $Emoji: String, $IsPublic: Boolean, $EmployeeRangeMin: String, $EmployeeRangeMax: String, $Roles: [String], $Tags: [String]) {
  updateuserfeed (feedId: $FeedId, feedName: $FeedName, emoji: $Emoji, isPublic: $IsPublic, employeeRangeMin: $EmployeeRangeMin, employeeRangeMax: $EmployeeRangeMax, roles: $Roles, tags: $Tags) {
    _id
    feedName
    emoji
    isPublic
    employeeRangeMin
    employeeRangeMax
    roles
    tags
  }
}
`

export const UPDATE_USER_BOARD = gql`
mutation updateuserboard($boardId: ID!, $boardName: String, $isArchived: Boolean, $lanes: [ID], $useractivity: [ID], $usernote: [ID]) {
  updateuserboard(boardId: $boardId, boardName: $boardName, isArchived: $isArchived, lanes: $lanes, useractivity: $useractivity, usernote: $usernote) {
    _id
    boardName
    isArchived
    lanes {
      _id

    }
    useractivity {
      _id
    }
    usernote {
      _id
    }
  }
}
`

export const UPDATE_LANES = gql`
mutation updatelanes($laneId: ID!, $title: String, $cards: [ID], $listIcon: String) {
  updatelanes(laneId: $laneId, title: $title, cards: $cards, listIcon: $listIcon) {
    _id
    cards {
      _id
    }
    listIcon
    title
  }
}
`
export const UPDATE_USERJOB = gql`
mutation updateuserjob($userjobId: ID!, $title: String, $description: String, $actionType: Int, $createdByWorkflow: Boolean, $jobLocation: String, $jobSalary: String, $jobUrl: String, $jobUrn: String, $deadline: String, $color: String, $jobnote: String, $companyId: [ID], $usercompany: [ID]) {
  updateuserjob(userjobId: $userjobId, title: $title, description: $description, actionType: $actionType, createdByWorkflow: $createdByWorkflow, jobLocation: $jobLocation, jobSalary: $jobSalary, jobUrl: $jobUrl, jobUrn: $jobUrn, deadline: $deadline, color: $color, jobnote: $jobnote, companyId: $companyId, usercompany: $usercompany) {
    _id
    actionType
    createdByWorkflow
    title
    jobLocation
    jobSalary
    jobUrl
    jobUrn
    description
    deadline
    color
    jobnote
    companyId {
      _id
    }
    usercompany {
      _id
    }
  }
}
`
export const UPDATE_USERACTIVITY = gql`
mutation updateuseractivity($activityId: ID!, $activityTitle: String, $activitynote: String, $activityCategory: Int, $activityCompleted: Boolean, $startAt: String, $endAt: String, $cards: ID, $boardId: ID, $companyId: ID, $usercompany: ID) {
  updateuseractivity(activityId: $activityId, activityTitle: $activityTitle, activitynote: $activitynote, activityCategory: $activityCategory, activityCompleted: $activityCompleted, startAt: $startAt, endAt: $endAt, cards: $cards, boardId: $boardId, companyId: $companyId, usercompany: $usercompany) {
    _id
    activityTitle
    activitynote
    activityCategory
    activityCompleted
    startAt
    endAt
    cards {
      _id
      title
    }
    boardId {
      _id
      boardName
    }
    companyId {
      _id
      companyName
    }
    usercompany {
      _id
      companyName
    }
  }
}
`
export const UPDATE_USERNOTE = gql`
mutation updateusernote($noteId: ID!, $noteText: String) {
  updateusernote(noteId: $noteId, noteText: $noteText) {
    _id
    noteText
  }
}
`

export const UPDATE_USERCOMPANY = gql`
mutation updateusercompany($usercompanyId: ID!, $companyName: String, $companyUrl: String, $companyLocation: String, $companyFoundedYear: String, $companyDomain: String, $companyDescription: String, $companySize: String, $companyCulture: [String], $companyValues: [String], $usercontacts: [ID], $contacts: [ID]) {
  updateusercompany(usercompanyId: $usercompanyId, companyName: $companyName, companyUrl: $companyUrl, companyLocation: $companyLocation, companyFoundedYear: $companyFoundedYear, companyDomain: $companyDomain, companyDescription: $companyDescription, companySize: $companySize, companyCulture: $companyCulture, companyValues: $companyValues, usercontacts: $usercontacts, contacts: $contacts) {
    _id
    companyName
    companyUrl
    companyLocation
    companyFoundedYear
    companyDomain
    companyDescription
    companySize
    companyCulture
    companyValues
    usercontacts {
      _id
      firstName
    }
    contacts {
      _id
      firstName
    }
  }
}
`

// DELETE MUTATIONS
export const DELETE_USER = gql`
mutation deleteUser($UserId: ID!) {
  deleteuser(userId: $UserId) {
    _id
  }
}
`

export const DELETE_USER_LETTER = gql`
mutation deleteuserletter($letterId: ID!) {
  deleteuserletter(letterId: $letterId) {
    _id
  }
}
`
export const DELETE_LANES = gql`
mutation deletelanes($boardId: ID!, $laneId: ID!) {
  deletelanes(boardId: $boardId, laneId: $laneId) {
    _id
    cards {
      _id
    }
    listIcon
    title
  }
}
`
export const DELETE_USERJOB = gql`
mutation deleteuserjob($userjobId: ID!, $laneId: ID!) {
  deleteuserjob(userjobId: $userjobId, laneId: $laneId) {
    _id
    title
    jobLocation
    actionType
    createdByWorkflow
    jobSalary
    jobUrl
    jobUrn
    description
    deadline
    color
    jobnote
    companyId {
      _id
    }
    usercompany {
      _id
    }
  }
}
`

export const DELETE_USER_FEED = gql`
mutation Mutation($feedId: ID!) {
  deleteuserfeed(feedId: $feedId) {
    _id
  }
}
`

export const DELETE_USER_BOARD = gql`
mutation deleteuserboard($boardId: ID!) {
  deleteuserboard(boardId: $boardId) {
    _id
  }
}
`

export const DELETE_USER_ACTIVITY = gql`
mutation deleteuseractivity($boardId: ID!, $activityId: ID!) {
  deleteuseractivity(boardId: $boardId, activityId: $activityId) {
    _id
    activityTitle
    activitynote
  }
}
`

export const DELETE_USER_NOTE_BOARD = gql`
mutation deleteusernoteboard($boardId: ID!, $noteId: ID!) {
  deleteusernoteboard(boardId: $boardId, noteId: $noteId) {
    _id
    noteText
  }
}
`

export const DELETE_USERCOMPANY = gql`
mutation deleteusercompany($usercompanyId: ID!) {
  deleteusercompany(usercompanyId: $usercompanyId) {
    _id
    companyName
  }
}
`

export const DELETE_USERCONTACT = gql`
mutation deleteusercontact($usercontactId: ID!) {
  deleteusercontact(usercontactId: $usercontactId) {
    _id
  }
}
`

export const CONFIRM_USER = gql`
mutation confirmUser($confirmed: Boolean!, $idToken: String!) {
  confirmUser(confirmed: $confirmed, idToken:$idToken){
    token
  }
}
`

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email: String!) {
  forgotpassword(email: $email){
    email
  }
}
`

export const RECOVER_PASSWORD = gql`
mutation recoverPassword($email: String!, $password: String!) {
  recoverpassword(email: $email, password: $password){
    firstName
  }
}
`
//Add a user board
export const ADD_USER_BOARD_1 = gql`
mutation Mutation($boardName: String, $isArchived: Boolean, $lanes: [ID], $useractivity: [ID], $usernote: [ID]) {
  adduserboard(boardName: $boardName, isArchived: $isArchived, lanes: $lanes, useractivity: $useractivity, usernote: $usernote) {
    _id
    boardName
    isArchived
    lanes {
      _id
    }
    useractivity {
      _id
    }
    usernote {
      _id
      noteText
    }
  }
}
`
//Add a user board only a name (if remaining arguments  are nor provided, its OK)
export const ADD_USER_BOARD_2 = gql`
mutation Mutation($boardName: String, $isArchived: Boolean) {
  adduserboard(boardName: $boardName, isArchived: $isArchived) {
    _id
    boardName
    isArchived
    lanes {
      _id
    }
    useractivity {
      _id
    }
    usernote {
      _id
      noteText
    }
  }
}
`


//Working add lane mutation
export const ADD_LANE_1 = gql`
mutation Mutation($title: String, $boardId: ID!) {
  addlanes(title: $title, boardId: $boardId) {
    _id
    cards {
      _id
    }
    listIcon
    title
  }
}
`

export const ADD_LANE = gql`
mutation Mutation($title: String, $listIcon: String, $cards: [ID], $boardId: ID!) {
  addlanes(title: $title, listIcon: $listIcon, cards: $cards, boardId: $boardId) {
    _id
    cards {
      _id
    }
    listIcon
    title
  }
}
`

export const GET_JOBS = gql`
mutation Getjobs($location: String!, $role: String!) {
  getjobs(location: $location, role: $role) {
    feedStatus
    isPublic
    roles
    cities
    feedUrl
    feedName
    feedId
    tags
  }
}
`

export const FETCH_HTML_CONTENT = gql`
mutation FetchHTMLContent($url: String!) {
  fetchHTMLContent(url: $url)
}
`

export const ADD_JOB_TO_BOARD = gql`
mutation Addfeedjobtoboard($boardId: ID, $boardName: String, $jobTitle: String, $description: String, $actionType: Int, $jobLocation: String, $jobSalary: String, $jobUrl: String, $laneTitle: String, $jobnote: String) {
  addfeedjobtoboard(boardId: $boardId, boardName: $boardName, jobTitle: $jobTitle description: $description, actionType: $actionType, jobLocation: $jobLocation, jobSalary: $jobSalary, jobUrl: $jobUrl, laneTitle: $laneTitle, jobnote: $jobnote) {
    _id
    boardName
  }
}
`

export const ADD_CALENDAR_EVENT = gql`
mutation Addcalendarevent($eventData: EventInput) {
  addcalendarevent(eventData: $eventData) {
    _id
    activityTitle
    activitynote
    startAt
    endAt
  }
}
`

export const UPDATE_SEARCH_FEED = gql`
mutation Updatesearchfeedtagged($id: ID!, $tags: String) {
  updatesearchfeedtagged(id: $id, tags: $tags) {
    _id
  }
}
`