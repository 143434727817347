import React, { useState} from 'react';
import { Button, Col, Card } from 'react-bootstrap';
import InlineEdit from '../InlineEdit';
import ExpandedComponentContact from './ExpandedComponentContact';
//GraphQL
import { useMutation} from '@apollo/client';
import { UPDATE_USERCOMPANY} from '../../utils/mutations';
//Utils and GlobalState


let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	fontWeight: "100",
	filter: "grayscale(100%)"
  };

const ExpandedComponentEdit = (props) => {
	const {
			data, 
        	contactsdataoptions,
			userRefetch } = props

//selection is the selected label in the array
//suggestions is a an array of all choice options in an object array of label: and value:
//labelArray is extracting only the labels from this above object array
//inputIndexSugg is the index number of the selected label in the choice options array
//this function returns the value: from the choice options in an object array when the label is provided
const getInput = (selection, suggestions) => {
	let labelArray =[];
	suggestions.map((suggestion, i) => labelArray.push(suggestion.label));
	// console.log("func call",labelArray );
	const inputIndexSugg = labelArray.indexOf(selection);
	const output = suggestions[Math.abs(inputIndexSugg)].value;
	// console.log("func call",selection, {inputIndexSugg}, {output} );
	return(
  
	  output
	)
  }
  
  //when an array of labels from the object array contactsdataoptions {suggestions} is provided, 
  //this function should return an array of values of value: 
  //from the choice options object array that is contactsdataoptions {suggestions} 
  const getselectedUsercontacts = (selections, suggestions) => {
  let selectionslength = selections.length;
  let selectedUsercontacts = [];
  for (let i = 0; i < selectionslength; i += 1) { //loop through the array selections{values} returned from form
  let selection = selections[i]; // for each value in the selections array returned from form
  //console.log({selection})
  let contactID = getInput(selection, suggestions) ////this function returns the value: from the choice options in an object array when the label is provided
  //this should be the ID of the contact
  selectedUsercontacts.push(contactID);
  // console.log(selectedUsercontacts);
  };
  return(
  selectedUsercontacts
  )
  }

//set initial value
	let companyName = data.companyName; // "Default",
	let companyDescription = data.companyDescription; // "Default",
	let companyLocation = data.companyLocation; // "Default",
	let companyFoundedYear = data.companyFoundedYear; // "Default",
	let companyUrl = data.companyUrl; // "Default",
	let companyDomain = data.companyDomain; // "Default",
	let companySize = data.companySize; // "Default",
	let companyCulture = data.companyCulture; // ["Default"],
	let companyValues = data.companyValues; // ["Default"],
	let usercontacts = getselectedUsercontacts(data.usercontacts,contactsdataoptions); // this taked an array of select labels and returns to associated value: from object array contactsdataoptions

//set initial value
const [storedcompanyName, setStoredCompanyName] = useState(companyName);
const [storedcompanyDescription, setStoredCompanyDescription] = useState(companyDescription);
const [storedcompanyLocation, setStoredCompanyLocation] = useState(companyLocation);
const [storedcompanyFoundedYear, setStoredCompanyFoundedYear] = useState(companyFoundedYear);
const [storedcompanyUrl, setStoredCompanyUrl] = useState(companyUrl);
const [storedcompanyDomain, setStoredCompanyDomain] = useState(companyDomain);
const [storedcompanySize, setStoredCompanySize] = useState(companySize);
const [storedcompanyCulture, setStoredCompanyCulture] = useState(companyCulture);
const [storedcompanyValues, setStoredCompanyValues] = useState(companyValues);

//define mutation
const [updateusercompany, { error: updateusercompanyerror }] = useMutation(UPDATE_USERCOMPANY, {
	onCompleted: async data => userRefetch()});	

//handle a change in CompanyName
const handleNewcompanyName = async (textValue) => {
  setStoredCompanyName(textValue);
  try {
    // add text to database
    await updateusercompany({
      variables: { usercompanyId: data._id , companyName: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in companySize
const handleNewcompanySize = async (textValue) => {
  setStoredCompanySize(textValue);
  try {
    // add text to database
    await updateusercompany({
      variables: { usercompanyId: data._id , companySize: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in companyFoundedYear
const handleNewcompanyFoundedYear = async (textValue) => {
	setStoredCompanyFoundedYear(textValue);
	try {
	  // add text to database
	  await updateusercompany({
		variables: { usercompanyId: data._id , companyFoundedYear: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in companyLocation
const handleNewcompanyLocation = async (textValue) => {
	setStoredCompanyLocation(textValue);
	try {
	  // add text to database
	  await updateusercompany({
		variables: { usercompanyId: data._id , companyLocation: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in companyDescription
const handleNewcompanyDescription = async (textValue) => {
	setStoredCompanyDescription(textValue);
	try {
	  // add text to database
	  await updateusercompany({
		variables: { usercompanyId: data._id , companyDescription: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in companyCulture
const handleNewcompanyCulture = async (textValue) => {
	setStoredCompanyCulture(textValue);
	let textValueArray = textValue.split(" ")
	try {
	  // add text to database
	  await updateusercompany({
		variables: { usercompanyId: data._id , companyCulture: textValueArray }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in companyValues
const handleNewcompanyValues = async (textValue) => {
	setStoredCompanyValues(textValue);
	let textValueArray = textValue.split(" ")
	try {
	  // add text to database
	  await updateusercompany({
		variables: { usercompanyId: data._id , companyValues: textValueArray }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in companyUrl
const handleNewcompanyUrl = async (textValue) => {
  setStoredCompanyUrl(textValue);
  try {
    // add text to database
    await updateusercompany({
      variables: { usercompanyId: data._id , companyUrl: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
 ////
//handle a change in companyDomain
const handleNewcompanyDomain = async (textValue) => {
	setStoredCompanyDomain(textValue);
	try {
	  // add text to database
	  await updateusercompany({
		variables: { usercompanyId: data._id , companyDomain: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };

  return (
	<div>
   		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <Card style={(expandedCardClass)}>
		<Card.Header className="text-muted">
		  <br/>
			<div className="divider"/>
			  	Company:  
				  {/* {data.companyName} */}
				  <InlineEdit text={storedcompanyName} onSetText={text => handleNewcompanyName(text)} />
			<div className="divider"/>
			<div className="divider"/>
				Size:  
				  {/* {data.companySize} */}
				  <InlineEdit text={storedcompanySize} onSetText={text => handleNewcompanySize(text)} />
			<div className="divider"/>
			<div className="divider"/>
				Founded:  
				  {/* {data.companyFoundedYear} */}
				  <InlineEdit text={storedcompanyFoundedYear} onSetText={text => handleNewcompanyFoundedYear(text)} />
			<div className="divider"/>
				Location:  
				  {/* {data.companyLocation} */}
				  <InlineEdit text={storedcompanyLocation} onSetText={text => handleNewcompanyLocation(text)} />
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
			  {/* {data.companyDescription} */}
			  <InlineEdit text={storedcompanyDescription} onSetText={text => handleNewcompanyDescription(text)} />
		  </Card.Text>
		  <Card.Text>
			  {/* {String(data.companyCulture)} */}
			  <InlineEdit text={storedcompanyCulture.join(" , ")} onSetText={text => handleNewcompanyCulture(text)} />
		  </Card.Text>
		  <Card.Text>
			  {/* {String(data.companyValues)} */}
			  <InlineEdit text={storedcompanyValues.join(" , ")} onSetText={text => handleNewcompanyValues(text)} />
		   </Card.Text>

	   <div>
         {/* {data.usercontacts && data.usercontacts.map((contacts,kValue) => (
         <Col key={kValue}>  */}
		 	<ExpandedComponentContact
				data={data}
				usercontacts={usercontacts} //Array ID of contacts
				// contacts={contacts} //label of this contact
				// kValue={kValue} //index value
				contactsdataoptions={contactsdataoptions}
				userRefetch={userRefetch}
			/>        
         {/* </Col>  
         ))}  */}
		</div>

		</Card.Body>
		<Card.Footer className="text-muted">
			URL & Domain:
			<br/>
				{/* {data.companyUrl} */}
				<InlineEdit text={storedcompanyUrl} onSetText={text => handleNewcompanyUrl(text)} />
			<br/>
				{/* {data.companyDomain} */}
				<InlineEdit text={storedcompanyDomain} onSetText={text => handleNewcompanyDomain(text)} />
	  </Card.Footer>
    </Card>
    </div>
  );
};

export default ExpandedComponentEdit;