import React, { useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import { Button, InputGroup, FormControl, Card } from 'react-bootstrap';
import InlineEdit from '../InlineEdit'
//GraphQL
import { useQuery, useMutation} from '@apollo/client';
import { QUERY_ME } from '../../utils/queries';
import { UPDATE_USERCONTACT} from '../../utils/mutations';
//Utils and GlobalState
import { usePepperContext } from '../../utils/GlobalState';
import { USER_DATA } from '../../utils/actions';
import { idbPromise } from '../../utils/helpers';

let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	fontWeight: "100",
	filter: "grayscale(100%)"
  };

const ExpandedComponentEdit = ({data}) => {
//console.log("id", data._id)
const [state, dispatch] = usePepperContext();
const { loading: userLoading, data: userData, refetch: userRefetch} = useQuery(QUERY_ME);


useEffect(() => {
	if (userData) {
	  dispatch({
		type: USER_DATA,
		userdata: userData.me
	  });
	//console.log("userdata.me", userData.me)
	idbPromise('userdata','put', userData.me);
	} else if (!userLoading) {
	  idbPromise('userdata','get').then(userdata => {
		dispatch({
		  type: USER_DATA,
		  userdata: userdata
		});
	  });
	}
	 //console.log('update')
  }, [userData, userLoading, dispatch ]);

//set initial value
	let firstName = data.firstName; // "Default",
	let lastName = data.lastName; // "Default",
	let about = data.about; // "Default",
	let location = data.location; // "Default",
	let country = data.country; // "Default",
	let linkedIn = data.linkedIn; // "Default",
	let twitter = data.twitter; // "Default",
	let gender = data.gender; // "Default",
	let role = data.role; // "Default",
	let seniority = data.seniority; // "Default",
	let city = data.city; // "Default",
	let Dstate = data.state; // "Default",
	let facebook = data.facebook; // "Default",
	let crunchbase = data.crunchbase; // "Default",
	let title = data.title; // "Default",
	let bio = data.bio; // ["Default"],
	let github = data.github; // ["Default"],
//set initial value

const [storedfirstName, setStoredfirstName] = useState(firstName);
const [storedlastName, setStoredlastName] = useState(lastName);
const [storedabout, setStoredabout] = useState(about);
const [storedlocation, setStoredlocation] = useState(location);
const [storedcountry, setStoredcountry] = useState(country);
const [storedlinkedIn, setStoredlinkedIn] = useState(linkedIn);
const [storedtwitter, setStoredtwitter] = useState(twitter);
const [storedgender, setStoredgender] = useState(gender);
const [storedrole, setStoredrole] = useState(role);
const [storedseniority, setStoredseniority] = useState(seniority);
const [storedcity, setStoredcity] = useState(city);
const [storedstate, setStoredstate] = useState(Dstate);
const [storedfacebook, setStoredfacebook] = useState(facebook);
const [storedcrunchbase, setStoredcrunchbase] = useState(crunchbase);
const [storedtitle, setStoredtitle] = useState(title);
const [storedbio, setStoredbio] = useState(bio);
const [storedgithub, setStoredgithub] = useState(github);

//console.log({storedfirstName});

//define mutation
const [updateusercontact, { error: updateusercontacterror }] = useMutation(UPDATE_USERCONTACT, {
	onCompleted: async data => userRefetch()});	

//handle a change in firstName
const handleNewfirstName = async (textValue) => {
  setStoredfirstName(textValue);
  try {
    // add text to database
    await updateusercontact({
      variables: { usercontactId: data._id , firstName: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in lastName
const handleNewlastName = async (textValue) => {
	setStoredlastName(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , lastName: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in gender
const handleNewgender = async (textValue) => {
  setStoredgender(textValue);
  try {
    // add text to database
    await updateusercontact({
      variables: { usercontactId: data._id , gender: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in role
const handleNewrole = async (textValue) => {
	setStoredrole(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , role: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in seniority
const handleNewseniority = async (textValue) => {
	setStoredseniority(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , seniority: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in city
const handleNewcity = async (textValue) => {
	setStoredcity(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , city: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in state
const handleNewstate = async (textValue) => {
	setStoredstate(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , state: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in facebook
const handleNewfacebook = async (textValue) => {
	setStoredfacebook(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , facebook: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in crunchbase
const handleNewcrunchbase = async (textValue) => {
	setStoredcrunchbase(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , crunchbase: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in title
const handleNewtitle = async (textValue) => {
	setStoredtitle(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , title: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in country
const handleNewcountry = async (textValue) => {
	setStoredcountry(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , country: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in location
const handleNewlocation = async (textValue) => {
	setStoredlocation(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , location: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in about
const handleNewabout = async (textValue) => {
	setStoredabout(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , about: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in bio
const handleNewbio = async (textValue) => {
	setStoredbio(textValue);
	let textValueArray = textValue.split(" ")
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , bio: textValueArray }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
  //handle a change in github
const handleNewgithub = async (textValue) => {
	setStoredgithub(textValue);
	let textValueArray = textValue.split(" ")
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , github: textValueArray }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
  ////
//handle a change in linkedIn
const handleNewlinkedIn = async (textValue) => {
  setStoredlinkedIn(textValue);
  try {
    // add text to database
    await updateusercontact({
      variables: { usercontactId: data._id , linkedIn: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
 ////
//handle a change in twitter
const handleNewtwitter = async (textValue) => {
	setStoredtwitter(textValue);
	try {
	  // add text to database
	  await updateusercontact({
		variables: { usercontactId: data._id , twitter: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
////


  return (
	<div>
   		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <Card style={(expandedCardClass)}>
		<Card.Header className="text-muted">
		  <br/>
		  	<div className="divider"/>
				Gender:  
				  {/* {data.gender} */}
				  <InlineEdit text={storedgender} onSetText={text => handleNewgender(text)} />
			<div className="divider"/>
			<div className="divider"/>
				Title:  
				  {/* {data.title} */}
				  <InlineEdit text={storedtitle} onSetText={text => handleNewtitle(text)} />
			<div className="divider"/>
			<div className="divider"/>
				Role:  
				  {/* {data.role} */}
				  <InlineEdit text={storedrole} onSetText={text => handleNewrole(text)} />
			<div className="divider"/>
			<div className="divider"/>
				Location:  
				  {/* {data.location} */}
				  <InlineEdit text={storedlocation} onSetText={text => handleNewlocation(text)} />
			<div className="divider"/>
		  <br/>
		  <br/>
		    <div className="divider"/>
				Seniority:  
				  {/* {data.seniority} */}
				  <InlineEdit text={storedseniority} onSetText={text => handleNewseniority(text)} />
			<div className="divider"/>
			<div className="divider"/>
				City:  
				  {/* {data.city} */}
				  <InlineEdit text={storedcity} onSetText={text => handleNewcity(text)} />
			<div className="divider"/>
			<div className="divider"/>
				State:  
				  {/* {data.state} */}
				  <InlineEdit text={storedstate} onSetText={text => handleNewstate(text)} />
			<div className="divider"/>
		  	<div className="divider"/>
				Country:  
				  {/* {data.country} */}
				  <InlineEdit text={storedcountry} onSetText={text => handleNewcountry(text)} />
			<div className="divider"/>
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
		  		Name:  
				{/* {data.firstName} */}
				<InlineEdit text={storedfirstName} onSetText={text => handleNewfirstName(text)} />
				<div className="divider"/>
				<InlineEdit text={storedlastName} onSetText={text => handleNewlastName(text)} />
		  </Card.Text>
		  <Card.Text>
			  	Bio: 
			  	{/* {data.bio} */}
			  	<InlineEdit text={storedbio} onSetText={text => handleNewbio(text)} />
		  </Card.Text>
		  <Card.Text>
			  	About: 
			  	{/* {data.about} */}
			  	<InlineEdit text={storedabout} onSetText={text => handleNewabout(text)} />
		  </Card.Text>
		</Card.Body>
		<Card.Footer className="text-muted">
		<br/>
				<div className="divider"/>
				<i className="fab fa-linkedin-in"/>
				<div className="divider"/>
					{/* {data.linkedIn} */}
					<InlineEdit text={storedlinkedIn} onSetText={text => handleNewlinkedIn(text)} />
				<div className="divider"/>
				<div className="divider"/>
				<i className="fab fa-twitter"/>
				<div className="divider"/>
					{/* {data.twitter} */}
					<InlineEdit text={storedtwitter} onSetText={text => handleNewtwitter(text)} />
			<br/>
				<div className="divider"/>
				<i class="fab fa-facebook"/>
				<div className="divider"/>
					{/* {data.facebook} */}
					<InlineEdit text={storedfacebook} onSetText={text => handleNewfacebook(text)} />
				<div className="divider"/>
				<div className="divider"/>
				<i className="fab fa-github"/>
				<div className="divider"/>
					{/* {data.github} */}
					<InlineEdit text={storedgithub} onSetText={text => handleNewgithub(text)} />
				<div className="divider"/>
				    Crunchbase: 
				<div className="divider"/>
					{/* {data.crunchbase} */}
					<InlineEdit text={storedcrunchbase} onSetText={text => handleNewcrunchbase(text)} />
			<br/>
	  </Card.Footer>
    </Card>
    </div>
  );
};

export default ExpandedComponentEdit;