import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const ComparisonSection = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <header>
            <h3 style={{ whiteSpace: 'pre-wrap' }}>How Peppercorn is Different</h3>
          </header>
          <section>
            <p style={{ whiteSpace: 'pre-wrap' }}>
              Unlike typical networking and job listing platforms, 
              Peppercorn.ai offers a personalized approach to career development. 
              We provide tailored coaching and educational resources, 
              focusing on self-discovery and personal marketing strategies, 
              backed by decades of experience. 
              This ensures you not only find opportunities 
              but are also well-prepared to seize them effectively.
            </p>
            <div className='d-flex justify-content-end'>
              <a href="/signup"><Button variant="success" size="lg">Sign Up</Button></a>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
}

export default ComparisonSection;
