//myArray.filter(item => item.type === 'beta').map(item => item.name)
export  const findJobTitleFromId = (TableData, jobIdValue) => {
  let findJobTitle = TableData.filter(item => item.jobId === jobIdValue).map(item => item.jobtitle) 
  // console.log("JobTitle from ID", findJobTitle)
  return ( 
    findJobTitle //the job title
       )
}

const cardsOptionscreate = (thislanesdata) => {
  let basecards = thislanesdata.cards //takes the cards object array for a lanesdata
  if (basecards != null && basecards.length > 0) {
    let basecardsLength = basecards.length;//finds the length of the lanes object array
    let cards = [] //create a new cards array
    let cardsobject = []
    // console.log( {basecards}, {basecardsLength} )
    for (let i = 0; i < basecardsLength; i += 1) {
      cardsobject[i] = {
        value:basecards[i]._id,
        label: basecards[i].title || "Rendering Issue",
        } //define the new cards object
        cards.push(cardsobject[i]);
        // console.log("cards item", basecards[i], "cardsobject item", cardsobject[i], "basic cards", basecards[i].cards )
      }
       //console.log({cards});
      let newdata= cards
       //console.log({newdata});
    return ( 
      newdata
      )
  }
else {
    let cards = [{ }] //create a new cards array
       //console.log({cards});
      let newdata= cards
       //console.log({newdata});
    return ( 
      newdata
      )

  }
};

const companydatacreate = (thisjobdata) => {
    let basecompany = thisjobdata.usercompany //takes the company object array for a jobdata
    if (basecompany != null) {
    let companyName = basecompany.companyName
        //define the new company
      return ( 
        companyName //the company value
           )
      }
    };

const companyIdcreate = (thisjobdata) => {
    let basecompany = thisjobdata.usercompany //takes the company object array for a jobdata
    if (basecompany != null) {
    let companyId = basecompany._id
        //define the new company
      return ( 
        companyId //the company value
           )
      }
    };


//why is adding a card to an activity impacting lanes data?
const cardsdatacreate = (thislanesdata) => {
    let basecards = thislanesdata.cards //takes the cards object array for a lanesdata
    if (basecards != null && basecards.length > 0) {
      let basecardsLength = basecards.length;//finds the length of the lanes object array
      let cards = [] //create a new cards array
      let cardsobject = []
      // console.log( {basecards}, {basecardsLength} )
      for (let i = 0; i < basecardsLength; i += 1) {
        cardsobject[i] = {
          laneId:thislanesdata._id,
          lanesTitle: thislanesdata.title || "Default Lane",
          lanesIcon: thislanesdata.listIcon || "Default Icon",
          jobId:basecards[i]._id,
          jobactionType: basecards[i].actionType || 1,
          createdByWorkflow: basecards[i].createdByWorkflow || true,
          jobtitle: basecards[i].title || "Issue in Rendering",
          jobLocation: basecards[i].jobLocation || "Default Location",
          jobSalary: basecards[i].jobSalary || "Default Salary",
          jobUrl: basecards[i].jobUrl || "Default Url",
          jobUrn: basecards[i].jobUrn || "Default Urn",
          jobdescription: basecards[i].description || "Default Description",
          deadline: basecards[i].deadline || "01/31/30",
          color: basecards[i].color || "blue",
          jobnote: basecards[i].jobnote || "Default Note",
          usercompanyId: companyIdcreate(basecards[i]) || "1",
          usercompany: companydatacreate(basecards[i]) || "Default Company",
          } //define the new cards object
          cards.push(cardsobject[i]);
          // console.log("cards item", basecards[i], "cardsobject item", cardsobject[i], "basic cards", basecards[i].cards )
        }
         //console.log({cards});
        let newdata= cards
         //console.log({newdata});
      return ( 
        newdata
        )
  }
  else {
      let cards = [{ }] //create a new cards array
         //console.log({cards});
        let newdata= cards
         //console.log({newdata});
      return ( 
        newdata
        )

  }
    };

export  const tableJobsDataCreate = (board) => {
  let alllanesdata = board.lanes
    if (alllanesdata != null && alllanesdata.length > 0) {
        let alllanesdataLength = alllanesdata.length;//finds the length of the lanes object array
        let lanes = [] //create a new lanes array
        let lanesobject = []
        // console.log( {alllanesdata}, {alllanesdataLength} )
        for (let i = 0; i < alllanesdataLength; i += 1) {
          lanesobject[i] = cardsdatacreate(alllanesdata[i]) //define the new lanes object
            lanes.push(lanesobject[i]);
            // console.log("lanes item", alllanesdata[i], "lanesobject item", lanesobject[i], "basic cards", baselanes[i].cards )
          }
           //console.log({lanes});
          // let newdata= lanes
          let newdata = [].concat.apply([], lanes);

        return ( 
          newdata
          )
    }
}

export  const tableJobsOptions = (board) => {
  let alllanesdata = board.lanes
  if (alllanesdata != null && alllanesdata.length > 0) {
      let alllanesdataLength = alllanesdata.length;//finds the length of the lanes object array
      let lanes = [] //create a new lanes array
      let lanesobject = []
      for (let i = 0; i < alllanesdataLength; i += 1) {
        lanesobject[i] = cardsOptionscreate(alllanesdata[i]) //define the new lanes object
          lanes.push(lanesobject[i]);
        }
         //console.log({lanes});
        // let newdata= lanes
        let newdata = [].concat.apply([], lanes);
        
      return ( 
        newdata
        )
  }
}

const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];				
            ctr++;
        });
    result += lineDelimiter;
    });

    return result;
}

export  const downloadCSV = (array) => {
	const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}

export const tableDataItem = [
    {
    id: 1,
    jobTitle: "Identify Contacts",
    jobnote: "Identify non-technical founders who have raised seed capital.",
    jobCompleted: true,
    startAt: "09/20/2021",
    endAt: "11/20/2021",
    cards: "Data Scientist",
    board: "PaaS Technologist",
    company: "Huddl.ai"
    },
    {
    id: 2,
    jobTitle: "Networking call",
    jobnote: "Have a Networking call with an ML Ops Leader",
    jobCompleted: false,
    startAt: "09/20/2021",
    endAt: "11/20/2024",
    cards: "Meta Job",
    board: "PaaS Technologist",
    company: "Meta"
    }

];

