import React, { useState } from 'react';
import { Link } from "react-router-dom";
// components
import { Modal, Tab, Card, Row, Col, CardGroup, Button, Badge, Container } from 'react-bootstrap';
// GraphQL
import { useMutation } from '@apollo/client';
import { DELETE_USER_BOARD } from '../utils/mutations';

const BoardsRender = (props) => {
  const { user, activemodule, userRefetch } = props;
  const boards = user.userboards;
  let deleteCard = true;
  const [modalValue, setModalValue] = useState({ show: false, boardID: "1" });

  const [deleteuserboard] = useMutation(DELETE_USER_BOARD, {
    onCompleted: async () => userRefetch()
  });

  // Handle Delete
  const handleDelete = async (modalValue) => {
    try {
      // remove from database
      await deleteuserboard({
        variables: { boardId: modalValue.boardID }
      });

      // clear value
      setModalValue({ show: false, boardID: "1" });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
     <Row className="justify-content-center">
        {boards && boards.map((board) => (
          <Col key={board._id} className="d-flex justify-content-center">
            <CardGroup>
              <Link to={`/${activemodule.moduleLink}/${board._id}`} className="text-decoration-none">
                <Card className="text-center modulecard">
                  {deleteCard && (
                    <Button variant='' onClick={(e) => {e.preventDefault(); setModalValue({ show: true, boardID: `${board._id}` })}}>
                      <i className="fas fa-trash-alt"></i>
                    </Button>
                  )}
                  <Card.Img variant="top" src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${activemodule.modulePoster}`} alt={`module-image:${activemodule.moduleTitle}`} />
                  <Card.Body>
                    <Card.Title>{board.boardName}</Card.Title>
                  </Card.Body>
                <Link to={`/${activemodule.moduleLink}/${board._id}`}>
                  <Card.Footer className='justify-center'>
                    <small className="text-muted" style={{ fontSize: "0.6rem", textAlign: "left" }}>{board.isArchived}</small>
                    <Badge className="pill-center" bg='none'>
                      Click to view or edit
                    </Badge>
                  </Card.Footer>
                </Link>
              </Card>
              </Link>
            </CardGroup>
          </Col>
        ))}
      </Row>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        show={modalValue.show}
      >
        <Modal.Body>
          <h5 className="py-4"> Are you sure you want to delete? </h5>
          <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <Row>
              <div className="py-4 d-flex justify-content-center">
                <Button className="btn-tutorial mx-2" onClick={() => handleDelete(modalValue)}>
                  Yes
                </Button>
                <Button className="btn-tutorial mx-2" onClick={() => setModalValue({ show: false, boardID: "1" })}>
                  No
                </Button>
              </div>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default BoardsRender;
