import React, { useEffect}from 'react';
import { Link } from "react-router-dom";
//GraphQL
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../utils/queries';
//Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import { USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
import { useAuthContext } from '../utils/AuthContext'
// import CompaniesRenderNew from '../components/CompaniesRenderNew';
import CompaniesRender from '../components/CompaniesRender';
//boards should display just that module information
const Companies = () => {
  // activemodule is hardcoded in JSX below as moduleData[4]; //hardcoded current module instead of useParams
  const { isAuthenticated } = useAuthContext()
const loggedIn = isAuthenticated;
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = usePepperContext();
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME);


  useEffect(() => {

    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me
      });
    idbPromise('userdata','put', userData.me);
    } else if (!userLoading) {
      idbPromise('userdata','get').then(userdata => {
        dispatch({
          type: USER_DATA,
          userdata: userdata
        });
      });
    }
    console.log('update')
  }, [userData, userLoading, dispatch ]);

  return (
    <div> 
        {loggedIn ? (
            
            <>
            <div className="container">
              <main>
                  <div>{!userLoading ? <CompaniesRender data={userData} /> : <div>Loading...</div>}</div>
              </main>
              <hr />
            </div>
            </> ) :
      (
        <>
        {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of this page.</h4> 
        </>
      )
         }
    </div>
  );
};

export default Companies;