import React from 'react';
// import { useParams, Link } from "react-router-dom";
// import DataTable, { createTheme } from 'react-data-table-component';
import { Card } from 'react-bootstrap';
// import CloudSpinner from '../../CloudSpinner';
// import InlineEdit from '../../InlineEdit'
// import ActivityForm from './ActivityForm';
//GraphQL
// import { useQuery, useMutation} from '@apollo/client';
// import { QUERY_ME, GET_BOARD} from '../../../utils/queries';
// import { UPDATE_USER_BOARD, ADD_LANE_1, UPDATE_LANES, DELETE_LANES, ADD_USER_JOB, UPDATE_USERJOB, DELETE_USERJOB } from '../../../utils/mutations';
// //Utils and GlobalState
// import { usePepperContext } from '../../../utils/GlobalState';
// import { BOARD_DATA } from '../../../utils/actions';
// import { USER_DATA } from '../../../utils/actions';
// import { idbPromise } from '../../../utils/helpers';
// import { tableDataItem, tableDataCreate, downloadCSV } from './BoardTableData';
// //tableDataItems, 
// import Auth from '../../../utils/auth';

  let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	filter: "grayscale(100%)"
  };
  
  const ExpandedComponent = ({data}) => {

    return (
    <div>
   {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <Card style={(expandedCardClass)}>
		<Card.Header>
		  <br/>
			<div className="divider"/>
			  <b>Category: </b> {data.activityCategory}
			<div className="divider"/>
			<div className="divider"/>
			<b>Job: </b> {data.cards}
			<div className="divider"/>
			{/* <div className="divider"/>
			<b>Board: </b> {data.board} */}
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
			  <b>{data.activityTitle}</b>
		  </Card.Text>
		  <Card.Text>
			  {data.activitynote}
		  </Card.Text>
			  {/* {deleteCard? (
				<Button variant='delete' 
					 onClick={() => setModalValue({ show: true, letterID: `${letter._id}`})}
					>
				  <i className="fas fa-trash-alt"></i>
				</Button>
				  )
				 :  
				 (<> </>)
				  }  */}
		  {/* <Button variant="primary"> <i className="fas fa-trash-alt"></i> </Button> */}
		</Card.Body>
		<Card.Footer className="text-muted">
		<b>Start: </b> 
			{/* {data.startAt} */}
			{new Date(data.startAt).toLocaleDateString()}
		<div className="divider"/>
		<b>End: </b> 
			{/* {data.endAt} */}
			{new Date(data.endAt).toLocaleDateString()}
	  </Card.Footer>
    </Card>
  </div>
    );
};

export default  ExpandedComponent;