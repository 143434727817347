import React, { useState } from 'react'
import { Image, Spinner, Toast } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../../utils/mutations'
import { useForm } from 'react-hook-form'
import './profile.scss'

const MyPersonalInfo = (props) => {
  const { fullName, phoneNumber, email, refetch } = props

  const [updateuser, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_USER)
  const [isActive, setIsActive] = useState(false)
  const [changedValue, setChangesValue] = useState({
    username: fullName,
    phoneNumber: phoneNumber,
    email: email,
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm()

  const onSubmit = async (data) => {
    console.log(data)
    try {
      await updateuser({
        variables: {
          username: data.username,
          phoneNumber: data.phoneNumber,
          email: data.email,
          profilePicture: data.profilePicture,
          password: data.password,
        },
      })
      // setIsActive(!isActive)
      refetch()
    } catch ({ message }) {
      console.log(message)
      setError('username', {
        type: 'manual',
        message,
      })
    }
  }

  return (
    <>
      {}
      <div id='profile-card' className='profile-card py-4'>
        <div className='text-center'>
          <div className='accordion-card'>
            <div className='accordion-item'>
              <div
                className='accordion-title  py-2 px-4'
                onClick={() => setIsActive(!isActive)}
              >
                <div>
                  <h6 className='my-auto'>My personal information</h6>
                </div>
                <div>
                  {isActive ? (
                    <span className='float-right isActive'>
                      <icon name='down-arrow' />
                    </span>
                  ) : (
                    <span className='float-right '>
                      <icon name='down-arrow' />
                    </span>
                  )}
                </div>
              </div>
              {isActive && (
                <div className='accordion-content'>
                  <div className='py-4 px-3 text-center'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        {/* Input For Full Name, validatio for first name and surname */}
                        <input
                          type='text'
                          {...register('username', {
                            pattern: {
                              value:
                                /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/,
                              message: 'Please enter your name and surname.',
                            },
                          })}
                          id='profile-input'
                          value={changedValue.username}
                          onChange={(event) =>
                            setChangesValue(event.target.value)
                          }
                        ></input>
                        {errors.fullName && (
                          <label className='errorMsg'>
                            Please enter your name and surname.
                          </label>
                        )}
                        <input
                          id='profile-input'
                          {...register('phoneNumber', { minLength: 10 })}
                          value={changedValue.phoneNumber}
                          onChange={(event) =>
                            setChangesValue(event.target.value)
                          }
                          placeholder='Phone Number'
                        ></input>
                        <input
                          id='profile-input'
                          readOnly
                          disabled
                          value={email}
                        ></input>
                      </div>
                      <div className='float-right py-4'>
                        <input
                          id='text-save'
                          disabled={mutationLoading}
                          className='pr-5 text-each'
                          type='submit'
                          value='save'
                        ></input>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <div>
                {mutationLoading && (
                  <Spinner animation='border' variant='success' />
                )}
                {mutationError && (
                  <div className='my-3 p-3 bg-danger text-white'>
                    {mutationError.message}
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ChangeMyProfilePicture = (props) => {
  const { refetch, myPic } = props
  const [isActive, setIsActive] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm()
  const [updateuser, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_USER)

  const [profilePicture] = useState([
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/man1.jpg',
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/man2.jpg',
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/man3.jpg',
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/woman1.jpg',
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/woman2.jpg',
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/woman3.jpg',
  ])

  const [changedValue, setChangesValue] = useState({
    profilePic: myPic,
  })

  const onSubmit = async () => {
    console.log(changedValue)
    try {
      await updateuser({
        variables: { profilePic: changedValue },
      })
      refetch()
    } catch ({ message }) {
      setError('profilePic', {
        type: 'manual',
        message,
      })
    }
  }

  return (
    <>
      <div className='py-4'>
        <div id='profile-card' className='profile-card py-4'>
          <div className='text-center'>
            <div className='accordion-card'>
              <div className='accordion-item'>
                <div
                  className='accordion-title  px-4 py-2'
                  onClick={() => setIsActive(!isActive)}
                >
                  <div>
                    <h6 className='my-auto'>Change my profile</h6>
                  </div>
                  <div>
                    {isActive ? (
                      <span className='float-right isActive'>
                        <icon name='down-arrow' />
                      </span>
                    ) : (
                      <span className='float-right '>
                        <icon name='down-arrow' />
                      </span>
                    )}
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {isActive && (
                    <>
                      {profilePicture.map((pic) => (
                        <Image
                          className={pic === changedValue ? 'selected' : ''}
                          width={75}
                          src={pic}
                          roundedCircle
                          onClick={() => {
                            setChangesValue(pic)
                          }}
                        />
                      ))}
                      <div className='float-right py-4'>
                        <input
                          id='text-save'
                          disabled={mutationLoading}
                          className='pr-5 text-each'
                          type='submit'
                          value='save'
                        ></input>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ChangeMyPassword = () => {
  const [isActive, setIsActive] = useState(false)
  const [updateuser, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_USER, {
      onCompleted: (data) => {
        if (data === !null) {
          setIsPasswordSet(true)
        }
        reset((formValues) => ({
          ...formValues,
          oldPassword: '',
          newPassword: '',
        }))
      },
    })

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [isRevealPwd, setIsRevealPwd] = useState(false)
  const [isNewRevealPwd, setIsNewRevealPwd] = useState(false)
  const [isPasswordSet, setIsPasswordSet] = useState(false)

  // Show/ Hide existing password
  const togglePasswordVisiblity = () => {
    setIsRevealPwd(isRevealPwd ? false : true)
  }

  // Show/ Hide new password
  const toggleNewPasswordVisiblity = () => {
    setIsNewRevealPwd(isNewRevealPwd ? false : true)
  }

  // const eye = <FontAwesomeIcon icon={faEye} />

  const onSubmit = async (data) => {
    try {
      await updateuser({
        variables: data,
      })
      setIsActive(!isActive)
    } catch ({ message }) {
      console.log(message)
    }
  }

  return (
    <>
      <div className='pt-4'>
        <div id='profile-card' className='profile-card py-4'>
          <div className='text-center'>
            <div className='accordion-card'>
              <div className='accordion-item'>
                <div
                  className='accordion-title  px-4 py-2'
                  onClick={() => setIsActive(!isActive)}
                >
                  <div>
                    <h6 className='my-auto'>Change my password</h6>
                  </div>
                  <div>
                    {isActive ? (
                      <span className='float-right isActive'>
                        <icon name='down-arrow' />
                      </span>
                    ) : (
                      <span className='float-right'>
                        <icon name='down-arrow' />
                      </span>
                    )}
                  </div>
                </div>
                {isActive && (
                  <div className='accordion-content'>
                    <div className='py-4 px-3 text-center'>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='pwd-container'>
                          <input
                            id='profile-input'
                            {...register('oldPassword')}
                            type={isRevealPwd ? 'text' : 'password'}
                            placeholder='Current password'
                          />
                          <i
                            className={
                              isRevealPwd
                                ? 'px-2 my-auto fa fa-eye'
                                : 'my-auto px-2 fa fa-eye-slash'
                            }
                            onClick={togglePasswordVisiblity}
                          ></i>
                          {errors.oldPassword && (
                            <label className='errorMsg'>
                              Your current password is incorrect. Please try
                              againˆ
                            </label>
                          )}
                        </div>
                        <div className='pwd-container'>
                          <input
                            {...register('newPassword', {
                              required: true,
                              pattern: {
                                value: /^().{10,}$/, // Regex for checking the password conditions
                                message: 'Password must fullfill conditions',
                              },
                            })}
                            id='profile-input'
                            type={isNewRevealPwd ? 'text' : 'password'}
                            placeholder='New password'
                          />
                          <i
                            className={
                              isNewRevealPwd
                                ? 'px-2 my-auto fa fa-eye'
                                : 'my-auto px-2 fa fa-eye-slash'
                            }
                            onClick={toggleNewPasswordVisiblity}
                          ></i>
                          {errors.newPassword && (
                            <div>
                              <ul className='errorMsg errorlist pt-3'>
                                Your password must have: a minimum of 10
                                characters <br />
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className='float-right py-4'>
                          <input
                            id='text-save'
                            disabled={mutationLoading}
                            className='pr-5 text-each'
                            type='submit'
                            value='save'
                          ></input>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                <div>
                  {mutationLoading && (
                    <Spinner animation='border' variant='success' />
                  )}
                  {mutationError && (
                    <label className='errorMsg'>{mutationError.message}</label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPasswordSet && (
        <Toast
          className='toast'
          onClose={() => setIsPasswordSet(false)}
          show={isPasswordSet}
          delay={3000}
          autohide
        />
      )}
    </>
  )
}

export { MyPersonalInfo, ChangeMyPassword, ChangeMyProfilePicture }
