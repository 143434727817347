import React, { useState } from 'react';
import { Button, Form, Alert, Toast } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { ADD_USER_BOARD_2 } from '../../utils/mutations';
import './App.css';

const BoardForm = ({ userRefetch }) => {
  const [boardName, setBoardName] = useState('');
  const [characterCount, setCharacterCount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [addBoard, { error }] = useMutation(ADD_USER_BOARD_2, {
    onCompleted: () => {
      userRefetch();
      setBoardName('');
      setCharacterCount(0);
      setShowToast(true); // Trigger toast on successful addition
    }
  });

  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length <= 200) {
      setBoardName(value);
      setCharacterCount(value.length);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (boardName.trim() && characterCount <= 200) {
      try {
        await addBoard({ variables: { boardName } });
      } catch (e) {
        console.error('Error adding board:', e);
      }
    }
  };

  const handleCloseToast = () => setShowToast(false);

  return (
    <div>
      <p className={`m-0 ${characterCount === 200 ? 'text-danger' : ''}`}>
        Character Count: {characterCount}/200
      </p>
      {error && <Alert variant="danger">Something went wrong...</Alert>}
      <Form onSubmit={handleFormSubmit}>
        <Form.Group className="mb-3" controlId="formBoardName">
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Enter new board name..."
            value={boardName}
            className="form-input col-12 col-md-9"
            onChange={handleChange}
            aria-label="New board name"
          />
          <Button
            className='btn d-block w-20'
            type="submit"
            variant="primary"
            style={{backgroundColor:'#303551', border:'none',  margin: 5}}
            disabled={!boardName.trim() || characterCount > 200}
          >
            Click to add board
          </Button>
        </Form.Group>
      </Form>
      <Toast onClose={handleCloseToast} show={showToast} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Board Added</strong>
        </Toast.Header>
        <Toast.Body>Board successfully added!</Toast.Body>
      </Toast>
    </div>
  );
};

export default BoardForm;
