import React from 'react';
import { Link } from "react-router-dom";
// Client Data
import completed from '../data/Completed';
// Components
import { Card, Badge, Row, Col, CardGroup } from 'react-bootstrap';
// GraphQL
/***/
// Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import { useAuthContext } from '../utils/AuthContext';

const MetricsRender = (props) => {
  const { activemodule } = props;
  // eslint-disable-next-line no-unused-vars
  const [state] = usePepperContext();
  // const { modules, userdata } = state;

  // let completed = userdata.completed;
  // instead now importing from data folder
  const handleComplete = (number) => {
    let completeForUser = completed.some(complete => complete === number);
    // console.log({completeForUser});
    return (
      <Badge className="pill-center mx-2 my-2" bg={completeForUser ? "success" : "dark"}>
        {completeForUser ? "Active" : "Activate"}
      </Badge>
    );
  };

  // if (!modules.length) {
  //   return <h3>There are no modules in this library!</h3>;
  // }

  const { isAuthenticated } = useAuthContext();
  const loggedIn = isAuthenticated;

  return (
    <div>
      <hr />
      <Row>
        <Col>
          <CardGroup>
            <Link to={`/${activemodule.moduleLink}`}>
              <Card className="text-center modulecard">
                {loggedIn
                  ? handleComplete(activemodule.moduleNumber)
                  : (
                    <Badge className="pill-center mx-2 my-2" bg style={{backgroundColor: "#F2A90B"}}>
                      Login Required
                    </Badge>
                  )}
                <Card.Img className="modulecard-img" variant="top" src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${activemodule.modulePoster}`} alt={`module-image:${activemodule.moduleTitle}`} />
                <Card.Body>
                  <Card.Title>
                    {/* {activemodule.moduleNumber} :  */}
                    {activemodule.moduleTitle}
                  </Card.Title>
                </Card.Body>
                <Card.Footer>
                  <small className="pill-center">Click to open</small>
                </Card.Footer>
              </Card>
              <br />
            </Link>
          </CardGroup>
        </Col>
      </Row>
    </div>
  );
};

export default MetricsRender;
