import React, { useEffect, useState, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Cookies from 'js-cookie';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { addMonths, subMonths } from 'date-fns';
import './calendar.scss';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../utils/mutations';
import Auth from '../../utils/auth';

const CalendarFull = ({ fullEvents }) => {
  const [events, setEvents] = useState(fullEvents);
  const [modalOpen, setModalOpen] = useState(false);
  const [theEvent, setEvent] = useState({});
  const navigate = useNavigate();
  const [logout] = useMutation(LOGOUT, { onCompleted: () => Auth.logout() });

  const handleClientLoad = useCallback(() => {
    window.gapi.load('client:auth2', initClient);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = 'https://apis.google.com/js/api.js';

    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.gapi) handleClientLoad();
    });

    return () => {
      document.body.removeChild(script);
    };
  }, [handleClientLoad]);

  const initClient = async () => {
    const currentDate = new Date();
    const futureDate = addMonths(currentDate, 3);
    const pastDate = subMonths(currentDate, 1);
    const token = Cookies.get('googleToken');

    if (!token) {
      navigate('/login');
    } else {
      try {
        const res = await fetch(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${encodeURIComponent(
            pastDate.toISOString()
          )}&timeMax=${encodeURIComponent(futureDate.toISOString())}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.status === 401) {
          localStorage.removeItem('id_token');
          logout();
        } else {
          const data = await res.json();
          if (data?.items) {
            setEvents(formatEvents(data.items));
          }
        }
      } catch (error) {
        console.error('Error fetching events', error);
        logout();
      }
    }
  };

  const formatEvents = (list) => {
    const dummyDateTime = new Date('2071-05-13T00:00:00Z');

    return list.map((item) => {
      const summary = item.summary || '';
      const description = item.description || '';
      const meetLink = item.hangoutLink || '';

      if (item.start?.dateTime) {
        return {
          title: summary,
          description: description,
          start: item.start.dateTime,
          end: item.end?.dateTime || item.start.dateTime,
          meetLink: meetLink,
        };
      } else if (item.start?.date) {
        const startDate = new Date(item.start.date);
        const endDate = item.end?.date ? new Date(item.end.date) : startDate;

        return {
          title: summary,
          description: description,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          meetLink: meetLink,
        };
      } else {
        return {
          title: summary,
          description: description,
          start: dummyDateTime.toISOString(),
          end: dummyDateTime.toISOString(),
          meetLink: meetLink,
        };
      }
    });
  };

  const location = useLocation();
  const { year, monthDate } = useParams();
  let initialDate = new Date().toISOString();

  if (location.pathname !== '/' && year > 999 && year < 10000) {
    const parsedMonth = parseInt(monthDate.split('-')[0]);
    const parsedDate = parseInt(monthDate.split('-')[1]);
    const month = parsedMonth > 0 && parsedMonth < 13 ? parsedMonth : null;
    const date = parsedDate > 0 && parsedDate < 31 ? parsedDate : null;

    if (month && date) {
      initialDate = `${year}-${month < 10 ? `0${month}` : month}-${
        date < 10 ? `0${date}` : date
      }T00:00:00`;
    }
  }

  const handleEventClick = (clickInfo) => {
    if (clickInfo.event) {
      setEvent({
        title: clickInfo.event.title,
        start: clickInfo.event.start,
        description: clickInfo.event.extendedProps.description,
        end: clickInfo.event.end,
        meetLink: clickInfo.event.extendedProps.meetLink,
      });
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <div className='demo-app'>
        <div className='demo-app-main'>
          {events && (
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              initialView='dayGridMonth'
              events={events}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              eventClick={handleEventClick}
              dateClick={handleEventClick}
            />
          )}
        </div>
      </div>
      <Modal show={modalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{theEvent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-2'>
            <strong>Start:</strong>
            <div>{theEvent.start?.toString()}</div>
          </div>
          <div className='my-2'>
            <strong>End:</strong>
            <div>{theEvent.end?.toString()}</div>
          </div>
          {theEvent.meetLink && (
            <div className='my-2'>
              <strong>Google Meet Link:</strong>
              <br />
              <a href={theEvent.meetLink} target="_blank" rel="noopener noreferrer">
                {theEvent.meetLink}
              </a>
            </div>
          )}
          <hr />
          <div>{theEvent.description}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalendarFull;
