import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const InspiringSection = () => {
  return (
    <Container fluid className="px-0 pb-5">
      <Row className="justify-content-center align-items-center text-center" style={{ minHeight: '80vh', position: 'relative' }}>
        <div style={{
          backgroundImage: 'url(https://discovery-journey.s3.us-east-2.amazonaws.com/discovery-images/60038089T1.jpg)',
          backgroundSize: 'cover',
          opacity: '0.5',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }} />
        <Col style={{ color: '#333333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
          <h1>Your Plan, Your Future</h1>
          <p>Unlock your full potential and take control of your career trajectory.</p>
          <a href="/signup"><Button variant="secondary" size="lg">Sign Up</Button></a>
        </Col>
      </Row>
    </Container>
  );
}

export default InspiringSection;
