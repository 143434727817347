import React, { useEffect }from 'react';
import { useParams, Link } from "react-router-dom";
//Client Data
// import moduleData from '../data/moduleData'
//Components
import BoardComponent from '../components/BoardComponent/BoardComponent';
// import GenerateDoc from '../components/GenerateDoc';

// import SideBarParagraph from '../components/Sidebar/SidebarParagraph';
//GraphQL
import { useQuery} from '@apollo/client';
import { QUERY_ME } from '../utils/queries';
import { GET_BOARD } from '../utils/queries';
//Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import { USER_DATA, BOARD_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
import { useAuthContext } from '../utils/AuthContext'
import { Spinner } from 'react-bootstrap';

const OneBoard = () => {
  const { isAuthenticated } = useAuthContext()
const loggedIn = isAuthenticated;
  const [state, dispatch] = usePepperContext();
  const { loading: userLoading, data: userData, refetch: userRefetch } = useQuery(QUERY_ME);
  
  const {boardId} = useParams();
  const { data: boardData, loading: boardLoading, refetch: boardRefetch} = useQuery(GET_BOARD, 
    {variables: { boardBoardId:boardId },
    //fetchPolicy: "network-only",
    //nextFetchPolicy: "cache-first" // Used for subsequent executions
    // pollInterval: 500,
    }
    );

  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me
      });
    idbPromise('userdata','put', userData.me);
    } else if (!userLoading) {
      idbPromise('userdata','get').then(userdata => {
        dispatch({
          type: USER_DATA,
          userdata: userdata
        });
      });
    }
  }, [userData, userLoading, dispatch ]);

  useEffect(() => {
    if (boardData) {
      dispatch({
        type: BOARD_DATA,
        boarddata: boardData.board
      });
    idbPromise('boarddata','put', boardData.board);
    } else if (!boardLoading) {
      idbPromise('boarddata','get').then(boarddata => {
        dispatch({
          type: BOARD_DATA,
          boarddata: boarddata
        });

      });
    }
  }, [boardData, boardLoading, dispatch ]);

let user = state.userdata;


const boards = user.userboards;


let isArchived = false;
let boardName = "sample name";
let board = {}
const assignValues = (boardmap) => {
    board = boardmap;
    isArchived = boardmap.isArchived;
    boardName = boardmap.boardName;
    return (isArchived, boardName, board);
  }
// //

boards && boards.map((boardmap) => 
  (boardmap._id === boardId) ? 
  assignValues(boardmap)
  : " "
  ) 


  return (
    <div> 
        {loggedIn ? (
            <>
            <div className="container">
              <main className="pb-4">
                  <div>
                   { userLoading ? 
                      <div className='container text-center'>
    <Spinner />
  </div> : 
                     (
                    <BoardComponent  user={user} board={board} boardRefetch={boardRefetch} userRefetch={userRefetch}/>             
                     )
                    } 
                  </div>
              </main>
            </div>
            </> ) :
      (
        <>
        {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of this page.</h4> 
        </>
      )
         }
    </div>
  );
};

export default OneBoard;