import React, { useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Card, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import InlineEdit from '../../InlineEdit'
//GraphQL
import { useQuery, useMutation} from '@apollo/client';
import { GET_BOARD} from '../../../utils/queries';
import { UPDATE_USERACTIVITY } from '../../../utils/mutations';
//Utils and GlobalState
import { usePepperContext } from '../../../utils/GlobalState';
import { BOARD_DATA } from '../../../utils/actions';
import { idbPromise } from '../../../utils/helpers';

let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	filter: "grayscale(100%)"
  };

const options = [
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
  ];

const ExpandedComponentEdit = (props) => {
	const {
			data, 
        	jobsdataoptions} = props

//console.log({jobsdataoptions})
// console.log("id", data._id)

const {boardId} = useParams();
// eslint-disable-next-line
const [state, dispatch] = usePepperContext();
const { data: boardData, loading: boardLoading, refetch: boardRefetch } = useQuery(GET_BOARD, {
	variables: { boardBoardId:boardId }});


	useEffect(() => {
		if (boardData) {
		   //console.log({boardData})
		  dispatch({
			type: BOARD_DATA,
			boarddata: boardData.board
		  });
		//console.log("boarddata", boardData.board)
		idbPromise('boarddata','put', boardData.board);
		} else if (!boardLoading) {
		  idbPromise('boarddata','get').then(boarddata => {
			dispatch({
			  type: BOARD_DATA,
			  boarddata: boarddata
			});
			 console.log({boarddata})
		  });
		}
	  }, [boardData, boardLoading, dispatch ]); 

//set initial value
let activityTitle = data.activityTitle
let activitynote = data.activitynote
let activityCategory = data.activityCategory
let cards = data.cards
let startAt = data.startAt
let endAt = data.endAt
//set initial value


const [storedTextTitle, setStoredTextTitle] = useState(activityTitle);
const [storedTextnote, setStoredTextnote] = useState(activitynote);
const [storedTextstartAt, setStoredTextstartAt] = useState(new Date(startAt));
const [storedTextendAt, setStoredTextendAt] = useState(new Date(endAt));
const [storedTextactivityCategory, setStoredTextActivityCategory] = useState(activityCategory);
const [storedTextcards, setStoredTextCards] = useState(cards);
// const [storedTextstartAt, setStoredTextstartAt] = useState(startAt);
// const [storedTextendAt, setStoredTextendAt] = useState(endAt);
//console.log({storedTextTitle});

//define mutation
const [updateuseractivity] = useMutation(UPDATE_USERACTIVITY, {
	onCompleted: async data => boardRefetch()});	

//handle a change in Title
const handleNewTextTitle = async (textValue) => {
  setStoredTextTitle(textValue);
  try {
    // add text to database
    await updateuseractivity({
      variables: { activityId: data._id , activityTitle: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in note
const handleNewTextnote = async (textValue) => {
  setStoredTextnote(textValue);
  try {
    // add text to database
    await updateuseractivity({
      variables: { activityId: data._id , activitynote: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in startAt
const handleNewTextstartAt = async (textValue) => {
  setStoredTextstartAt(textValue);
  try {
    // add text to database
    await updateuseractivity({
      variables: { activityId: data._id , startAt: textValue }
    });
    // // if needed clear form value here
  } catch (e) {
    console.error(e);
  }
};
////
//handle a change in endAt
const handleNewTextendAt = async (textValue) => {
	setStoredTextendAt(textValue);
	try {
	  // add text to database
	  await updateuseractivity({
		variables: { activityId: data._id , endAt: textValue }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
////
//handle a change in activityCategory
const handleNewTextActivityCategory = async (textValue) => {
	setStoredTextActivityCategory(textValue);
	try {
	  // add text to database
	  await updateuseractivity({
		variables: { activityId: data._id , activityCategory: textValue.value }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
////
//handle a change in cards
const handleNewTextCards = async (textValue) => {
	setStoredTextCards(textValue);
	//console.log("what is stored value for cards", {textValue})
	try {
	  // add text to database
	  await updateuseractivity({
		variables: { activityId: data._id , cards: textValue.value }
	  });
	  // // if needed clear form value here
	} catch (e) {
	  console.error(e);
	}
  };
////

  return (
    <div>
     <Card style={(expandedCardClass)}>
		<Card.Header>
		  <br/>
			<div className="divider"/>
			  {/* <b>Company: </b> 
			  		{data.company}
			<div className="divider"/>
			<div className="divider"/> */}
						 <Form.Group className="mb-3" controlId="formBasicPassword">
          					<Form.Label>Activity Category: {data.activityCategory}</Form.Label>
         				   		<Select
            						defaultValue={storedTextactivityCategory}
									onChange={text => handleNewTextActivityCategory(text)}
            						// onChange={setStoredTextActivityCategory}
            						options={options}
          				   		/>
        				</Form.Group>

        				<Form.Group className="mb-3" controlId="formBasicPassword">
          					<Form.Label> Job Card:{data.cards}</Form.Label>
          						<Select
            						defaultValue={storedTextcards}
            						onChange={text => handleNewTextCards(text)}
									//onChange={setStoredTextCards}
            						options={jobsdataoptions}
          						/>
        				</Form.Group>
			<div className="divider"/>
			<div className="divider"/>

		  <br/>
	    </Card.Header>
		<Card.Body>
		  <Card.Text>
			  <b>
				  {/* {data.activityTitle} */}
				  <InlineEdit text={storedTextTitle} onSetText={text => handleNewTextTitle(text)} />
			  </b>
		  </Card.Text>
		  <Card.Text>
			      {/* {data.activitynote} */}
				  <InlineEdit text={storedTextnote} onSetText={text => handleNewTextnote(text)} />
		  </Card.Text>
		</Card.Body>
		<Card.Footer className="text-muted">
		  <b>Start: </b> 
		  		  {/* {data.startAt} */}
				  {new Date(data.startAt).toLocaleDateString()}
					<br/>
				   {/* <InlineEdit text={storedTextstartAt} onSetText={text => handleNewTextstartAt(text)} /> */}
				   select new to edit
				   <DatePicker
              		selected={storedTextstartAt}
              		onChange={text => handleNewTextstartAt(text)}
              		selectsStart
              		startDate={storedTextstartAt}
              		endDate={storedTextendAt}
				   />
		  <div className="divider"/>
		  <b>End: </b> 
		  		  {/* {data.endAt} */}
					{new Date(data.endAt).toLocaleDateString()}
					<br/>
					{/* <InlineEdit text={storedTextendAt} onSetText={text => handleNewTextendAt(text)} /> */}
					select new to edit
					<DatePicker
              		 selected={storedTextendAt}
              		 onChange={text => handleNewTextendAt(text)}
              		 selectsEnd
              		 startDate={storedTextstartAt}
             		 endDate={storedTextendAt}
              		 minDate={storedTextstartAt}
            		/>
	    </Card.Footer>
     </Card>
  	</div>    
  );
};

export default ExpandedComponentEdit;