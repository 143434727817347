import React, { useEffect } from 'react'
//Client Data
import moduleData from '../data/moduleData'
//Components
import CompanyComponent from './CompanyComponent';
//GraphQL
import { useQuery } from '@apollo/client'
import { QUERY_ME, GET_COMPANIES} from '../utils/queries';
//Utils and GlobalState
import { tableDataCreate} from './CompanyTable/CompanyData';
import { tableUserContactOptions } from './ContactTable/ContactData';
import { GeneraltableDataCreate} from './GeneralCompanyTable/GeneralCompanyData';
import { usePepperContext } from '../utils/GlobalState'
import { USER_DATA,  COMPANIES_DATA} from '../utils/actions'
import { idbPromise } from '../utils/helpers'
import Auth from '../utils/auth'
import { Spinner } from 'react-bootstrap';

let SimpleDefaultTableDataItemA = [{

}];
let SimpleDefaultTableDataItemB = [{

}];

let Simplecontacttableoptions = [{

}];

const CompaniesRender = (props) => {

  const { activemodule } = moduleData[4]
  const loggedIn = Auth.loggedIn();
  const [state, dispatch] = usePepperContext()
 

	const { loading: userLoading, data: userData, refetch: userRefetch} = useQuery(QUERY_ME);
	const { loading: companiesLoading, data: companiesData} = useQuery(GET_COMPANIES);

  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      })
      idbPromise('userdata', 'put', userData.me)
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        })
      })
    }
    console.log('update')
  }, [userData, userLoading, dispatch])

  useEffect(() => {
    if (companiesData) {
      let allcompaniesData = companiesData.companies
      let allcompaniesDataId = allcompaniesData[0]._id || "1"; // formatting because iDB object requires a an id for keypath
      let formatAllcompaniesData = {
        _id: allcompaniesDataId,
        allcompaniesData
      }
      dispatch({
        type: COMPANIES_DATA,
        companiesdata: formatAllcompaniesData
      });
      //console.log("companiesdata", formatAllcompaniesData)
      idbPromise('companiesdata', 'put', formatAllcompaniesData);
    } else if (!companiesLoading) {
      idbPromise('companiesdata', 'get').then(companiesdata => {
        dispatch({
          type: COMPANIES_DATA,
          companiesdata: companiesdata
        });
        //console.log("companiesdata")
      });
    }
  }, [companiesData, companiesLoading, dispatch]);

  if (userData && companiesData) {
    if (!userData.me.usercompanies || !companiesData.companies) {
      return ( 
        <>
        <p> No company data available... </p> 
        {/* <CloudSpinner/> */ } 
        </>
      );
    } else {
      let stateUserData = userData.me || state.userdata;
      let stateCompaniesData = companiesData.companies || state.companiesdata;
      let tableDataItemA;
      let tableDataItemB;
      let contactsdataoptions;
      //console.log("usercompanies data available", {stateUserData});
      //console.log("companies data available", {stateCompaniesData});
      if (!tableDataCreate(stateUserData)) {
        //console.log("Data not computed");
        tableDataItemA = SimpleDefaultTableDataItemA
        tableDataItemB = GeneraltableDataCreate(stateCompaniesData)
        contactsdataoptions = Simplecontacttableoptions
        return(
          <CompanyComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} companiesData={companiesData} tableDataItemB={tableDataItemB} contactsdataoptions={contactsdataoptions}/>
        )
        }
      else if (!GeneraltableDataCreate(stateCompaniesData)) {
        //console.log("Data not computed");
        tableDataItemA = tableDataCreate(stateUserData)
        tableDataItemB = SimpleDefaultTableDataItemB  
        contactsdataoptions = Simplecontacttableoptions
        return(
          <CompanyComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} companiesData={companiesData} tableDataItemB={tableDataItemB} contactsdataoptions={contactsdataoptions}/>
        )
        }
      else if (!tableDataCreate(stateUserData) || !GeneraltableDataCreate(stateCompaniesData)) {
          //console.log("Data not computed");
          tableDataItemA = SimpleDefaultTableDataItemA
          tableDataItemB = SimpleDefaultTableDataItemB  
          contactsdataoptions = Simplecontacttableoptions
          return(
            <CompanyComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} companiesData={companiesData} tableDataItemB={tableDataItemB} contactsdataoptions={contactsdataoptions}/>
          )
          }
      else {
        tableDataItemA = tableDataCreate(stateUserData)
        tableDataItemB = GeneraltableDataCreate(stateCompaniesData)
        contactsdataoptions = tableUserContactOptions(stateUserData)
        //console.log("User Companies Data for Table", tableDataItemA)
        //console.log("Companies Data for Table", tableDataItemB)
        };
        return ( 
          <CompanyComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} companiesData={companiesData} tableDataItemB={tableDataItemB} contactsdataoptions={contactsdataoptions}/>
          );
      }
    } else {
      return (
        <>
           <div className='container text-center'>
            <Spinner />
           </div>
        </>)
      }
    };

export default CompaniesRender;

