import React, { useEffect}from 'react';
import { Link } from "react-router-dom";
//Client Data
import moduleData from '../data/moduleData'
//Components
import LettersRender from '../components/LettersRender';
import LetterForm from '../components/LetterForm';
// import SideBarParagraph from '../components/Sidebar/SidebarParagraph';
//GraphQL
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../utils/queries';
//Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import { USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
import { useAuthContext } from '../utils/AuthContext'
import { Spinner } from 'react-bootstrap';

const Letters = () => {
  // Letters: activemodule is hardcoded in JSX below as moduleData[6]; //hardcoded current module instead of useParams
  const { isAuthenticated } = useAuthContext()
  const loggedIn = isAuthenticated;
  const [state, dispatch] = usePepperContext();
  const { loading: userLoading, data: userData, refetch: userRefetch } = useQuery(QUERY_ME);
  const moduleLoading = false;


  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me
      });
      idbPromise('userdata','put', userData.me);
    } 
    // add else if to check if `loading` is undefined in `useQuery()` Hook
    else if (!userLoading) {
      // since we're offline, get all of the data from the store
      idbPromise('userdata','get').then(userdata => {
        // use retrieved data to set global state for offline browsing
        dispatch({
          type: USER_DATA,
          userdata: userdata
        });
      });
    }
    console.log('me updated')
  }, [userData, userLoading, dispatch]);


  return (
    <div> 
        {loggedIn ? (
    
            <>
            <div className="container">
              <main>
              <LetterForm userRefetch={userRefetch}/>
                  <div>{moduleLoading ? <div className='container text-center'>
    <Spinner />
  </div> : <LettersRender user={state.userdata} activemodule={moduleData[6]} userRefetch={userRefetch} />}</div>
              </main>
              <hr />
            </div>
            </> ) :
      (
        <>
        {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of this page.</h4> 
        </>
      )
         }
    </div>
  );
};

export default Letters;
