// see SignupForm.js for comments
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Col } from 'react-bootstrap'
import { CONFIRM_USER } from '../utils/mutations'
import { useMutation } from '@apollo/client'
// icons
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ConfirmEmail = () => {
  const { idToken } = useParams()
  // const { confirmed } = false;
  const [confirmUser] = useMutation(CONFIRM_USER, {
    onCompleted: (data) => {
      console.log(data)
      setConfirmed(true)
    },
  })
  const [showAlert, setShowAlert] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      handleClick()
    }
  }, [isLoading, handleClick()])

  const handleClick = async () => {
    setLoading(true)
    console.log(idToken)
    try {
        await confirmUser({
        variables: { confirmed, idToken },
      })
    } catch (e) {
      console.error(e)
      setShowAlert(true)
    }
  }

  return (
    <div className='container'>
      <Col className='logincard' md={{ span: 6, offset: 3 }}>
        <Link className='btn btn-link' to='/?welcome'>
          ← Go to Plan
        </Link>
        {showAlert ? (
          <>
            <h3 className='text-center'>Looks like your token expired.</h3>
            <div className='form-group col text-right'>
              <Link to={`/signup`} className='btn btn-link pr-0'>
                Go to Sign Up →
              </Link>
            </div>
          </>
        ) : (
          <div className='my-4 text-center'>
            {confirmed ? (
              <>
                <h3 className='my-4 text-center'>
                  Thank you for confirming your email
                </h3>
                <Button
                  className='btn btn-success btn-circle btn-xl'
                  disabled={confirmed}
                >
                  {<FontAwesomeIcon icon={faCheck} />}
                </Button>
              </>
            ) : (
              <>
                <h3 className='my-4 text-center'>
                  Please confirm your email link
                </h3>
                <Button
                  variant='success'
                  disabled={isLoading}
                  onClick={!isLoading ? handleClick : null}
                >
                  {isLoading ? 'Verifying…' : 'Click to verify'}
                </Button>
              </>
            )}
          </div>
        )}
      </Col>
    </div>
  )
}

export default ConfirmEmail
