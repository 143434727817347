import React, { useState, useEffect} from 'react';
// import { useParams, Link } from "react-router-dom";
import { Button, Card } from 'react-bootstrap';
// import InlineEdit from '../InlineEdit'
//GraphQL
import { useQuery, useMutation} from '@apollo/client';
import { QUERY_ME } from '../../utils/queries';
import { GET_COMPANIES} from '../../utils/queries';
import { ADD_USERCOMPANY} from '../../utils/mutations';
//Utils and GlobalState
import { usePepperContext } from '../../utils/GlobalState';
// import { USER_DATA } from '../../utils/actions';
import { COMPANIES_DATA} from '../../utils/actions'
import { idbPromise } from '../../utils/helpers';

let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	fontWeight: "100",
	filter: "grayscale(100%)"
  };

const GeneralExpandedComponentEdit = ({data}) => {
//console.log("id", data._id)
// eslint-disable-next-line
const [state, dispatch] = usePepperContext();
const { refetch: userRefetch} = useQuery(QUERY_ME);
const { loading: companiesLoading, data: companiesData } = useQuery(GET_COMPANIES);


useEffect(() => {
	if (companiesData) {
	 let allcompaniesData = companiesData.companies
	 let allcompaniesDataId = allcompaniesData[0]._id || "1";// formatting because iDB object requires a an id for keypath
	 let formatAllcompaniesData = {_id: allcompaniesDataId, allcompaniesData}
	  dispatch({
	  type: COMPANIES_DATA,
	  companiesdata: formatAllcompaniesData
	  });
	//console.log("companiesdata", formatAllcompaniesData)
	idbPromise('companiesdata','put', formatAllcompaniesData);
	} else if (!companiesLoading) {
	  idbPromise('companiesdata','get').then(companiesdata => {
	  dispatch({
		type: COMPANIES_DATA,
		companiesdata: companiesdata
	  });
	  });
	}
}, [companiesData, companiesLoading, dispatch ]);

//set initial value
	let companyName = data.companyName; // "Default",
	let companyDescription = data.companyDescription; // "Default",
	let companyLocation = data.companyLocation; // "Default",
	let companyFoundedYear = data.companyFoundedYear; // "Default",
	let companyUrl = data.companyUrl; // "Default",
	let companyDomain = data.companyDomain; // "Default",
	let companySize = data.companySize; // "Default",
	let companyCulture = data.companyCulture; // ["Default"],
	let companyValues = data.companyValues; // ["Default"],
	//let contacts = data.contacts; // "Default Note",
//set initial value

const [storedcompanyName, setStoredCompanyName] = useState(companyName);
const [storedcompanyDescription, setStoredCompanyDescription] = useState(companyDescription);
const [storedcompanyLocation, setStoredCompanyLocation] = useState(companyLocation);
const [storedcompanyFoundedYear, setStoredCompanyFoundedYear] = useState(companyFoundedYear);
const [storedcompanyUrl, setStoredCompanyUrl] = useState(companyUrl);
const [storedcompanyDomain, setStoredCompanyDomain] = useState(companyDomain);
const [storedcompanySize, setStoredCompanySize] = useState(companySize);
const [storedcompanyCulture, setStoredCompanyCulture] = useState(companyCulture);
const [storedcompanyValues, setStoredCompanyValues] = useState(companyValues);

//console.log({storedcompanyName});

//define mutation	
const [addusercompany, { error: addusercompanyerror }] = useMutation(ADD_USERCOMPANY, {
	onCompleted: async data => userRefetch()});

const handleFormSubmit = async event => {
	event.preventDefault();
		try {
		  // add to database
		  await addusercompany({
			variables: { 
			  companyName: storedcompanyName,
			  companyDescription: storedcompanyDescription,
			  companyLocation: storedcompanyLocation,
			  companyFoundedYear: storedcompanyFoundedYear,
			  companyUrl: storedcompanyUrl,
			  companyDomain: storedcompanyDomain,
			  companySize: storedcompanySize,
			  companyCulture: storedcompanyCulture,
			  companyValues: storedcompanyValues,
			  // contacts: storedcontacts, 
			}
		  });
	
		} catch (e) {
		  console.error(e);
	}
};

  return (
	<div>
   		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <Card style={(expandedCardClass)}>
		<Card.Header className="text-muted">
		  <br/>
			<div className="divider"/>
			  	Company:  
				  {data.companyName}
			<div className="divider"/>
			<div className="divider"/>
				Size:  
				  {data.companySize}
			<div className="divider"/>
			<div className="divider"/>
				Founded:  
				  {data.companyFoundedYear}
			<div className="divider"/>
				Location:  
				  {data.companyLocation}
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
			  {data.companyDescription}
		  </Card.Text>
		  <Card.Text>
			  {data.companyCulture.join(" , ")}
		  </Card.Text>
		  <Card.Text>
		  	  {data.companyValues.join(" , ")}
		   </Card.Text>
		</Card.Body>
		<Card.Footer className="text-muted">
			URL & Domain:
			<br/>
				{data.companyUrl}
			<br/>
				{data.companyDomain}
	  </Card.Footer>
    </Card>
	<>
		<Button 
                className='btn d-block w-20' 
                type="submit" 
                variant='custom'
                style={{ margin: 5 }}
                onClick={handleFormSubmit}
                >
                 Click to save this company
        </Button>
	</>
    </div>
  );
};

export default GeneralExpandedComponentEdit;