import React, { useState, useEffect} from 'react';
// import { useParams, Link } from "react-router-dom";
import { Button, Card } from 'react-bootstrap';
// import InlineEdit from '../InlineEdit'
//GraphQL
import { useQuery, useMutation} from '@apollo/client';
import { QUERY_ME } from '../../utils/queries';
import { GET_CONTACTS} from '../../utils/queries';
import { ADD_USERCONTACT} from '../../utils/mutations';
//Utils and GlobalState
import { usePepperContext } from '../../utils/GlobalState';
// import { USER_DATA } from '../../utils/actions';
import { CONTACTS_DATA} from '../../utils/actions'
import { idbPromise } from '../../utils/helpers';

let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	fontWeight: "100",
	filter: "grayscale(100%)"
  };

const GeneralExpandedComponentEdit = ({data}) => {
//console.log("id", data._id)
const [state, dispatch] = usePepperContext();
const { refetch: userRefetch} = useQuery(QUERY_ME);
const { loading: contactsLoading, data: contactsData } = useQuery(GET_CONTACTS);


useEffect(() => {
	if (contactsData) {
	 let allcontactsData = contactsData.contacts
	 let allcontactsDataId = allcontactsData[0]._id || "1";// formatting because iDB object requires a an id for keypath
	 let formatAllcontactsData = {_id: allcontactsDataId, allcontactsData}
	  dispatch({
	  type: CONTACTS_DATA,
	  contactsdata: formatAllcontactsData
	  });
	//console.log("contactsdata", formatAllcontactsData)
	idbPromise('contactsdata','put', formatAllcontactsData);
	} else if (!contactsLoading) {
	  idbPromise('contactsdata','get').then(contactsdata => {
	  dispatch({
		type: CONTACTS_DATA,
		contactsdata: contactsdata
	  });
	  });
	}
}, [contactsData, contactsLoading, dispatch ]);

//set initial value
let firstName = data.firstName; // "Default",
let lastName = data.lastName; // "Default",
let about = data.about; // "Default",
let location = data.location; // "Default",
let country = data.country; // "Default",
let linkedIn = data.linkedIn; // "Default",
let twitter = data.twitter; // "Default",
let gender = data.gender; // "Default",
let role = data.role; // "Default",
let seniority = data.seniority; // "Default",
let city = data.city; // "Default",
let Dstate = data.state; // "Default",
let facebook = data.facebook; // "Default",
let crunchbase = data.crunchbase; // "Default",
let title = data.title; // "Default",
let bio = data.bio; // ["Default"],
let github = data.github; // ["Default"],
//set initial value

const [storedfirstName, setStoredfirstName] = useState(firstName);
const [storedlastName, setStoredlastName] = useState(lastName);
const [storedabout, setStoredabout] = useState(about);
const [storedlocation, setStoredlocation] = useState(location);
const [storedcountry, setStoredcountry] = useState(country);
const [storedlinkedIn, setStoredlinkedIn] = useState(linkedIn);
const [storedtwitter, setStoredtwitter] = useState(twitter);
const [storedgender, setStoredgender] = useState(gender);
const [storedrole, setStoredrole] = useState(role);
const [storedseniority, setStoredseniority] = useState(seniority);
const [storedcity, setStoredcity] = useState(city);
const [storedstate, setStoredstate] = useState(Dstate);
const [storedfacebook, setStoredfacebook] = useState(facebook);
const [storedcrunchbase, setStoredcrunchbase] = useState(crunchbase);
const [storedtitle, setStoredtitle] = useState(title);
const [storedbio, setStoredbio] = useState(bio);
const [storedgithub, setStoredgithub] = useState(github);

//console.log({storedfirstName});

//define mutation	
const [addusercontact] = useMutation(ADD_USERCONTACT, {
	onCompleted: async data => userRefetch()});

const handleFormSubmit = async event => {
	event.preventDefault();
		try {
		  // add to database
		  await addusercontact({
			variables: { 
				firstName: storedfirstName || "a",
				lastName: storedlastName || "default",
				gender: storedgender || "default",
				title: storedtitle || "default",
				role:  storedrole || "default",
				seniority: storedseniority || "default",
				location: storedlocation || "default",
				city: storedcity ||  "default",
				state:  storedstate || "default",
				country:  storedcountry || "default",
				linkedIn: storedlinkedIn ||  "default",
				twitter: storedtwitter ||  "default",
				crunchbase: storedcrunchbase || "default",
				facebook: storedfacebook || "default",
				github: storedgithub || "default",
				bio:  storedbio || "default",
				about: storedabout || "default"	
			  // contacts: storedcontacts,
			}
		  });
	
		} catch (e) {
		  console.error(e);
	}
};

  return (
	<div>
   		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
	<Card style={(expandedCardClass)}>
		<Card.Header className="text-muted">
		  <br/>
			<div className="divider"/>
			  	Gender:  {data.gender}
			<div className="divider"/>
			<div className="divider"/>
				Title:  {data.title}
			<div className="divider"/>
			<div className="divider"/>
				Role:  {data.role}
			<div className="divider"/>
				Location:  {data.location}
		  <br/>
		  <br/>
			<div className="divider"/>
			  	Seniority:  {data.seniority}
			<div className="divider"/>
			<div className="divider"/>
				City: {data.city}
			<div className="divider"/>
			<div className="divider"/>
				State: {data.state}
			<div className="divider"/>
				Country: {data.country}
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
		  	Name:  {data.firstName}{" "}{data.lastName}
		  </Card.Text>
		  <Card.Text>
			Bio:  {data.bio}
		  </Card.Text>
		  <Card.Text>
			About:  {data.about}
		   </Card.Text>
		</Card.Body>
		<Card.Footer className="text-muted">
			<br/>
				<div className="divider"/>
				<i className="fab fa-linkedin-in"/>
				<div className="divider"/>
					{data.linkedIn}
				<div className="divider"/>
				<div className="divider"/>
				<i className="fab fa-twitter"/>
				<div className="divider"/>
					{data.twitter}
			<br/>
				<div className="divider"/>
				<i class="fab fa-facebook"/>
				<div className="divider"/>
					{data.facebook}
				<div className="divider"/>
				<div className="divider"/>
				<i className="fab fa-github"/>
				<div className="divider"/>
					{data.github}
				<div className="divider"/>
				    Crunchbase: 
				<div className="divider"/>
					{data.crunchbase}
			<br/>

	  </Card.Footer>
    </Card>
	<>
		<Button 
                className='btn d-block w-20' 
                type="submit" 
                variant='custom'
                style={{ margin: 5 }}
                onClick={handleFormSubmit}
                >
                 Click to save this contact
        </Button>
	</>
    </div>
  );
};

export default GeneralExpandedComponentEdit;