import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import {
  Modal,
  Tab,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from 'react-bootstrap'
// import ActivityFormMoreInputs from './ActivityFormMoreInputs'
import ExpandedComponent from './ExpandedComponent'
import ExpandedComponentEdit from './ExpandedComponentEdit'
//GraphQL
import { useMutation } from '@apollo/client'
import { DELETE_USER_ACTIVITY } from '../../../utils/mutations'
//Utils and GlobalState
import { tableActivityDataCreate, downloadCSV } from './BoardTableData'

const BoardActivityTable = (props) => {
  const { tableDataItemA, jobsdataoptions, boardData, boardRefetch } = props
  const data = tableDataItemA
  const { boardId } = useParams()

  const [deleteuseractivity] = useMutation(
    DELETE_USER_ACTIVITY,
    {
      onCompleted: async (data) => boardRefetch(),
    }
  )

  let date = new Date(Date.now()) //date object just now - used for comparing 2 dates

  const [selectedRows, setSelectedRows] = React.useState([])
  const [toggleCleared, setToggleCleared] = React.useState(false)
  const [modalValueDelete, setModalValueDelete] = useState({
    show: false,
    selectedRows: {},
  })
  const [modalValueEdit, setModalValueEdit] = useState({
    show: false,
    selectedRows: {},
  })

  //Handle Delete
  const handleDelete = async (data) => {
    // clear value
    //console.log("data", data)
    try {
      // delete
      await deleteuseractivity({
        variables: {
          boardId: boardId,
          activityId: data._id,
        },
      })

      // clear form value
      setModalValueDelete({ show: false, selectedRows: {} })
    } catch (e) {
      console.error(e)
    }
  }

  //Handle Escape Delete
  const handleEscapeDelete = async (data) => {
    // clear value
    setModalValueDelete({ show: false, selectedRows: {} })
  }

  //Handle Escape Edit
  const handleEscapeEdit = async () => {
    // clear value
    setModalValueEdit({ show: false, selectedRows: {} })
  }

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows)
    //console.log("handleRowSelected", selectedRows)
  }, [])

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      setToggleCleared(!toggleCleared)
      setModalValueDelete({ show: true, selectedRows: selectedRows })
    }
    const handleEdit = () => {
      setToggleCleared(!toggleCleared)
      setModalValueEdit({ show: true, selectedRows: selectedRows })
    }
    return (
      <div>
        <Button
          key='edit'
          onClick={handleEdit}
          style={{ backgroundColor: 'green' }}
          icon
        >
          Edit
        </Button>
        <div className='divider' />
        <Button
          key='delete'
          onClick={handleDelete}
          style={{ backgroundColor: 'red' }}
          icon
        >
          Delete
        </Button>
      </div>
    )
  }, [selectedRows, toggleCleared])
  //data removed from depend messes with check box selection

  const Export = ({ onExport }) => (
    <Button variant='success' onClick={(e) => onExport(e.target.value)}>Export</Button>
  )
  const actionsMemo = React.useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(tableActivityDataCreate(boardData.board))}
      />
    ),
    []
  )
  //Organizing data for Export as CSV Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  //
  const boardNameTable = boardData.board.boardName
  const activityTableTitle = `Activity for ${boardNameTable}`

  const DateStartWrap = (row) => (
    <p>{new Date(row.startAt).toLocaleDateString()}</p>
  )
  const DateEndWrap = (row) => <p>{new Date(row.endAt).toLocaleDateString()}</p>
  //Begin define columns and row rendering conditionals
  const columns = useMemo(
    () => [
      // {
      // 	cell: () => <button onClick={handleAction()}>Action</button>,
      // 	ignoreRowClick: true,
      // 	allowOverflow: true,
      // 	button: true,
      // },
      {
        name: 'Title',
        selector: (row) => row.activityTitle,
        sortable: true,
        //grow: 2,
        right: true,
      },
      {
        name: 'Note',
        selector: (row) => row.activitynote,
        sortable: true,
        right: true,
      },
      {
        name: 'Start',
        // selector: row => row.startAt,
        selector: (row) => <DateStartWrap {...row} />,
        sortable: true,
        right: true,
      },
      {
        name: 'End',
        // selector: row => row.endAt,
        selector: (row) => <DateEndWrap {...row} />,
        sortable: true,
        right: true,
        conditionalCellStyles: [
          {
            when: (row) => new Date(row.endAt) >= date,
            style: {
              backgroundColor: 'rgba(63, 195, 128, 0.9)',
              color: 'white',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
          {
            when: (row) => new Date(row.endAt) < date,
            style: {
              backgroundColor: 'rgba(248, 148, 6, 0.9)',
              color: 'white',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        ],
      },
      // {
      // 	name: 'Company',
      // 	selector: row => row.company,
      // 	sortable: true,
      // 	right: true,
      // },
      {
        name: 'Card',
        selector: (row) => row.cards,
        sortable: true,
        right: true,
      },
      // {
      // 	name: 'Board',
      // 	selector: row => row.board,
      // 	sortable: true,
      // 	right: true,
      // },
      {
        name: 'Completed',
        selector: (row) => row.activityCompleted,
        sortable: true,
        right: true,
        conditionalCellStyles: [
          {
            when: (row) => row.activityCompleted === true,
            style: {
              backgroundColor: 'rgba(63, 195, 128, 0.9)',
              color: 'white',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
          {
            when: (row) => row.activityCompleted !== true,
            style: {
              backgroundColor: 'rgba(248, 148, 6, 0.9)',
              color: 'white',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        ],
      },
    ],
    []
  )
  const conditionalRowStyles = [
    {
      when: (row) => new Date(row.endAt) < date,
      style: {
        backgroundColor: 'rgba(248, 148, 6, 0.9)',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ]
  //End define columns and row rendering conditionals

  //Begin Define Filter Component
	const FilterComponent = ({ filterText, onFilter, onClear }) => {
		const inputRef = useRef(null);
  
	// Set focus on the input field when filterText changes
		useEffect(() => {
	  		inputRef.current.focus();
		}, [filterText]);
  
		return (
	  	<InputGroup className="mb-3">
				<FormControl
		  		id="search"
		  		type="text"
		 		  placeholder="Filter By activity title" //What can be filtered
		  		aria-label="Search Input"
		  		value={filterText}
		  		onChange={onFilter}
		  		aria-describedby="basic-addon2"
          autoComplete='off'
		  		ref={inputRef} // Attach the ref to the input field
			  />
			  <Button variant="outline-secondary" id="button-addon2" type="button" onClick={onClear}>
		  		Clear
			  </Button>
	  	</InputGroup>
		);
  	};

		const [filterText, setFilterText] = React.useState('');
    	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

		const subHeaderComponentMemo = React.useMemo(() => {
    		const handleClear = () => {
    			if (filterText) {
    				setResetPaginationToggle(!resetPaginationToggle);
    				setFilterText('');
    			}
    		};
   
    		return (
    			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    		);
    	}, [filterText, resetPaginationToggle]);
//End Define Filter Component
		
//main code extracts filtered data for rendering in table	
		const filteredItems = data.filter(
			item => item.activityTitle && item.activityTitle.toLowerCase().includes(filterText.toLowerCase()), //What can be filtered
			);
			// console.log("filteredItems", filteredItems)
//End main code extracts filtered data for rendering in table

  return (
    <div>
      <div className='table-container'>
      <DataTable
        title={activityTableTitle}
        data={filteredItems}
        columns={columns}
        actions={actionsMemo}
        expandableRows={true}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
        pagination
        fixedHeader={true}
        fixedHeaderScrollHeight={'500px'}
        conditionalRowStyles={conditionalRowStyles}
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      </div>
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='text-center'
        show={modalValueDelete.show}
        style={{ overflowY: 'scroll' }}
      >
        <Modal.Body>
          <h3 className='py-4'> You can delete only one activity at a time</h3>
          <Row>
            {selectedRows &&
              selectedRows.map((data, k) => (
                <Col key={k}>
                  <ExpandedComponent data={data} />
                  <h2 className='py-4'> delete this activity? </h2>
                  <Tab.Container id='left-tabs-example' defaultActiveKey={k}>
                    <Row>
                      <div className='py-4 d-flex justify-content-center'>
                        <button
                          className='btn-tutorial '
                          onClick={() => handleDelete(data)}
                        >
                          Yes
                        </button>
                        <div className='divider' />
                        <button
                          className='btn-tutorial '
                          onClick={() => handleEscapeDelete(data)}
                        >
                          No
                        </button>
                      </div>
                    </Row>
                  </Tab.Container>
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='text-center'
        show={modalValueEdit.show}
        style={{ overflowY: 'scroll' }}
      >
        <Modal.Body>
          <h2 className='py-4'> edit activity? </h2>
          <Row>
            {selectedRows &&
              selectedRows.map((data, k) => (
                <Col key={k}>
                  <Tab.Container id='left-tabs-example' defaultActiveKey={0}>
                    <Row>
                      <ExpandedComponentEdit
                        jobsdataoptions={jobsdataoptions}
                        data={data}
                      />
                    </Row>
                  </Tab.Container>
                </Col>
              ))}
          </Row>
          <Tab.Container id='left-tabs-example' defaultActiveKey={1}>
            <Row>
              <div className='py-4 d-flex justify-content-center'>
                <button
                  className='btn-tutorial '
                  onClick={() => handleEscapeEdit(modalValueEdit)}
                >
                  Done
                </button>
              </div>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default BoardActivityTable
