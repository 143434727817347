// import React, { useMemo, useState, useCallback, useEffect } from 'react';
// import { useParams, Link } from "react-router-dom";
// import DataTable, { createTheme } from 'react-data-table-component';
// import { Modal, Tab, Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap';
// import ActivityFormMoreInputs from './ActivityFormMoreInputs';
// import ExpandedComponent from './ExpandedComponent';
// import ExpandedComponentEdit from './ExpandedComponentEdit';
// //GraphQL
// import { useQuery, useMutation} from '@apollo/client';
// import { QUERY_ME, GET_BOARD} from '../../../utils/queries';
// import { DELETE_USER_ACTIVITY } from '../../../utils/mutations';
// //Utils and GlobalState
// import { usePepperContext } from '../../../utils/GlobalState';
// import { BOARD_DATA } from '../../../utils/actions';
// import { USER_DATA } from '../../../utils/actions';
// import { idbPromise } from '../../../utils/helpers';
import { tableJobsDataCreate, findJobTitleFromId} from '../BoardJobsComponent/BoardJobsData';

// activityCategory: 1
// activityCompleted: false
// activityTitle: "Networking call"
// activitynote: "Have a Networking call with an ML Ops Leader"
// boardId: {__typename: 'userboard', _id: '55af7a1cb0fd80a876238971', boardName: 'PaaS: Technology Board'}
// cards: {__typename: 'userjob', _id: null, actionType: null, color: null, createdByWorkflow: null, …}
// companyId: {__typename: 'company', _id: '40f609d197b6b16198446788', companyName: null}
// endAt: "01/01/23"
// startAt: "01/01/22"
// usercompany: {__typename: 'usercompany', _id: 'dca04b8e83418ab252565bcb', companyName: 'Peppercorn.ai'}
// __typename: "useractivity"
// _id: "61f99087540136df094f636b"

const companydatacreate = (thisactivitydata) => {
    let basecompany = thisactivitydata.usercompany //takes the company object array for a activitydata
    if (basecompany != null) {
    let companyName = basecompany._id
        //define the new company
      return ( 
        companyName //the company value
           )
      }
    };

const boarddatacreate = (thisactivitydata) => {
  // console.log("What does board data look like?", thisactivitydata )
    let baseboard = thisactivitydata.boardId //takes the board object array for a activitydata
    if (baseboard != null) {
    let boardName = baseboard._id
      return ( 
        boardName //the board value
           )
      }
    };

//working attempt  renders card ID 05/18/2022
// const cardsdatacreate = (thisactivitydata, jobsdata) => {
//     let basecards = thisactivitydata.cards //takes the cards object array for a activitydata
//     if (basecards != null) {
//         let cardsId = basecards._id  
//         //console.log({cardsId})
//           let cardsName = cardsId
//           //define the new cards
//         return ( 
//           cardsName //the cards value
//              )

//       //   if (jobsdata != null) {   
//       //   let cardsName = findJobTitleFromId(jobsdata, cardsId)
//       //   //define the new cards
//       // return ( 
//       //   cardsName //the cards value
//       //      )
//       //  }
//       }
//       else
//       {
//         //console.log("This activity has no card")
//         let cardsName = "no job id associated"
//         return ( 
//           cardsName //the cards value
//              )
//       }
//     };

//version NEW rendering Job Title
const cardsdatacreate = (thisactivitydata, jobsdata) => {
    let basecards = thisactivitydata.cards //takes the cards object array for a activitydata
    if (basecards != null) {
        let cardsId = basecards._id  
        //console.log({cardsId})
        if (jobsdata != null) {   
          let cardsName = findJobTitleFromId(jobsdata, cardsId)
          //set value
          return ( 
            cardsName//the cards value
          )
        }
        else
        {
         let cardsName = "No Title Found"
          return ( 
          cardsName//the cards value
          )
        }
      }
      else
      {
        //console.log("This activity has no card")
        let cardsName = "no job card"
        return ( 
          cardsName //the cards value
             )
      }
    };

export  const tableActivityDataCreate = (board) => {
    let allactivitydata = board.useractivity
    let jobsdata = tableJobsDataCreate(board)
     //console.log( {jobsdata})
    if (allactivitydata != null && allactivitydata.length > 0) {
        let allactivitydataLength = allactivitydata.length;//finds the length of the lanes object array
        let activity = [] //create a new activity array
        let activityobject = {}
        // console.log( {allactivitydata}, {allactivitydataLength} )
        for (let i = 0; i < allactivitydataLength; i += 1) {
          activityobject[i] = {
            _id:allactivitydata[i]._id,
            activityCategory: allactivitydata[i].activityCategory || 1,
            activityCompleted: allactivitydata[i].activityCompleted,
            activityTitle: allactivitydata[i].activityTitle,
            activitynote: allactivitydata[i].activitynote,
            endAt: allactivitydata[i].endAt,
            startAt: allactivitydata[i].startAt,
            company: companydatacreate(allactivitydata[i]) || "Default Company",
            cards: cardsdatacreate(allactivitydata[i], jobsdata) || "Default Job",
            board: boarddatacreate(allactivitydata[i]) || "Default Board"
            } //define the new activity object
            activity.push(activityobject[i]);
            // console.log("activity item", allactivitydata[i], "activityobject item", activityobject[i], "basic cards", baseactivity[i].cards )
          }
           //console.log({activity});
          let newdata= activity
           //console.log({newdata});
        return ( 
          newdata
          )
    }
}

const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];				
            ctr++;
        });
    result += lineDelimiter;
    });

    return result;
}

export  const downloadCSV = (array) => {
	const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}

export const tableDataItem = [
    {
    id: 1,
    activityTitle: "Identify Contacts",
    activitynote: "Identify non-technical founders who have raised seed capital.",
    activityCompleted: true,
    startAt: "09/20/2021",
    endAt: "11/20/2021",
    cards: "Data Scientist",
    board: "PaaS Technologist",
    company: "Huddl.ai"
    },
    {
    id: 2,
    activityTitle: "Networking call",
    activitynote: "Have a Networking call with an ML Ops Leader",
    activityCompleted: false,
    startAt: "09/20/2021",
    endAt: "11/20/2024",
    cards: "Meta Job",
    board: "PaaS Technologist",
    company: "Meta"
    },
    {
    id: 3,
    activityTitle: "Networking call",
    activitynote: "Have a Networking call with CTO",
    activityCompleted: false,
    startAt: "09/20/2021",
    endAt: "11/20/2022",
    cards: "Meta Job",
    board: "PaaS Technologist",
    company: "Meta"
    },

];