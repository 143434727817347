import React, { useState, useEffect, useRef, useCallback } from "react";
import useKeyPress from "../hooks/useKeyPress";
import useOnClickOutside from "../hooks/useOnClickOutside";
//import DOMPurify from "dompurify";


function InlineEdit(props) {
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.text);

  const wrapperRef = useRef(null);
  const textRef = useRef(null);
  const inputRef = useRef(null);

  const enter = useKeyPress("Enter");
  const esc = useKeyPress("Escape");

  const { onSetText } = props;

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      onSetText(inputValue);
      setIsInputActive(false);
      //complete mutation
    }
  });

  const onEnter = useCallback(() => {
    if (enter) {
      onSetText(inputValue);
      setIsInputActive(false);
      //complete mutation
    }
  }, [enter, inputValue, onSetText]);

  const onEsc = useCallback(() => {
    if (esc) {
      setInputValue(props.text);
      setIsInputActive(false);
    }
  }, [esc, props.text]);

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive) {
      inputRef.current.focus();
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and close the editor
      onEnter();
      // if Escape is pressed, revert the text and close the editor
      onEsc();
    }
  }, [onEnter, onEsc, isInputActive]); // watch the Enter and Escape key presses

  const handleInputChange = useCallback(
    event => {
      // sanitize the input a little
      //setInputValue(DOMPurify.sanitize(event.target.value));
      setInputValue((event.target.value));
    },
    [setInputValue]
  );

  const handleSpanClick = useCallback(() => setIsInputActive(true), [
    setIsInputActive
  ]);

  return (
    <span className="inline-text" ref={wrapperRef}>
      <span
        ref={textRef}
        onClick={handleSpanClick}
        className={`inline-text_copy inline-text_copy--${
          !isInputActive ? "active" : "hidden"
        }`}
      >
        {props.text}
      </span>
      <textarea
        ref={inputRef}
        // set the width to the input length multiplied by the x height
        // it's not quite right but gets it close
        // style={{ minWidth: Math.ceil(inputValue.length) + "ch" }}
        style ={{width:"100%"}}
        value={inputValue}
        rows={2}
        onChange={handleInputChange}
        className={`p-2 inline-text_input inline-text_input--${
          isInputActive ? "active" : "hidden"
        }`}
      />
    </span>
  );
}

export default InlineEdit;




// import React, { useState, useEffect, useRef } from "react";
// import useKeyPress from "../hooks/useKeyPress";
// import useOnClickOutside from "../hooks/useOnClickOutside";



// const InlineEdit = (props) => {
// // const { editText } = props;
// const [isInputActive, setIsInputActive] = useState(false);
// const [inputValue, setInputValue] = useState(props.text || "sample text");


// const wrapperRef = useRef(null);
// const textRef = useRef(null);
// const inputRef = useRef(null);


//  const enter = useKeyPress("Enter");
//  const esc = useKeyPress("Escape");

// console.log(props.onSetText);
//  // check to see if the user clicked outside of this component
//  useOnClickOutside(wrapperRef, () => {
//    if (isInputActive) {
//     setInputValue(props.onSetText);
//      setIsInputActive(false);
//    }
//  });


//  // focus the cursor in the input field on edit start
//  useEffect(() => {
//    if (isInputActive) {
//      inputRef.current.focus();
//    }
//  }, [isInputActive]);


//  useEffect(() => {
//    if (isInputActive) {
//      // if Enter is pressed, save the text and case the editor
//      if (enter) {
//     setInputValue(props.onSetText);
//        setIsInputActive(false);
//      }
//      // if Escape is pressed, revert the text and close the editor
//      if (esc) {
//        setInputValue(props.text);
//        setIsInputActive(false);
//      }
//    }
//  }, [enter, esc]); // watch the Enter and Escape key presses


//  return (
//    <span className="inline-text" ref={wrapperRef}>
//      <span
//        ref={textRef}
//         onClick={() => setIsInputActive(true)}
//        className={`inline-text_copy inline-text_copy--${!isInputActive ? "active" : "hidden"}`}
//      >
//        {props.text}
//      </span>
//      <input
//        ref={inputRef}
//        // set the width to the input length multiplied by the x height
//        // it's not quite right but gets it close
//        style={{ width: Math.ceil(inputValue.length * 0.9) + "ex" }}
//        value={inputValue}
//        onChange={(e) => {
//          setInputValue(e.target.value);
//        }}
//        className={`inline-text_input inline-text_input--${isInputActive ? "active" : "hidden"}`}
//      />
//    </span>
//  );
// }


// export default InlineEdit;
