import React, { useState } from 'react';
import { Button, Modal, Tab, Row, Col } from 'react-bootstrap';
import { Form as MyInput } from 'react-bootstrap';
// import CompanyContactAutocomplete from './CompanyContactAutocomplete';
import { useMutation } from '@apollo/client';
// import DatePicker from "react-datepicker";
// import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import AutocompleteForm from '../AutocompleteForm';
// import Select from 'react-select';
// import "react-datepicker/dist/react-datepicker.css";
import { ADD_USERCOMPANY } from '../../utils/mutations';
// import { QUERY_ME } from '../../utils/queries';

const CompanyFormMoreInputs = (props) => {
  const { contactsdataoptions, userData, userRefetch } = props;

  let datausercontacts = ["1", "2", "3"];
  let defaultCompany = {
    companyName: "Default",
    companyDescription: "Default",
    companyLocation: "Default",
    companyFoundedYear: "Default",
    companyUrl: "Default",
    companyDomain: "Default",
    companySize: "Default",
    companyCulture: ["Default"],
    companyValues: ["Default"],
    usercontacts: datausercontacts,
  };

  const [modalCompanyCreate, setModalCompanyCreate] = useState({ show: false });
  const [characterCountName, setCharacterCountName] = useState(0);
  const [characterCountDescription, setCharacterCountDescription] = useState(0);
  const [characterCountLocation, setCharacterCountLocation] = useState(0);
  const [characterCountFoundedYear, setCharacterCountFoundedYear] = useState(0);
  const [characterCountUrl, setCharacterCountUrl] = useState(0);
  const [characterCountDomain, setCharacterCountDomain] = useState(0);
  const [characterCountSize, setCharacterCountSize] = useState(0);
  const [characterCountCulture, setCharacterCountCulture] = useState(0);
  const [characterCountValues, setCharacterCountValues] = useState(0);
  const [autobuttonSubmit, setAutoButtonSubmit] = useState(false);

  const [addusercompany, { error }] = useMutation(ADD_USERCOMPANY, {
    onCompleted: async data => userRefetch()
  });

  const { handleSubmit, control, watch, setValue, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
      companyName: defaultCompany.companyName,
      companyDescription: defaultCompany.companyDescription,
      companyLocation: defaultCompany.companyLocation,
      companyFoundedYear: defaultCompany.companyFoundedYear,
      companyUrl: defaultCompany.companyUrl,
      companyDomain: defaultCompany.companyDomain,
      companySize: defaultCompany.companySize,
      companyCulture: defaultCompany.companyCulture,
      companyValues: defaultCompany.companyValues,
      usercontacts: datausercontacts,
    }
  });

  const getInput = (selection, suggestions) => {
    let labelArray = [];
    suggestions.map((suggestion) => labelArray.push(suggestion.label));
    const inputIndexSugg = labelArray.indexOf(selection);
    return suggestions[Math.abs(inputIndexSugg)].value;
  };

  const getselectedUsercontacts = (selections, suggestions) => {
    let selectedUsercontacts = [];
    selections.forEach(selection => {
      let contactID = getInput(selection, suggestions);
      selectedUsercontacts.push(contactID);
    });
    return selectedUsercontacts;
  };

  const handleEscape = () => {
    setModalCompanyCreate({ show: false });
  };

  const handleModal = () => {
    setModalCompanyCreate({ show: true });
  };

  const onSubmit = async (data) => {
    const selected = getselectedUsercontacts(data.usercontacts, contactsdataoptions);
    try {
      await addusercompany({
        variables: {
          companyName: data.companyName,
          companyDescription: data.companyDescription,
          companyLocation: data.companyLocation,
          companyFoundedYear: data.companyFoundedYear,
          companyUrl: data.companyUrl,
          companyDomain: data.companyDomain,
          companySize: data.companySize,
          companyCulture: data.companyCulture,
          companyValues: data.companyValues,
          usercontacts: selected,
        }
      });

      setModalCompanyCreate({ show: false });
      setAutoButtonSubmit(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (field, setCharacterCount) => (e) => {
    const value = e.target.value;
    setValue(field, value);
    setCharacterCount(value.length);
  };

  return (
    <div>
      <Button
        className='btn d-block w-20'
        type="button"
        variant='custom'
        style={{ margin: 5 }}
        onClick={handleModal}
      >
        Click to create a new company
      </Button>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={modalCompanyCreate.show}
        style={{ overflowY: "scroll" }}
      >
        <Modal.Body>
          <h3 className="py-4"> Create a new company </h3>
          <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <Row>
              <MyInput>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="companyName"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Name</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company name..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyName", setCharacterCountName)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountName === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountName}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyDescription"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Description</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company description..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyDescription", setCharacterCountDescription)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountDescription === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountDescription}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyLocation"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Location</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company location..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyLocation", setCharacterCountLocation)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountLocation === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountLocation}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyFoundedYear"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Founded Year</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company founded year..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyFoundedYear", setCharacterCountFoundedYear)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountFoundedYear === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountFoundedYear}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyUrl"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Url</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company url..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyUrl", setCharacterCountUrl)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountUrl === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountUrl}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyDomain"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Domain</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company domain..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyDomain", setCharacterCountDomain)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountDomain === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountDomain}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companySize"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Size</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company size..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companySize", setCharacterCountSize)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountSize === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountSize}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyCulture"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Culture</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company culture..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyCulture", setCharacterCountCulture)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountCulture === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountCulture}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <Controller
                    name="companyValues"
                    control={control}
                    render={({ field }) => (
                      <MyInput.Group className="mb-3" controlId="formBasicEmail">
                        <MyInput.Label>Company Values</MyInput.Label>
                        <MyInput.Control
                          as="textarea"
                          rows={1}
                          placeholder="Enter new company values..."
                          {...field}
                          className="form-input col-12 col-md-9"
                          onChange={handleChange("companyValues", setCharacterCountValues)}
                        />
                        <MyInput.Text className="text-muted">
                          <p className={`m-0 ${characterCountValues === 200 ? 'text-error' : ''}`}>
                            Character Count: {characterCountValues}/200
                            {error && <span className="ml-2">Something went wrong...</span>}
                          </p>
                        </MyInput.Text>
                      </MyInput.Group>
                    )}
                  />
                  <div>
                    {datausercontacts && datausercontacts.map((contacts, k) => (
                      <Col key={k}>
                        <div>
                          <label>
                            Contact: {contacts}
                            <AutocompleteForm
                              field={`${k}`}
                              suggestions={contactsdataoptions}
                            />
                          </label>
                        </div>
                      </Col>
                    ))}
                    <div>
                      <Button
                        className='btn d-block w-75 mx-auto'
                        type='submit'
                        variant='success'
                        disabled={autobuttonSubmit && !Object.keys(errors).length === 0}
                      >
                        {autobuttonSubmit ? 'Selected!! ✈ ' : 'Click to add this company'}
                      </Button>
                    </div>
                  </div>
                </form>
              </MyInput>
              <div className="py-4 d-flex justify-content-center">
                <button className="btn-tutorial" onClick={handleEscape}>
                  Escape without adding company
                </button>
              </div>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompanyFormMoreInputs;
