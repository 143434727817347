import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import routing
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import routes from './routes';
// stylesheets
import './App.scss';
// import Components
import { AppNavbar, Breadcrumbs, Footer } from './components/index';
// import GlobalState
import { PepperProvider } from './utils/GlobalState';
//import scroll to top
import ScrollToTop from './utils/ScrollToTop';
import cookie from 'cookie';

import { AuthContextProvider } from './utils/AuthContext';
import ErrorBoundary from './utils/ErrorBoundary';
import { ConfigClient} from './utils/helpers'

// const httpLink = new HttpLink({
//   uri: 'http://localhost:3000/graphql',
//   credentials: 'include',
// });


// const httpLink = new HttpLink({
//   uri: 'https://peppercorn-search-03282022.herokuapp.com/graphql',
//   credentials: 'include',
// })

const httpLink = new HttpLink({
  uri: `${ConfigClient.API_URL}/graphql`,
  credentials: 'include',
})


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  let cookies;

  // Server side
  if (headers) {
    cookies = cookie.parse(headers.cookie || '');
  }

  // Client side
  if (typeof window !== 'undefined') {
    cookies = cookie.parse(document.cookie || '');
  }

  return {
    headers: {
      ...headers,
      authorization: cookies?.token ? `Bearer ${cookies.token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors);
    console.log('networkError', networkError);
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop>
        <ErrorBoundary>
          <div className="flex-row flex-column justify-space-around min-100-vh content">
            <PepperProvider>
              <AuthContextProvider>
                <AppNavbar />
                <Routes>
                  {routes.map(({ path, Component }, key) => (
                    <Route
                      exact
                      path={path}
                      key={key}
                      element={
                        <RouteWrapper path={path} Component={Component} />
                      }
                    />
                  ))}
                </Routes>
              </AuthContextProvider>
            </PepperProvider>
          </div>
          <Footer />
          </ErrorBoundary>
        </ScrollToTop>
      </Router>
    </ApolloProvider>
  );
}

const RouteWrapper = ({ path, Component }) => {
  const params = useParams();
  const crumbs = routes
    // Get all routes that contain the current one.
    .filter((route) => path.includes(route.path))
    // Swap out any dynamic routes with their param values.
    .map((route) => ({
      ...route,
      path: Object.keys(params).length
        ? Object.keys(params).reduce(
            (path, param) =>
              path.replace(`:${param}`, params[param]),
            route.path
          )
        : route.path,
    }));

  return (
    <div>
      <Breadcrumbs crumbs={crumbs} />
      <Component />
    </div>
  );
};

export default App;