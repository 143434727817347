import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Container,
  Col,
  Card,
  Button,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faSpinner,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  ADD_LANE,
  UPDATE_LANES,
  DELETE_LANES,
  ADD_USER_JOB,
  DELETE_USERJOB,
} from '../../utils/mutations';
import { GET_BOARD } from '../../utils/queries';
import SidePanel from './SidePanel';

import './BoardComponent.scss';

const BoardComponent = () => {
  const { boardId } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_BOARD, {
    variables: { boardBoardId: boardId },
  });

  const [columns, setColumns] = useState({});
  const [editingColumnId, setEditingColumnId] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [addingCardColumnId, setAddingCardColumnId] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const titleInputRef = useRef(null);
  const inputRef = useRef(null);
  const panelRef = useRef(null); // Ref for the Offcanvas

  const [addCard, { loading: addingCard }] = useMutation(ADD_USER_JOB, {
    onCompleted: () => refetch(),
  });
  const [addLane, { loading: addingLane }] = useMutation(ADD_LANE, {
    onCompleted: () => refetch(),
  });
  const [deleteuserjob, { loading: deletingCard }] = useMutation(
    DELETE_USERJOB,
    {
      onCompleted: () => refetch(),
    }
  );
  const [deletelanes, { loading: deletingLane }] = useMutation(DELETE_LANES, {
    onCompleted: () => refetch(),
  });
  const [updateLanes] = useMutation(UPDATE_LANES, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    if (data && data.board) {
      setColumns(transformBoardData(data.board));
    }
  }, [data]);

  useEffect(() => {
    if (editingColumnId && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [editingColumnId]);

  const handleAddColumn = () => {
    const defaultTitle = 'New Column';
    addLane({ variables: { boardId, title: defaultTitle } })
      .then((response) => {
        const newLane = response.data.addLane;
        const newColumns = {
          ...columns,
          [newLane.id]: {
            id: newLane.id,
            title: defaultTitle,
            taskIds: [],
            tasks: {},
          },
        };
        setColumns(newColumns);
        setEditingColumnId(newLane.id);
        setEditingTitle(defaultTitle);
      })
      .catch(console.error);
  };

  const handleEditColumn = (columnId, currentTitle) => {
    setEditingColumnId(columnId);
    setEditingTitle(currentTitle);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    setColumns((prevColumns) => {
      const start = prevColumns[source.droppableId];
      const finish = prevColumns[destination.droppableId];
      let newColumns = { ...prevColumns };

      if (start === finish) {
        const newTaskIds = Array.from(start.taskIds);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        newColumns[source.droppableId] = {
          ...start,
          taskIds: newTaskIds,
        };

        updateLanes({
          variables: { laneId: source.droppableId, cards: newTaskIds },
        });
      } else {
        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
          ...start,
          taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
          ...finish,
          taskIds: finishTaskIds,
        };

        newColumns[source.droppableId] = newStart;
        newColumns[destination.droppableId] = newFinish;

        updateLanes({
          variables: { laneId: source.droppableId, cards: newStart.taskIds },
        });
        updateLanes({
          variables: {
            laneId: destination.droppableId,
            cards: newFinish.taskIds,
          },
        });
      }

      return newColumns;
    });
  };

  const handleUpdateColumnTitle = (columnId) => {
    if (editingTitle.trim() !== '') {
      updateLanes({
        variables: {
          laneId: columnId,
          title: editingTitle,
          cards: columns[columnId].taskIds,
        },
      })
        .then(() => {
          const newColumns = {
            ...columns,
            [columnId]: { ...columns[columnId], title: editingTitle },
          };
          setColumns(newColumns);
          setEditingColumnId(null);
          setEditingTitle('');
        })
        .catch(console.error);
    } else {
      setEditingColumnId(null);
      setEditingTitle('');
    }
  };

  const openSidePanel = (job) => {
    setSelectedJob(job);
    setIsPanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsPanelOpen(false);
    setSelectedJob(null);
  };

  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      closeSidePanel();
    }
  };

  useEffect(() => {
    if (isPanelOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPanelOpen]);

  const handleKeyDown = (e, columnId) => {
    if (e.key === 'Enter') {
      handleUpdateColumnTitle(columnId);
    } else if (e.key === 'Escape') {
      setEditingColumnId(null);
      setEditingTitle('');
    }
  };

  const handleAddCard = (columnId, description) => {
    if (description.trim() === '') return;
    addCard({ variables: { lanesId: columnId, description } })
      .then((response) => {
        const newCard = response.data.adduserjob;
        const newColumns = { ...columns };
        newColumns[columnId].taskIds.push(newCard._id);
        newColumns[columnId].tasks[newCard._id] = {
          id: newCard._id,
          content: newCard.description,
        };
        setColumns(newColumns);
        setAddingCardColumnId(null);
      })
      .catch(console.error);
  };

  const handleToggleAddCard = (columnId) => {
    setAddingCardColumnId(columnId);
  };

  const onCardDelete = (cardId, columnId, e) => {
    e.stopPropagation(); // Prevent click event from reaching the card
    if (!deletingCard) {
      deleteuserjob({ variables: { userjobId: cardId, laneId: columnId } });
    }
  };

  const onLaneDelete = (laneId) => {
    if (!deletingLane) {
      deletelanes({ variables: { boardId, laneId } });
    }
  };

  if (loading) return <FontAwesomeIcon className='mx-auto' icon={faSpinner} spin />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Button onClick={handleAddColumn} className='add-column-btn top-btn-card'>
        {addingLane ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Add Column +'}
      </Button>
      {error && <div className='error-message'>{error.message}</div>}
      {isPanelOpen && <SidePanel job={selectedJob} onClose={closeSidePanel} show={isPanelOpen} ref={panelRef} />}
      <Container className='kanban-container'>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(columns).map((columnId) => {
            const column = columns[columnId];
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided) => (
                  <Col
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='kanban-column'
                  >
                    <div className='column-header'>
                      {editingColumnId === columnId ? (
                        <InputGroup>
                          <FormControl
                            ref={titleInputRef}
                            value={editingTitle}
                            onChange={(e) => setEditingTitle(e.target.value)}
                            onBlur={() => handleUpdateColumnTitle(editingColumnId)}
                            onKeyDown={(e) => handleKeyDown(e, editingColumnId)}
                            autoFocus
                          />
                        </InputGroup>
                      ) : (
                        <div>
                          <div
                            onClick={() => handleEditColumn(columnId, column.title)}
                            className='edit-icon-pencil'
                          >
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                              className='edit-icon mr-2'
                            />
                            <h2 className='column-title'>{column.title}</h2>
                          </div>
                        </div>
                      )}
                      <div>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          spin={deletingLane}
                          onClick={() => onLaneDelete(columnId)}
                          className='delete-icon'
                        />
                      </div>
                    </div>
                    {column.taskIds.map((taskId, index) => (
                      <Draggable
                        key={taskId}
                        draggableId={taskId}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mb-3"
                            onClick={() => openSidePanel(column.tasks[taskId])}
                          >
                          
                            <Card.Body>
                              <div className='card-text'>
                                {column.tasks[taskId]?.content || 'No Content Available'}
                              </div>
                              <div className='card-delete-icon'>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className='card-close'
                                  onClick={(e) => onCardDelete(taskId, columnId, e)}
                                />
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    {addingCardColumnId === columnId ? (
                      <InputGroup className='mb-2'>
                        <FormControl
                          ref={inputRef}
                          type='text'
                          placeholder='Enter card description'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleAddCard(columnId, e.target.value);
                              e.target.value = ''; // Clear the input after the card is added
                            } else if (e.key === 'Escape') {
                              setAddingCardColumnId(null); // Cancel adding on escape key
                            }
                          }}
                          autoFocus
                        />
                        {addingCard && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className='ms-2 my-auto'
                          />
                        )}
                      </InputGroup>
                    ) : (
                      <Button
                        onClick={() => handleToggleAddCard(columnId)}
                        className='add-card-btn'
                      >
                        + Add Card
                      </Button>
                    )}
                  </Col>
                )}
              </Droppable>
            );
          })}
        </DragDropContext>
      </Container>
      <div className='text-center'>
        {deletingCard ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
        {deletingLane ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
      </div>
    </>
  );
};

function transformBoardData(board) {
  const columnMap = {};
  board.lanes.forEach((lane) => {
    const tasks = {};
    lane.cards.forEach((card) => {
      tasks[card._id] = {
        id: card._id,
        content: card.description || 'No description provided',
      };
    });
    columnMap[lane._id] = {
      id: lane._id,
      title: lane.title,
      taskIds: lane.cards.map((card) => card._id),
      tasks,
    };
  });
  return columnMap;
}

export default BoardComponent;
