import React, { useEffect}from 'react';
import { Link } from "react-router-dom";
//Client Data
// import moduleData from '../data/moduleData'
//Components
import FeedsRender from '../components/Feed/FeedsRender';
// import SideBarParagraph from '../components/Sidebar/SidebarParagraph';
//GraphQL
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../utils/queries';
//Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import { USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
import { useAuthContext } from '../utils/AuthContext'
import { Spinner } from 'react-bootstrap';
//boards should display just that module information
const Feeds = () => {
  // activemodule is hardcoded in JSX below as moduleData[0]; //hardcoded current module instead of useParams
  const { isAuthenticated } = useAuthContext()
const loggedIn = isAuthenticated;
  // eslint-disable-next-line
  const [state, dispatch] = usePepperContext();
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME);
  const moduleLoading = false;

  useEffect(() => {

    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me
      });
    idbPromise('userdata','put', userData.me);
    } else if (!userLoading) {
      idbPromise('userdata','get').then(userdata => {
        dispatch({
          type: USER_DATA,
          userdata: userdata
        });
      });
    }
  }, [userData, userLoading, dispatch]);

  return (
    <div> 
        {loggedIn ? (
            
            <>
            <div className="container">
              <main>
                  <div>{moduleLoading ? <div className='container text-center'>
    <Spinner />
  </div> : <FeedsRender />}</div>
              </main>
              
            </div>
            </> ) :
      (
        <>
        {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of this page.</h4> 
        </>
      )
         }
    </div>
  );
};

export default Feeds;