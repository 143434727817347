import React, { useEffect } from 'react'
//Components
import {  Tab, Nav, Row, Col, Badge } from 'react-bootstrap';
import ContactTable from './ContactTable/ContactTable';
import GeneralContactTable from './GeneralContactTable/GeneralContactTable';


const ContactComponent = (props) => {
	const {
    userData,
    tableDataItemA,
    userRefetch,
    contactsData,
    tableDataItemB
    } = props

        return ( 
            <>
                <main>
                  <div>
                        <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                          <Row>
                            <Col sm={3}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"> 
                                      <Badge className="pill-center mx-2 my-2" bg style={{fontSize:"0.8rem", backgroundColor:"#F2A90B"}}>
                                        My Contacts
                                      </Badge>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"> 
                                      <Badge className="pill-center mx-2 my-2" bg style={{fontSize:"0.8rem", backgroundColor:"#F2A90B"}}>
                                        General Contacts
                                      </Badge>
                                    </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                            <Row>
                              <Tab.Content>
                                  <Tab.Pane eventKey="first">
                                    <ContactTable userData = {userData} tableDataItemA ={tableDataItemA} userRefetch={userRefetch}/>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    <GeneralContactTable contactsData = {contactsData} tableDataItemB ={tableDataItemB} userRefetch={userRefetch}/>
                                  </Tab.Pane>
                              </Tab.Content>
                            </Row>
                          </Row>
                        </Tab.Container>
                    </div>
                  </div>
                </main>
            </>
          );
      }

export default ContactComponent;

