import React, { useEffect } from 'react'
//Client Data
//Components
import ContactComponent from './ContactComponent';
//GraphQL
import { useQuery } from '@apollo/client'
import { QUERY_ME, GET_CONTACTS} from '../utils/queries';
//Utils and GlobalState
import { tableDataCreate} from './ContactTable/ContactData';
import { GeneraltableDataCreate} from './GeneralContactTable/GeneralContactData';
import { usePepperContext } from '../utils/GlobalState'
import { USER_DATA,  CONTACTS_DATA} from '../utils/actions'
import { idbPromise } from '../utils/helpers'
import { Spinner } from 'react-bootstrap';

let SimpleDefaultTableDataItemA = [{

}];
let SimpleDefaultTableDataItemB = [{

}];

const ContactsRender = (props) => {  
  const [state, dispatch] = usePepperContext()
 

	const { loading: userLoading, data: userData, refetch: userRefetch} = useQuery(QUERY_ME);
	const { loading: contactsLoading, data: contactsData} = useQuery(GET_CONTACTS);

  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      })
      idbPromise('userdata', 'put', userData.me)
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        })
      })
    }
    console.log('update')
  }, [userData, userLoading, dispatch])

  useEffect(() => {
    if (contactsData) {
      let allcontactsData = contactsData.contacts
      let allcontactsDataId = allcontactsData[0]._id || "1"; // formatting because iDB object requires a an id for keypath
      let formatAllcontactsData = {
        _id: allcontactsDataId,
        allcontactsData
      }
      dispatch({
        type: CONTACTS_DATA,
        contactsdata: formatAllcontactsData
      });
      //console.log("contactsdata", formatAllcontactsData)
      idbPromise('contactsdata', 'put', formatAllcontactsData);
    } else if (!contactsLoading) {
      idbPromise('contactsdata', 'get').then(contactsdata => {
        dispatch({
          type: CONTACTS_DATA,
          contactsdata: contactsdata
        });
        //console.log("contactsdata")
      });
    }
  }, [contactsData, contactsLoading, dispatch]);

  if (userData && contactsData) {
    if (!userData.me.usercontacts || !contactsData.contacts) {
      return ( 
        <>
        <p> No contact data available... </p> 
        {/* <CloudSpinner/> */ } 
        </>
      );
    } else {
      let stateUserData = userData.me || state.userdata;
      let stateContactsData = contactsData.contacts || state.contactsdata;
      let tableDataItemA;
      let tableDataItemB;
      //console.log("usercontacts data available", {stateUserData});
      //console.log("contacts data available", {stateContactsData});
      if (!tableDataCreate(stateUserData)) {
        //console.log("Data not computed");
        tableDataItemA = SimpleDefaultTableDataItemA
        tableDataItemB = GeneraltableDataCreate(stateContactsData)
        return(
          <ContactComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} contactsData={contactsData} tableDataItemB={tableDataItemB}/>
        )
        }
      else if (!GeneraltableDataCreate(stateContactsData)) {
        //console.log("Data not computed");
        tableDataItemA = tableDataCreate(stateUserData)
        tableDataItemB = SimpleDefaultTableDataItemB  
        return(
          <ContactComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} contactsData={contactsData} tableDataItemB={tableDataItemB}/>
        )
        }
      else if (!tableDataCreate(stateUserData) || !GeneraltableDataCreate(stateContactsData)) {
          //console.log("Data not computed");
          tableDataItemA = SimpleDefaultTableDataItemA
          tableDataItemB = SimpleDefaultTableDataItemB  
          return(
            <ContactComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} contactsData={contactsData} tableDataItemB={tableDataItemB}/>
          )
          }
      else {
        tableDataItemA = tableDataCreate(stateUserData)
        tableDataItemB = GeneraltableDataCreate(stateContactsData)
        //console.log("User Contacts Data for Table", tableDataItemA)
        //console.log("Contacts Data for Table", tableDataItemB)
        };
        return ( 
          <ContactComponent userData={userData} tableDataItemA={tableDataItemA} userRefetch={userRefetch} contactsData={contactsData} tableDataItemB={tableDataItemB}/>
          );
      }
    } else {
      return (
        <>
           <div className='container text-center'>
            <Spinner />
           </div>
        </>)
      }
    };

export default ContactsRender;

