import React from 'react';
//Client Data
//components
import { Row } from 'react-bootstrap';
import BoardAllActivity from './BoardComponent/BoardAllActivity';
//GraphQL

//utils and GlobalState


const ActivityRender = (props) => {
  const { user, activemodule} = props;
  const boards = user.userboards;

  return (
  <div>
    <div>
       <hr />
        <Row>
      {boards && boards.map((board) => (
        <div key={board._id}>
          {board.boardName}
          <BoardAllActivity boardId={board._id}/>
        </div>  
        ))}      
        </Row>
    </div>
  </div>
  );
};

export default ActivityRender;
