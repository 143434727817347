import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Accordion, Form, Button, Row, Col } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { ADD_USER } from '../utils/mutations'; // Ensure ADD_USER is correctly exported from mutations
import { ConfigServer } from '../utils/helpers'; // Ensure ConfigServer is correctly exported from helpers

const Signup = () => {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    uniqueid: '',
    profilePic: '',
  });
  const [signedUp, setSignedUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorName, setErrorName] = useState('');
  const [validated, setValidated] = useState(false);

  const [adduser, { error }] = useMutation(ADD_USER, {
    onCompleted: () => setSignedUp(true),
    onError: (e) => {
      setErrorName(e.message);
      setShowAlert(true);
    },
  });

  useEffect(() => {}, [setFormState]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      await adduser({
        variables: { ...formState },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className='container'>
      <Col className='logincard' md={{ span: 9, offset: 2 }}>
        <main className='flex-row justify-center mb-3 mx-2'>
          {signedUp ? (
            <h3 className='text-center my-2'>
              Almost there. Please check your email and confirm your account before you login.
            </h3>
          ) : (
            <>
              <Link to='/login' className='btn btn-link'>
                ← Go to Login
              </Link>
              <h3 className='text-center my-2'>Sign Up</h3>
              <Card>
                <Card.Body className='text-center'>
                  <Link to='/Terms' className='btn btn-link'>
                    By signing up you accept our terms and policies
                  </Link>
                </Card.Body>
              </Card>

              <div className='my-2 text-center'>
                <a
                  className='button-google'
                  style={{ color: 'black', textDecoration: 'none' }}
                  href={`${ConfigServer.API_URL}/auth/google`}
                >
                  <img
                    src='images/Google_Calendar_icon.svg'
                    alt='google login'
                    className='icon'
                  ></img>
                  Login with Google to Connect Google Calendar
                </a>
              </div>

              <Card>
                <Card.Body className='text-center'>Or signup with Email</Card.Body>
              </Card>
              <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Alert
                  dismissible
                  onClose={() => setShowAlert(false)}
                  show={showAlert}
                  variant='danger'
                >
                  {errorName}
                </Alert>

                <Form.Group className='py-2'>
                  <Form.Label htmlFor='firstName'>First Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='First Name'
                    name='firstName'
                    onChange={handleChange}
                    value={formState.firstName}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    First Name is required!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor='lastName'>Last name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Last name'
                    name='lastName'
                    onChange={handleChange}
                    value={formState.lastName}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Last name is required!
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='py-2'>
                  <Form.Label htmlFor='email'>Email</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Your email address'
                    name='email'
                    onChange={handleChange}
                    value={formState.email}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Email is required!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor='password'>Password</Form.Label>
                  <div className='pass-wrapper'>
                    <Form.Control
                      type={passwordShown ? 'text' : 'password'}
                      placeholder='Your password'
                      name='password'
                      onChange={handleChange}
                      value={formState.password}
                      minLength='10'
                      required
                    />
                    <i
                      style={{ cursor: 'pointer' }}
                      className={passwordShown ? 'px-2 my-auto fa fa-eye' : 'my-auto px-2 fa fa-eye-slash'}
                      onClick={togglePasswordVisibility}
                    ></i>
                  </div>
                  <Form.Control.Feedback type='invalid'>
                    Password of 10 characters is required!
                  </Form.Control.Feedback>
                </Form.Group>

                <Row>
                  <Form.Group as={Col} htmlFor='uniqueid'>
                    <Form.Label>Unique ID</Form.Label>
                    <Form.Control
                      type='uniqueid'
                      as='input'
                      placeholder='ID#'
                      name='uniqueid'
                      onChange={handleChange}
                      value={formState.uniqueid}
                      required
                    />
                    <Form.Text className='text-muted'>
                      Authenticate course credentials.
                    </Form.Text>
                    <Form.Control.Feedback type='invalid'>
                      This number is located in your Bootcamp login
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <div className='py-2'></div>
                <Button className='btn d-block w-100' type='submit' variant='success'>
                  Sign Up
                </Button>

                {error && (
                  <div>
                    Sign Up failed<span></span>
                  </div>
                )}
              </Form>
            </>
          )}
        </main>
        <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>🔒 Do we take security seriously?</Accordion.Header>
            <Accordion.Body>
              We use a 2048-bit SSL Certificate when you send your data and salted password-hashing up to 72 bytes when storing your password.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </div>
  );
};

export default Signup;
