import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
// import { Form as MyInput } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import AutocompleteForm from '../AutocompleteForm';
//GraphQL
import { useMutation } from '@apollo/client';
import { UPDATE_USERCOMPANY } from '../../utils/mutations';

const ButtonStyle = {
  fontSize: "0.7rem"
};

const ExpandedComponentContact = (props) => {
  const { data, usercontacts, contactsdataoptions, userRefetch } = props;

  console.log({ data }, { usercontacts }, { contactsdataoptions }, { userRefetch });

  //selection is the selected label in the array
  //suggestions is a an array of all choice options in an object array of label: and value:
  //labelArray is extracting only the labels from this above object array
  //inputIndexSugg is the index number of the selected label in the choice options array
  //this function returns the value: from the choice options in an object array when the label is provided
  const getInput = (selection, suggestions) => {
    let labelArray = [];
    suggestions.map((suggestion) => labelArray.push(suggestion.label));
    const inputIndexSugg = labelArray.indexOf(selection);
    return suggestions[Math.abs(inputIndexSugg)].value;
  };

  //set initial value
  const [autobuttonSubmit, setAutoButtonSubmit] = useState(false);

  //define mutation
  const [updateusercompany, { error: updateusercompanyerror }] = useMutation(UPDATE_USERCOMPANY, {
    onCompleted: async data => userRefetch()
  });

  const handleNewTextusercontacts = (textValue, k, existingStored) => {
    let newArr = [...existingStored];
    let contactID = getInput(textValue, contactsdataoptions);
    newArr[k] = contactID;
    return newArr;
  };

  const handleDeleteUsercontacts = (k, existingStored) => {
    if (k >= 0 && k < existingStored.length) {
      return existingStored.filter((_, index) => index !== k);
    } else {
      return existingStored;
    }
  };

  const { handleSubmit, control, setValue, getValues, formState: { errors, isSubmitted } } = useForm({
    defaultValues: {
      usercontacts: usercontacts
    }
  });

  const onSubmit = async (values) => {
    let storedusercontacts = usercontacts;

    values.usercontacts.forEach((value, index) => {
      if (getValues()[`usercontacts[${index}]`] !== usercontacts[index]) {
        storedusercontacts = handleNewTextusercontacts(value, index, storedusercontacts);
      }
    });

    values.usercontacts.forEach((value, index) => {
      if (value === "Delete Contact") {
        storedusercontacts = handleDeleteUsercontacts(index, storedusercontacts);
      }
    });

    try {
      await updateusercompany({
        variables: { usercompanyId: data._id, usercontacts: storedusercontacts }
      });
    } catch (e) {
      console.error(e);
    }

    setAutoButtonSubmit(true);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {data.usercontacts && data.usercontacts.map((contacts, kValue) => (
          <Col key={kValue}>
            <div>
              <label>
                Contact:{contacts}
                <br />
                <Controller
                  name={`usercontacts[${kValue}]`}
                  control={control}
                  render={({ field }) => (
                    <AutocompleteForm
                      {...field}
                      suggestions={contactsdataoptions}
                    />
                  )}
                />
              </label>
            </div>
          </Col>
        ))}
        <div>
          <label>
            Contact: Add Contact
            <br />
            <Controller
              name={`usercontacts[${data.usercontacts.length}]`}
              control={control}
              render={({ field }) => (
                <AutocompleteForm
                  {...field}
                  suggestions={contactsdataoptions}
                />
              )}
            />
          </label>
        </div>
        <div>
          <Button
            className='btn d-block w-75 mx-auto'
            style={ButtonStyle}
            type='submit'
            variant='success'
            disabled={autobuttonSubmit && !isSubmitted}
          >
            {autobuttonSubmit ? 'Selected!! ✈ ' : 'Click to confirm contact edits'}
          </Button>
          <br />
        </div>
      </form>
    </div>
  );
};

export default ExpandedComponentContact;
