import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { ADD_USER_LETTER } from '../utils/mutations';
// import { QUERY_ME } from '../utils/queries';

const LetterForm = ({userRefetch}) => {

  const [letterName, setLetterName] = useState('');
  const [characterCount, setCharacterCount] = useState(0);
  // error is initially undefined but can change if mutation fails
  const [adduserletter, { error }] = useMutation(ADD_USER_LETTER, {
      onCompleted: async data => userRefetch()
    }
  );

  const handleChange = event => {
    if (event.target.value.length <= 280) {
      setLetterName(event.target.value);
      setCharacterCount(event.target.value.length);
    }
  };

  const handleFormSubmit = async event => {
    event.preventDefault();

    try {
      // add  to database
      await adduserletter({
        variables: { letterName: letterName}
      });

      // clear form value
      setLetterName('');
      setCharacterCount(0);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <p className={`m-0 ${characterCount === 1000 ? 'text-error' : ''}`}>
        Character Count: {characterCount}/200
        {error && <span className="ml-2">Something went wrong...</span>}
      </p>
      <Form>
        <Form.Group 
            className="mb-3" 
            controlId="exampleForm.ControlTextarea1"
            >
            <Form.Control 
                      as="textarea" rows={1} 
                      placeholder="Enter new letter name..."
                      value={letterName}
                      className="form-input col-12 col-md-9"
                      onChange={handleChange}
            />
                <Button 
                  className='btn d-block w-20' 
                  type="submit" 
                  variant='custom'
                  style={{ margin: 5 }}
                  onClick={handleFormSubmit}
                  >
                   Click to add letter
                </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default LetterForm;