import React, { useState, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";

const SelectboxStyle = {
  borderColor: "transparent",
  color: "transparent",
};

const AutocompleteForm = ({ suggestions }) => {
  const { control, setValue, watch, formState: { errors, touchedFields } } = useForm();
  const value = watch("autocomplete") || "";

  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const onChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "=") {
      // show all suggestions
      setFilteredSuggestions(suggestions);
    } else {
      // Filter out suggestions that don't contain the user's input
      const filterSugg = suggestions.filter(
        (suggestion) =>
          suggestion.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
      setFilteredSuggestions(filterSugg);
    }
    setActiveSuggestion(0);
    setShowSuggestions(true);
    setValue("autocomplete", inputValue);
  };

  const onClick = (e) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setValue("autocomplete", e.target.innerText);
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setValue("autocomplete", filteredSuggestions[activeSuggestion].label);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  let suggestionsListComponent;

  if (showSuggestions && value) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={index} onClick={onClick}>
                {suggestion.label}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>No suggestions, you're on your own!</em>
        </div>
      );
    }
  }

  return (
    <>
      <Fragment>
        <Controller
          name="autocomplete"
          control={control}
          render={({ field }) => (
            <input
              type="text"
              {...field}
              onChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
              onKeyDown={onKeyDown}
              value={value}
            />
          )}
        />
        <select style={SelectboxStyle} value={value} onChange={onChange}></select>
        {suggestionsListComponent}
      </Fragment>
      {touchedFields.autocomplete && errors.autocomplete && <em>{errors.autocomplete.message}</em>}
    </>
  );
};

export default AutocompleteForm;
