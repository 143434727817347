import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const DiscoveryPlaybookPage = () => {
  return (
    <Container>
      <Row style={{ paddingBottom: '20px' }}>
        <Col md={4} className='text-left'>
          <h4>Login or Signup with Google</h4>
          <p>Login or Signup with Google to integrate your Google Calendar and book meetings directly within the Peppercorn Plan dashboard. We prioritize your privacy and data security, ensuring all data processing complies with our privacy policy.</p>
        </Col>
        <Col md={4} className='text-left'>
          <h4>Integrate your Google Calendar</h4>
          <p>The My Calendar feature integrates with your Google Calendar, automatically syncing any job search activities and events you schedule. Get notifications and reminders for interviews, applications, and more.</p>
        </Col>
        <Col md={4} className='text-left'>
          <h4>Store Contacts & Companies</h4>
          <p>Fetch your contacts and companies from your Google account and store them in your Peppercorn Plan dashboard.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default DiscoveryPlaybookPage;
