import React, { useState } from 'react';
import { Button, Form, Modal, Tab, Row } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
// import DatePicker from "react-datepicker";
// import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { ADD_USERCONTACT } from '../../utils/mutations';
// import { QUERY_ME } from '../../utils/queries';

//var array = string.split(',');
// const options = [
//   { value: 1, label: '1' },
//   { value: 2, label: '2' },
//   { value: 3, label: '3' },
// ];

const ContactFormMoreInputs = (props) => {
  const { userRefetch } = props

  let defaultContact =
  {
    firstName:  "a",
    lastName: "default",
    profilePic:  "default",
    gender: "default",
    title: "default",
    role:  "default",
    seniority:  "default",
    location: "default",
    city:  "default",
    state:  "default",
    country:  "default",
    linkedIn:  "default",
    twitter:  "default",
    crunchbase:  "default",
    facebook:  "default",
    github: "default",
    bio:  "default",
    about:  "default"
  }
  const [modalContactCreate, setModalContactCreate] = useState({ show: false});
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [profilePic, setprofilePic] = useState('');
  const [gender, setgender] = useState('');
  const [title, settitle] = useState('');
  const [role, setrole] = useState('');
  const [seniority, setseniority] = useState('');
  const [location, setlocation] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [country, setcountry] = useState('');
  const [linkedIn, setlinkedIn] = useState('');
  const [twitter, settwitter] = useState('');
  const [crunchbase, setcrunchbase] = useState('');
  const [facebook, setfacebook] = useState('');
  const [github, setgithub] = useState('');
  const [bio, setbio] = useState('');
  const [about, setabout] = useState('');

  const [defaultValue, setDefaultValue] = useState(defaultContact);
  const [characterCountfirstName, setCharacterCountfirstName] = useState(0);
  const [characterCountlastName, setCharacterCountlastName] = useState(0);
  const [characterCountprofilePic, setCharacterCountprofilePic] = useState(0);
  const [characterCountgender, setCharacterCountgender] = useState(0);
  const [characterCounttitle, setCharacterCounttitle] = useState(0);
  const [characterCountrole, setCharacterCountrole] = useState(0);
  const [characterCountseniority, setCharacterCountseniority] = useState(0);
  const [characterCountlocation, setCharacterCountlocation] = useState(0);
  const [characterCountcity, setCharacterCountcity] = useState(0);
  const [characterCountstate, setCharacterCountstate] = useState(0);
  const [characterCountcountry, setCharacterCountcountry] = useState(0);
  const [characterCountlinkedIn, setCharacterCountlinkedIn] = useState(0);
  const [characterCounttwitter, setCharacterCounttwitter] = useState(0);
  const [characterCountcrunchbase, setCharacterCountcrunchbase] = useState(0);
  const [characterCountfacebook, setCharacterCountfacebook] = useState(0);
  const [characterCountgithub, setCharacterCountgithub] = useState(0);
  const [characterCountbio, setCharacterCountbio] = useState(0);
  const [characterCountabout, setCharacterCountabout] = useState(0);

  // error is initially undefined but can change if mutation fails
  const [addusercontact, { error }] = useMutation(ADD_USERCONTACT, {
      onCompleted: async data => userRefetch()
    });

  const handlefirstNameChange = event => {
    if (event.target.value.length <= 280) {
      setfirstName(event.target.value);
      setCharacterCountfirstName(event.target.value.length);
    }
  };
  const handlelastNameChange = event => {
    if (event.target.value.length <= 280) {
      setlastName(event.target.value);
      setCharacterCountlastName(event.target.value.length);
    }
  };
  const handleprofilePicChange = event => {
    if (event.target.value.length <= 280) {
      setprofilePic(event.target.value);
      setCharacterCountprofilePic(event.target.value.length);
    }
  };
  const handlegenderChange = event => {
    if (event.target.value.length <= 280) {
      setgender(event.target.value);
      setCharacterCountgender(event.target.value.length);
    }
  };
  const handletitleChange = event => {
    if (event.target.value.length <= 280) {
      settitle(event.target.value);
      setCharacterCounttitle(event.target.value.length);
    }
  };
  const handleroleChange = event => {
    if (event.target.value.length <= 280) {
      setrole(event.target.value);
      setCharacterCountrole(event.target.value.length);
    }
  };
  const handleseniorityChange = event => {
    if (event.target.value.length <= 280) {
      setseniority(event.target.value);
      setCharacterCountseniority(event.target.value.length);
    }
  };
  const handlelocationChange = event => {
    if (event.target.value.length <= 280) {
      setlocation(event.target.value);
      setCharacterCountlocation(event.target.value.length);
    }
  };
  const handlecityChange = event => {
    if (event.target.value.length <= 280) {
      setcity(event.target.value);
      setCharacterCountcity(event.target.value.length);
    }
  };
  const handlestateChange = event => {
    if (event.target.value.length <= 280) {
      setstate(event.target.value);
      setCharacterCountstate(event.target.value.length);
    }
  };
  const handlecountryChange = event => {
    if (event.target.value.length <= 280) {
      setcountry(event.target.value);
      setCharacterCountcountry(event.target.value.length);
    }
  };
  const handlelinkedInChange = event => {
    if (event.target.value.length <= 280) {
      setlinkedIn(event.target.value);
      setCharacterCountlinkedIn(event.target.value.length);
    }
  };
  const handletwitterChange = event => {
    if (event.target.value.length <= 280) {
      settwitter(event.target.value);
      setCharacterCounttwitter(event.target.value.length);
    }
  };
  const handlecrunchbaseChange = event => {
    if (event.target.value.length <= 280) {
      setcrunchbase(event.target.value);
      setCharacterCountcrunchbase(event.target.value.length);
    }
  };
  const handlefacebookChange = event => {
    if (event.target.value.length <= 280) {
      setfacebook(event.target.value);
      setCharacterCountfacebook(event.target.value.length);
    }
  };
  const handlegithubChange = event => {
    if (event.target.value.length <= 280) {
      setgithub(event.target.value);
      setCharacterCountgithub(event.target.value.length);
    }
  };
  const handlebioChange = event => {
    if (event.target.value.length <= 280) {
      setbio(event.target.value);
      setCharacterCountbio(event.target.value.length);
    }
  };
  const handleaboutChange = event => {
    if (event.target.value.length <= 280) {
      setabout(event.target.value);
      setCharacterCountabout(event.target.value.length);
    }
  };

//Handle Escape
  const handleEscape = () => {
	  // clear value
	   setModalContactCreate({ show: false})
  }

//Handle Modal 
  const handleModal = () => {
  // clear value
   setModalContactCreate({ show: true})
  }

  const handleFormSubmit = async event => {
    event.preventDefault();
    try {
      // add thought to database
      await addusercontact({
        variables: { 
          firstName: firstName || "a",
          lastName: lastName || "default",
          profilePic: profilePic ||  "default",
          gender: gender || "default",
          title: title || "default",
          role:  role || "default",
          seniority: seniority || "default",
          location: location || "default",
          city: city ||  "default",
          state:  state || "default",
          country:  country || "default",
          linkedIn: linkedIn ||  "default",
          twitter: twitter ||  "default",
          crunchbase: crunchbase || "default",
          facebook: facebook || "default",
          github: github || "default",
          bio:  bio || "default",
          about: about || "default"
          // usercontacts: defaultValue.usercontacts, 
        }
      });

      // clear form value
      setCharacterCountfirstName(0);
      setCharacterCountlastName(0);
      setCharacterCountprofilePic(0);
      setCharacterCountgender(0);
      setCharacterCounttitle(0);
      setCharacterCountrole(0);
      setCharacterCountseniority(0);
      setCharacterCountlocation(0);
      setCharacterCountcity(0);
      setCharacterCountstate(0);
      setCharacterCountcountry(0);
      setCharacterCountlinkedIn(0);
      setCharacterCounttwitter(0);
      setCharacterCountcrunchbase(0);
      setCharacterCountfacebook(0);
      setCharacterCountgithub(0);
      setCharacterCountbio(0);
      setCharacterCountabout(0);
      setModalContactCreate({ show: false})
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
    <Button 
                className='btn d-block w-20' 
                type="submit" 
                variant='custom'
                style={{ margin: 5 }}
                onClick={handleModal}
                >
                 Click to create a new contact
    </Button>
    <Modal
     {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      className="text-left"
      show={modalContactCreate.show}
      style={{overflowY: "scroll"}}
    >
    <Modal.Body>
    <h3 className="py-4"> Create a new contact </h3>
     <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
      <Row>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={firstName}
              className="form-input col-12 col-md-9"
              onChange={handlefirstNameChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountfirstName === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountfirstName}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={lastName}
              className="form-input col-12 col-md-9"
              onChange={handlelastNameChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountlastName === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountlastName}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Profile</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={profilePic}
              className="form-input col-12 col-md-9"
              onChange={handleprofilePicChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountprofilePic === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountprofilePic}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Gender</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={gender}
              className="form-input col-12 col-md-9"
              onChange={handlegenderChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountgender === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountgender}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={title}
              className="form-input col-12 col-md-9"
              onChange={handletitleChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCounttitle === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCounttitle}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Role</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={role}
              className="form-input col-12 col-md-9"
              onChange={handleroleChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountrole === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountrole}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Seniority</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={seniority}
              className="form-input col-12 col-md-9"
              onChange={handleseniorityChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountseniority === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountseniority}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Location</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={location}
              className="form-input col-12 col-md-9"
              onChange={handlelocationChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountlocation === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountlocation}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>City</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={city}
              className="form-input col-12 col-md-9"
              onChange={handlecityChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountcity === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountcity}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>State</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={state}
              className="form-input col-12 col-md-9"
              onChange={handlestateChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountstate === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountstate}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Country</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={country}
              className="form-input col-12 col-md-9"
              onChange={handlecountryChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountcountry === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountcountry}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>LinkedIn</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={linkedIn}
              className="form-input col-12 col-md-9"
              onChange={handlelinkedInChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountlinkedIn === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountlinkedIn}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Twitter</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={twitter}
              className="form-input col-12 col-md-9"
              onChange={handletwitterChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCounttwitter === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCounttwitter}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Crunchbase</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={crunchbase}
              className="form-input col-12 col-md-9"
              onChange={handlecrunchbaseChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountcrunchbase === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountcrunchbase}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Facebook</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={facebook}
              className="form-input col-12 col-md-9"
              onChange={handlefacebookChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountfacebook === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountfacebook}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Github</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={github}
              className="form-input col-12 col-md-9"
              onChange={handlegithubChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountgithub === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountgithub}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Bio</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={bio}
              className="form-input col-12 col-md-9"
              onChange={handlebioChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountbio === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountbio}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>About</Form.Label>
            <Form.Control 
              as="textarea" rows={1} 
              placeholder="Enter ..."
              value={about}
              className="form-input col-12 col-md-9"
              onChange={handleaboutChange}
            />
            <Form.Text className="text-muted">
                <p className={`m-0 ${characterCountabout === 200 ? 'text-error' : ''}`}>
                  Character Count: {characterCountabout}/200
                  {error && <span className="ml-2">Something went wrong...</span>}
                </p>
            </Form.Text>
        </Form.Group>

        
    

        <Button 
                className='btn d-block w-20' 
                type="submit" 
                variant='custom'
                style={{ margin: 5 }}
                onClick={handleFormSubmit}
                >
                 Click to add this contact
        </Button>
        </Form>
        <div className="py-4 d-flex justify-content-center">
          <button className="btn-tutorial " onClick={() => handleEscape()} > 
            Escape without adding contact 
          </button>
        </div>
      </Row>
     </Tab.Container>
  </Modal.Body>
  </Modal>
  </div>
  );
};

export default ContactFormMoreInputs;


