import React, { useEffect}from 'react';
import { Link } from "react-router-dom";
//Client Data
import moduleData from '../data/moduleData'
//Components
import BoardsRender from '../components/BoardsRender';
import BoardForm from '../components/BoardComponent/BoardForm';
// import SideBarParagraph from '../components/Sidebar/SidebarParagraph';
//GraphQL
import { useQuery } from '@apollo/client';
import { QUERY_ME} from '../utils/queries';
//Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import { USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
import { useAuthContext } from '../utils/AuthContext'
import { Spinner } from 'react-bootstrap';

const Boards = () => {
  // Boards: activemodule is hardcoded in JSX below as moduleData[2]; //hardcoded current module instead of useParams
  const { isAuthenticated } = useAuthContext()
  const { toggleAuth } = useAuthContext();
  //console.log(isAuthenticated)
  const loggedIn = isAuthenticated;
  const [state, dispatch] = usePepperContext();
  const { loading: userLoading, data: userData, refetch: userRefetch } = useQuery(QUERY_ME);
  const moduleLoading = false;



  useEffect(() => {
    toggleAuth()
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      })
      idbPromise('userdata', 'put', userData.me)
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        })
      })
    }

  }, [userData, userLoading, dispatch, toggleAuth])

  return (
    <div> 
      {loggedIn ? (
        <>
          <div className="container">
            <main>
              <BoardForm userRefetch={userRefetch}/>
              <div>
                {moduleLoading ? 
                 <div className='text-center'>
    <Spinner />
  </div> : 
                 <BoardsRender user={state.userdata} activemodule={moduleData[2]} userRefetch={userRefetch}/>
                 }
              </div>
            </main>
              <hr />
          </div>
        </> 
          ) :
          (
        <>
        {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access the content of this page.</h4> 
        </>
          )
      }
    </div>
  );
};

export default Boards;
