import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Modal, Form, FormGroup, FormControl, FormLabel, FormCheck, Alert, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import styles from './BoardComponent.module.scss';
import { GET_USERJOB } from '../../utils/queries';
import { UPDATE_USERJOB, ADD_USER_ACTIVITY } from '../../utils/mutations';
import { useParams } from 'react-router-dom';

const SidePanel = ({ job, onClose, show }) => {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [editableField, setEditableField] = useState(null);
  const [editableValue, setEditableValue] = useState('');
  const [eventData, setEventData] = useState({
    summary: '',
    description: '',
    start: '',
    end: '',
    attendees: '',
    includeGoogleMeet: false
  });
  const [error, setError] = useState('');

  const { data: userJobData, loading, error: queryError } = useQuery(GET_USERJOB, {
    variables: { userjobId: job?.id },
    skip: !job, // Skip query if job is not provided
  });

  const [updateUserJob] = useMutation(UPDATE_USERJOB);
  const [addActivity] = useMutation(ADD_USER_ACTIVITY);

  useEffect(() => {
    if (userJobData?.userjob) {
      setEventData((prevData) => ({
        ...prevData,
        description: userJobData.userjob.description || ''
      }));
    }
  }, [userJobData]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
  };

  const handleEditField = (field, value) => {
    setEditableField(field);
    setEditableValue(value);
  };

  const handleChange = (event) => {
    setEditableValue(event.target.value);
  };

  const handleBlurOrEnter = async () => {
    const updatedUserJob = {
      userjobId: job.id,
      [editableField]: editableValue,
    };

    try {
      await updateUserJob({ variables: updatedUserJob });
    } catch (error) {
      console.error('Error updating user job:', error);
    } finally {
      setEditableField(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleBlurOrEnter();
    }
  };

  const handleEventDataChange = (field, value) => {
    setEventData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleAddCalendarEvent = async () => {
    if (!eventData.summary || !eventData.description || !eventData.start || !eventData.end) {
      setError('Please fill out all required fields.');
      return;
    }

    const getSignInCookie = Cookies.get('googleToken');
    const event = {
      summary: eventData.summary,
      description: eventData.description,
      start: {
        dateTime: new Date(eventData.start).toISOString(),
        timeZone: 'America/Los_Angeles'
      },
      end: {
        dateTime: new Date(eventData.end).toISOString(),
        timeZone: 'America/Los_Angeles'
      },
      attendees: eventData.attendees ? eventData.attendees.split(',').map(email => ({ email })) : [],
      conferenceData: eventData.includeGoogleMeet ? {
        createRequest: {
          requestId: "7qxalsvy0e",
          conferenceSolutionKey: {
            type: "hangoutsMeet"
          }
        }
      } : undefined
    };

    try {
      console.log(event)
      const res = await fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getSignInCookie}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event)
      });

      if (res.ok) {
        const eventDataFromGoogle = await res.json();
        await addActivity({
          variables: {
            boardId: params.boardId,
            activityTitle: eventDataFromGoogle.summary,
            activitynote: eventDataFromGoogle.description,
            activityCategory: 1,
            activityCompleted: false,
            startAt: eventDataFromGoogle.start.dateTime,
            endAt: eventDataFromGoogle.end.dateTime,
            companyId: job.companyId,
            usercompany: job.usercompanyId,
            cards: job.id
          }
        });
        handleCloseModal();
      } else {
        const errorData = await res.json();
        console.error('Error adding calendar event:', errorData);
        setError('Error adding calendar event. Please try again.');
      }
    } catch (error) {
      console.error('Error adding calendar event:', error);
      setError('Error adding calendar event. Please try again.');
    }
  };

  if (!job) return null;

  if (loading) return <FontAwesomeIcon icon={faSpinner} spin className="ms-2 my-auto" />;

  if (queryError) return <p>Error: {queryError.message}</p>;

  const userJobDetails = userJobData?.userjob;

  return (<>
    <Offcanvas show={show} onHide={onClose} scroll backdrop={false} placement='end'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title as='h4'>Job Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {userJobDetails && (
          <>
            {['title', 'description', 'jobLocation', 'jobSalary', 'jobUrl', 'jobUrn', 'deadline', 'color', 'jobnote'].map((field) => (
              <div key={field} className={styles.inputFeed}>
                <div className={styles.wrapInputControl}>
                  <label className={styles.inputFieldContainer}>
                    {field.charAt(0).toUpperCase() + field.slice(1)}:
                    {editableField === field ? (
                      <input
                        className={styles.stylishInput}
                        placeholder={`Enter ${field}`}
                        type="text"
                        name={field}
                        value={editableValue}
                        onChange={handleChange}
                        onBlur={handleBlurOrEnter}
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    ) : (
                      <>
                        <span>{userJobDetails[field]}</span>
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          className={styles.editIcon}
                          onClick={() => handleEditField(field, userJobDetails[field])}
                        />
                      </>
                    )}
                  </label>
                </div>
              </div>
            ))}
            <Button variant="secondary" onClick={onClose} className="mt-3 mx-5">Close</Button>
          </>
        )}
            <Button onClick={handleOpenModal} style={{backgroundColor:'#303551', border:'none'}} className="mt-3">Book Activity</Button>
      </Offcanvas.Body>
    </Offcanvas>

    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Book an Activity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <FormGroup controlId="activitySummary">
            <FormLabel>Activity Summary:</FormLabel>
            <FormControl
              className={styles.stylishInput}
              type="text"
              value={eventData.summary}
              onChange={(e) => handleEventDataChange('summary', e.target.value)}
              placeholder="Enter activity summary"
              required
            />
          </FormGroup>
          <FormGroup controlId="activityDescription">
            <FormLabel>Activity Description:</FormLabel>
            <FormControl
              className={styles.stylishInput}
              as="textarea"
              value={eventData.description}
              onChange={(e) => handleEventDataChange('description', e.target.value)}
              placeholder={`Enter activity description${userJobDetails ? ` (e.g., ${userJobDetails.description})` : ''}`}
              required
            />
          </FormGroup>
          <FormGroup controlId="activityStart">
            <FormLabel>Start Date and Time:</FormLabel>
            <FormControl
              className={styles.stylishInput}
              type="datetime-local"
              value={eventData.start}
              onChange={(e) => handleEventDataChange('start', e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup controlId="activityEnd">
            <FormLabel>End Date and Time:</FormLabel>
            <FormControl
              className={styles.stylishInput}
              type="datetime-local"
              value={eventData.end}
              onChange={(e) => handleEventDataChange('end', e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup controlId="activityAttendees">
            <FormLabel>Participant Emails (comma-separated):</FormLabel>
            <FormControl
              className={styles.stylishInput}
              type="text"
              value={eventData.attendees}
              onChange={(e) => handleEventDataChange('attendees', e.target.value)}
              placeholder="example@example.com, another@example.com"
            />
          </FormGroup>
          <FormGroup controlId="googleMeetCheckbox">
            <FormCheck
              type="checkbox"
              style={{type:'checkbox'}}
              label="Add Google Meet link to the event"
              checked={eventData.includeGoogleMeet}
              onChange={(e) => handleEventDataChange('includeGoogleMeet', e.target.checked)}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        <Button variant="primary" style={{backgroundColor:'#303551', border:'none'}} onClick={handleAddCalendarEvent}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  </>);
};

export default SidePanel;
