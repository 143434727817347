import React from 'react';
import { Button, InputGroup, FormControl, Card } from 'react-bootstrap';



  let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	fontWeight: "100",
	filter: "grayscale(100%)"
  };
  
  const ExpandedComponent = ({data}) => {

    return (
    <div>
   		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <Card style={(expandedCardClass)}>
		<Card.Header className="text-muted">
		  <br/>
			<div className="divider"/>
			  	Gender:  {data.gender}
			<div className="divider"/>
			<div className="divider"/>
				Title:  {data.title}
			<div className="divider"/>
			<div className="divider"/>
				Role:  {data.role}
			<div className="divider"/>
				Location:  {data.location}
		  <br/>
		  <br/>
			<div className="divider"/>
			  	Seniority:  {data.seniority}
			<div className="divider"/>
			<div className="divider"/>
				City: {data.city}
			<div className="divider"/>
			<div className="divider"/>
				State: {data.state}
			<div className="divider"/>
				Country: {data.country}
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
		  	Name:  {data.firstName}{" "}{data.lastName}
		  </Card.Text>
		  <Card.Text>
			Bio:  {data.bio}
		  </Card.Text>
		  <Card.Text>
			About:  {data.about}
		   </Card.Text>
		</Card.Body>
		<Card.Footer className="text-muted">
			<br/>
				<div className="divider"/>
				<i className="fab fa-linkedin-in"/>
				<div className="divider"/>
					{data.linkedIn}
				<div className="divider"/>
				<div className="divider"/>
				<i className="fab fa-twitter"/>
				<div className="divider"/>
					{data.twitter}
			<br/>
				<div className="divider"/>
				<i class="fab fa-facebook"/>
				<div className="divider"/>
					{data.facebook}
				<div className="divider"/>
				<div className="divider"/>
				<i className="fab fa-github"/>
				<div className="divider"/>
					{data.github}
				<div className="divider"/>
				    Crunchbase: 
				<div className="divider"/>
					{data.crunchbase}
			<br/>

	  </Card.Footer>
    </Card>
  </div>
    );
};

export default  ExpandedComponent;