import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  Image,
  Nav,
  Spinner,
} from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { GET_CONTACT, GET_USERCONTACT } from '../utils/queries'
import { useForm } from 'react-hook-form'
import {
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './modal.scss'

const ModalContact = ({ location }) => {
  const { register, handleSubmit } = useForm()
  const { state = {} } = location
  const { contactId } = useParams()
  const [toggleContact, setToggleContact] = useState(true)
  const [formState, setFormState] = useState({})

  // If a User contact, query contact collection
  const { loading: contactLoading, data: contactData } = useQuery(GET_CONTACT, {
    skip: location.state.user,
    variables: { contactId: contactId },
  })
  // If a User contact, query usercontact collection
  const { data } = useQuery(GET_USERCONTACT, {
    skip: !location.state.user,
    variables: { contactId: contactId },
  })

  const { modal } = state

  useEffect(() => {
    if (data && location.state.user) {
      setFormState(data.mycontact)
    } else if (contactData && !location.state.user) {
      setFormState(contactData.contact)
    }
  }, [contactData, data, location.state.user])

  console.log(location.state.user, data, contactData, formState)
  const onSubmit = (myData) => {
    alert(JSON.stringify(myData))
  }

  const contactKeys = Object.keys(formState).slice(0, -2)
  const companyKeys = Object.keys(formState).slice(-2)

  if (contactLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={modal ? 'modal overflow-auto' : undefined}>
      {formState !== null ? (
        <div className='modal-container'>
          <div>
            <Row>
              <Col className='px-0'>
                <Card>
                  <Card.Header className='modal-card-header d-flex justify-content-evenly align-items-center'>
                    <div className=''>
                      <Image
                        className='contact-img'
                        src={formState.profilePic}
                      />
                    </div>
                    <div className='justify-content-around'>
                      <h3>
                        <FontAwesomeIcon
                          className='mx-3'
                          // icon={
                          //   formState.gender === 'female' ? faFemale : faMale
                          // }
                        />
                        {formState.firstName + ' ' + formState.lastName}
                      </h3>
                      <h3>
                        <FontAwesomeIcon
                          className='mx-3'
                          icon={faMapMarkerAlt}
                        />
                        {formState.city},{formState.country}
                      </h3>
                    </div>
                  </Card.Header>
                </Card>
                <Nav justify variant='tabs' defaultActiveKey='1'>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setToggleContact(true)}
                      eventKey='1'
                    >
                      Contact
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setToggleContact(false)}
                      eventKey='2'
                    >
                      Company
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      onClick={() => setToggleContact(false)}
                      eventKey='3'
                    >
                      Directory
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <form
                  className='d-flex justify-content-sm-center justify-content-md-evenly justify-content-xl-evenly justify-content-lg-around flex-wrap mx-4 text-center'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {toggleContact ? (
                    <>
                      {contactKeys.map((value, index) => (
                        <>
                          {index > 1 ? (
                            <>
                              <div className='form-group' key={index}>
                                <label className='label-modal' htmlFor={value}>
                                  {value}
                                </label>
                                {value === 'bio' || value === 'about' ? (
                                  <textarea
                                    rows='4'
                                    className='textarea-modal form-control mx-1'
                                    defaultValue={formState[value]}
                                    placeholder='placeholder'
                                  ></textarea>
                                ) : (
                                  <input
                                    type='text'
                                    className='input-modal form-control mx-1'
                                    defaultValue={formState[value]}
                                    placeholder='placeholder'
                                    {...register(`${value}`)}
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                      <hr />
                      <div className='text-center'>
                        <button className='input-save my-2 mx-2'>Save</button>
                        <button className='input-save my-2 mx-2'>Cancel</button>
                      </div>
                    </>
                  ) : (
                    <>
                      {companyKeys.map((value, index) => (
                        <>
                          <label className='label-modal' htmlFor={value}>
                            {value}
                          </label>
                          {formState[value].map((e, key) => (
                            <div className='mx-2 my-2' key={key}>
                              Company Name: {e.companyName} <br />
                              URL: {e.companyUrl}
                              <br />
                              Description: {e.companyDescription}
                              <br />
                              Company Size: {e.companySize}
                              <br />
                              Founded Year: {e.companyFoundedYear}
                              <br />
                            </div>
                          ))}
                        </>
                      ))}
                      <hr />
                      <div className='text-center'>
                        <button className='input-save my-2 mx-2'>Save</button>
                        <button className='input-save my-2 mx-2'>Cancel</button>
                      </div>
                    </>
                  )}
                </form>
                <div></div>
              </Col>
            </Row>
            <div className='py-2 text-center'>
              {modal && (
                <Link to='/contacts'>
                  <button className='btn-tutorial'>Back to Contacts</button>
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='container text-center'>
    <Spinner />
  </div>
      )}
    </div>
  )
}

export default ModalContact
