/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Image } from 'react-bootstrap'
import './profile.scss'

const Upload = (props) => {
  const { name, picture } = props

  const profilePicture =
    picture ||
    'https://peppercornsearch.s3.us-east-2.amazonaws.com/profile/woman1.jpg'

  return (
    <>
      <div className='profile-image text-center' style={{ zIndex: '-1' }}></div>
      <Image
        style={{
          marginTop: '-75px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
        height={150}
        width={150}
        src={profilePicture}
        roundedCircle
      />
      <h4 className='pt-4'>{name}</h4>
    </>
  )
}

export default Upload
