import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Form, Button, Alert, Col } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../utils/mutations';

const ForgotPassword = () => {
    const [forgotPassword, {error} ] = useMutation(FORGOT_PASSWORD);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorName, setErrorName] = useState('');
    const [validated] = useState(false);
    const [formState, setFormState] = useState({ email: ''});
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            [name]: value
        });
    };

    const handleFormSubmit = async event => {
        event.preventDefault();

        // check if form has everything (as per react-bootstrap docs)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
            setSubmitting(true)
        
            //console.log(formState)
            try {
                await forgotPassword({
                    variables: {...formState}
                });
                //console.log(formState)
                setSubmitting(false)
                // Auth.(data.login.token);
            } catch (e) {
                setSubmitting(false)
                console.error(e);
                setShowAlert(true);
                setErrorName(`${e}`);
            }
          
            setFormState({
                email: '',
            });
        

    };
    // const handleRequestChange = (event) => {
    //     const { name, value } = event.target;
    //     setRequestState({
    //         ...requestState,
    //         [name]: value
    //     });
    // };

    return (
         <Col className="logincard" md={{ span: 6, offset: 3}}>
         <Link to={`/login`} className="btn btn-link">← Back to Login</Link>
         <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <h3 className="text-center">Forgot Password</h3>
                    <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                    {errorName.split(":")[2]}
                    </Alert>
                    <Form.Group>
                        <Form.Label>Type your email to get a new token to reset your password.</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Your email address'
                            name='email'
                            onChange={handleInputChange}
                            value={formState.email}
                            required
                        />
                            <Form.Text className="text-muted">
                             Please enter the email address you used when you signed-up in this form. A password reset link will be sent to the address you provide. 
                             <br/>
                             If the email address entered does not match the email used at sign-up, you won't be able to use the reset link to reset your password.
                             <br/>
                             If you are unable to reset your password, please email info@peppercon.ai and provide as many account details as possible, so we can help you.
                            </Form.Text>
                    <Form.Control.Feedback type='invalid'>Email is required!</Form.Control.Feedback>
                        {/* <ErrorMessage name="email" component="Form.Group" className="invalid-feedback" /> */}
                    </Form.Group>
                    {validated ? (
                        <>
                        <div className="form-row">
                            <div className="form-group col">
                                <h3 className="text-center">Check your email for a new authorization token</h3>
                            </div>
                        </div>
                        </>
                    ) : (
                        <>
                        <div className="form-row">
                            <div className="form-group col">
                                <Button type="submit" variant='success' className="btn d-block w-100" disabled={isSubmitting}>
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Submit
                                        </Button>
                                    </div>
                                </div>
                            {error && <div>Email lookup failed</div>}
                            <Accordion>
                            <Card>
                            <Card.Body className="text-center">
                                <Accordion.Toggle as={Button} variant="light" eventKey="0">
                                🔒 Do we take security seriously?
                                </Accordion.Toggle>
                            </Card.Body>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="text-center">
                                We use a 2048-bit SSL Certificate when you send your data and salted password-hashing up to 72 bytes for storing your password with us.
                                </Card.Body>
                            </Accordion.Collapse>
                            </Card>
                            </Accordion>
                            </>
                    )}
                </Form>
            </Col>
    )
}

export default ForgotPassword;