import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="footer text-white py-3">
            <Container>
                <Row className="text-center">
                    <Col>
                        <a href="#" className="footer-link">All Rights Reserved</a>
                    </Col>
                    <Col>
                        <a href="mailto:info@peppercorn.ai" className="footer-link">Peppercorn Plan</a>
                    </Col>
                    <Col>
                        <a href="/terms-of-use" className="footer-link">Terms of Use</a>
                    </Col>
                    <Col>
                        <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
