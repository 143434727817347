import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { Modal, Tab, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import CompanyFormMoreInputs from './CompanyFormMoreInputs';
import ExpandedComponent from './ExpandedComponent';
import ExpandedComponentEdit from './ExpandedComponentEdit';
import { useMutation } from '@apollo/client';
import { DELETE_USERCOMPANY } from '../../utils/mutations';
import { tableDataCreate, downloadCSV } from './CompanyData';

const CompanyTable = ({ tableDataItemA, contactsdataoptions, userData, userRefetch }) => {
  const data = tableDataItemA;
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalValueDelete, setModalValueDelete] = useState({ show: false, selectedRows: {} });
  const [modalValueEdit, setModalValueEdit] = useState({ show: false, selectedRows: {} });
  const [modalValueAlert, setModalValueAlert] = useState({ show: false, selectedRows: {} });
  const [deleteusercompany] = useMutation(DELETE_USERCOMPANY, {
    onCompleted: () => userRefetch()
  });

  const handleDelete = async (selecteddata) => {
    if (tableDataItemA.length > 1) {
      try {
        await deleteusercompany({ variables: { usercompanyId: selecteddata._id } });
        setModalValueDelete({ show: false, selectedRows: {} });
      } catch (e) {
        console.error(e);
      }
    } else {
      setModalValueAlert({ show: true, selectedRows: {} });
    }
  };

  const handleEscapeAlert = () => setModalValueAlert({ show: false, selectedRows: {} });
  const handleEscapeDelete = () => setModalValueDelete({ show: false, selectedRows: {} });
  const handleEscapeEdit = () => setModalValueEdit({ show: false, selectedRows: {} });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      setToggleCleared(!toggleCleared);
      setModalValueDelete({ show: true, selectedRows });
    };
    const handleEdit = () => {
      setToggleCleared(!toggleCleared);
      setModalValueEdit({ show: true, selectedRows });
    };
    return (
      <div>
        <Button onClick={handleEdit} style={{ backgroundColor: 'green' }}>Edit</Button>
        <div className='divider' />
        <Button onClick={handleDelete} style={{ backgroundColor: 'red' }}>Delete</Button>
      </div>
    );
  }, [selectedRows, toggleCleared]);

  const Export = ({ onExport }) => <Button variant='success' onClick={e => onExport(e.target.value)}>Export</Button>;
  const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(tableDataCreate(userData.me))} />, [userData]);

  const URLWrap = row => (
    <a href={row.companyUrl} style={{ wordBreak: "break-all" }}>
      {row.companyUrl}
    </a>
  );

  const columns = useMemo(() => [
	{
	  name: 'Name',
	  selector: row => `${row.companyName.slice(0, 10)}...`,
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Location',
	  selector: row => row.companyLocation,
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Founded Year',
	  selector: row => row.companyFoundedYear,
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Culture',
	  selector: row => row.companyCulture.toString(),
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Values',
	  selector: row => row.companyValues.toString(),
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Contacts',
	  selector: row => row.usercontacts.toString(),
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Size',
	  selector: row => row.companySize,
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Description',
	  selector: row => `${row.companyDescription?.slice(0, 10) ?? ''}...`,
	  sortable: true,
	  right: true,
	},
	{
	  name: 'URL',
	  selector: row => <URLWrap {...row} />,
	  sortable: true,
	  right: true,
	},
	{
	  name: 'Domain',
	  selector: row => row.companyDomain,
	  sortable: true,
	  right: true,
	}
  ], []);
  

  const FilterComponent = ({ filterText, onFilter, onClear }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      inputRef.current.focus();
    }, [filterText]);

    return (
      <InputGroup className="mb-3">
      <FormControl
        id="search"
        type="text"
        placeholder="Filter By Company"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        aria-describedby="basic-addon2"
        ref={inputRef}
        autoComplete="off"
      />
        <Button variant="outline-secondary" id="button-addon2" type="button" onClick={onClear}>
          Clear
        </Button>
      </InputGroup>
    );
  };

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = data.filter(
    item => item.companyName && item.companyName.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div>
      <CompanyFormMoreInputs
        contactsdataoptions={contactsdataoptions}
        userData={userData}
        userRefetch={userRefetch} />
      <div className='table-container'>
        <DataTable
          title="My Companies"
          data={filteredItems}
          columns={columns}
          actions={actionsMemo}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          pagination
          fixedHeader
          fixedHeaderScrollHeight={"500px"}
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          selectableRows
          contextActions={contextActions}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
        />
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        show={modalValueDelete.show}
        style={{ overflowY: "scroll" }}
      >
        <Modal.Body>
          <h3 className="py-4"> You can delete only one company at a time</h3>
          <Row>
            {selectedRows.map((rowdata, k) => (
              <Col key={k}>
                <ExpandedComponent data={rowdata} />
                <h2 className="py-4"> delete this company? </h2>
                <Tab.Container id="left-tabs-example" defaultActiveKey={k}>
                  <Row>
                    <div className="py-4 d-flex justify-content-center">
                      <button className="btn-tutorial " onClick={() => handleDelete(rowdata)}> 
                        Yes 
                      </button>
                      <div className="divider" />
                      <button className="btn-tutorial " onClick={() => handleEscapeDelete()}> 
                        No 
                      </button>
                    </div>
                  </Row>
                </Tab.Container>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        show={modalValueEdit.show}
        style={{ overflowY: "scroll" }}
      >
        <Modal.Body>
          <h2 className="py-4"> edit company? </h2>
          <Row>
            {selectedRows.map((data, k) => (
              <Col key={k}>
                <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                  <Row>
                    <ExpandedComponentEdit contactsdataoptions={contactsdataoptions} data={data} userData={userData} userRefetch={userRefetch} />
                  </Row>
                </Tab.Container>
              </Col>
            ))}
          </Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
            <Row>
              <div className="py-4 d-flex justify-content-center">
                <button className="btn-tutorial " onClick={handleEscapeEdit}> 
                  Done
                </button>
              </div>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        show={modalValueAlert.show}
        style={{ overflowY: "scroll" }}
      >
        <Modal.Body>
          <h3 className="py-4"> You must not delete your last company</h3>
          <Row>
            <div className="py-4 d-flex justify-content-center">
              <button className="btn-tutorial " onClick={handleEscapeAlert}> 
                OK 
              </button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompanyTable;
