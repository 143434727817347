import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// Client Data
import completed from '../data/Completed';
// Components
import { Card, Badge, Row, Col, CardGroup } from 'react-bootstrap';

// GraphQL
/***/
// Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState';
import ModalTutorial from '../modals/ModalTutorial';
import ModalTutorial1 from '../modals/ModalTutorial1';
import { useAuthContext } from '../utils/AuthContext';
import DiscoveryPlaybookPage from './LandingPage/DiscoveryPlaybook';
import InspiringSection from './LandingPage/InspiringSection';
import ComparisonSection from './LandingPage/ComparisonSection';
import FeatureCarousel from './LandingPage/FeatureCarousel';

const ModulesRender = () => {
  const [modalShow, setModalShow] = useState(false);
  const [tutorialShow, setTutorialShow] = useState(false);

  const [state] = usePepperContext();
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();
  const { modules, userdata } = state;
  let googleUser = userdata.googleUser;

  useEffect(() => {
    if (location.search.split('?')[1] === "welcome" && !tutorialShow) {
      setTutorialShow(true);
    }
  }, [location, tutorialShow]);

  // Adding disabled card class style
  let disabledCardClass = {
    backgroundColor: 'rgba(0,0,0,.1)',
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: '1px 1px 2px rgba(0,0,0,.125)',
    color: 'rgba(0,0,0,.125)',
    filter: 'grayscale(100%)',
  };

  const handleComplete = (number) => {
    let completeForUser = completed.some((complete) => complete === number);
    return (
      <Badge text='light' className='pill-center mx-2 my-2' bg={completeForUser ? 'success' : 'secondary'}>
        {completeForUser ? 'Active' : 'Activate'}
      </Badge>
    );
  };

  const handleGoogleUser = (userLogic, module) => {
    if ("My Calendar" === module.moduleTitle && !userLogic) {
      return (
        <CardGroup className="justify-content-center">
          <Link to='/'>
            <Card className='text-center modulecard' style={disabledCardClass}>
              {isAuthenticated ? (
                <Badge className='pill-center mx-2 my-2' bg='dark'>
                  Not Available
                </Badge>
              ) : (
                <Badge className='pill-center mx-2 my-2' bg style={{backgroundColor: "#F2A90B"}}>
                  Login Required
                </Badge>
              )}
              <Card.Img
                className='modulecard-img'
                variant='top'
                src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`}
                alt={`module-image:${module.moduleTitle}`}
              />
              <Card.Body>
                <Card.Title>{module.moduleTitle}</Card.Title>
              </Card.Body>
              <Card.Footer>
                <small className='pill-center'>Not Available</small>
              </Card.Footer>
            </Card>
          </Link>
        </CardGroup>
      );
    } else if ("My Calendar" === module.moduleTitle && userLogic) {
      return (
        <CardGroup className="justify-content-center">
          <Link to={`/${module.moduleLink}`}>
            <Card className='text-center modulecard'>
              {isAuthenticated ? (
                handleComplete(module.moduleNumber)
              ) : (
                <Badge className='pill-center mx-2 my-2' bg style={{backgroundColor: "#F2A90B"}}>
                  Login Required
                </Badge>
              )}
              <Card.Img
                className='modulecard-img'
                variant='top'
                src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`}
                alt={`module-image:${module.moduleTitle}`}
              />
              <Card.Body>
                <Card.Title>{module.moduleTitle}</Card.Title>
              </Card.Body>
              <Card.Footer>
                <Badge className="pill-center" bg='none'>Click to open</Badge>
              </Card.Footer>
            </Card>
          </Link>
        </CardGroup>
      );
    } else {
      return (
        <CardGroup className="justify-content-center">
          <Link to={`/${module.moduleLink}`}>
            <Card className='text-center modulecard'>
              {isAuthenticated ? (
                handleComplete(module.moduleNumber)
              ) : (
                <Badge className='pill-center mx-2 my-2' bg style={{backgroundColor: "#F2A90B"}}>
                  Login Required
                </Badge>
              )}
              <Card.Img
                className='modulecard-img'
                variant='top'
                src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`}
                alt={`module-image:${module.moduleTitle}`}
              />
              <Card.Body>
                <Card.Title>{module.moduleTitle}</Card.Title>
              </Card.Body>
              <Card.Footer>
                <Badge className="pill-center" bg='none'>Click to open</Badge>
              </Card.Footer>
            </Card>
          </Link>
        </CardGroup>
      );
    }
  };

  if (!modules.length) {
    return <h3>There are no modules in this library!</h3>;
  }

  const loggedIn = isAuthenticated;
  return (
    <div>
      {loggedIn ? (
        <div className='d-flex justify-content-end py-4 mx-4'>
          <button className='btn-tutorial' onClick={() => setModalShow(true)}>
            Tutorial
          </button>
        </div>
      ) : (
        <>
          {InspiringSection()}
          {DiscoveryPlaybookPage()}
          {FeatureCarousel()}
          {ComparisonSection()}
        </>
      )}
      {loggedIn ? (
        <>
            <Row className="justify-content-center">
              {modules &&
                modules.map((module) => (
                  <Col key={module.moduleNumber}  className="d-flex justify-content-center">
                    {loggedIn ? (
                      handleGoogleUser(googleUser, module)
                    ) : (
                      <CardGroup>
                        <Link to={`/${module.moduleLink}`}>
                          <Card className='text-center modulecard'>
                            {loggedIn ? (
                              handleComplete(module.moduleNumber)
                            ) : (
                              <Badge className='pill-center mx-2 my-2' bg style={{backgroundColor: "#F2A90B"}}>
                                Login Required
                              </Badge>
                            )}
                            <Card.Img
                              className='modulecard-img'
                              variant='top'
                              src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`}
                              alt={`module-image:${module.moduleTitle}`}
                            />
                            <Card.Body>
                              <Card.Title>{module.moduleTitle}</Card.Title>
                            </Card.Body>
                            <Card.Footer>
                              <Badge className="pill-center" bg='none'>Click to open</Badge>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </CardGroup>
                    )}
                  </Col>
                ))}
            </Row>
        </>
      ) : (
        <Card></Card>
      )}
      <ModalTutorial
        data={modules}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ModalTutorial1
        data={modules}
        show={tutorialShow}
        onHide={() => setTutorialShow(false)}
      />
    </div>
  );
};

export default ModulesRender;
