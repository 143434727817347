// import pages
import Home from './pages/Home';
import Boards from './pages/Boards';
import OneBoard from './pages/OneBoard';
import OneLetter from './pages/OneLetter';
import Calendar from './pages/Calendar';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Activity from './pages/Activity';
import Contacts from './pages/Contacts';
import Companies from './pages/Companies';
import Feeds from './pages/Feeds';
import Letters from './pages/Letters';
import Metrics from './pages/Metrics';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ConfirmEmail from './pages/ConfirmEmail'

// Components
import ModalContact from './modals/ModalContact';
import Profile from './components/Profile/Profile';
import Terms from './pages/Terms';
import NoMatch from './pages/NoMatch';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import JobsTable from './components/FeedsComponent/JobsTable';

// export each page as generated API 
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { path: "/home", name: " ", Component: Home },
  { path: "/", name: "Home ", Component: Home },
  { path: "/terms-of-use", name: "> Terms Of Use ", Component: Terms },
  { path: "/privacy-policy", name: "> Privacy Policy", Component: PrivacyPolicy },
  { path: "/boards", name: "> Boards ", Component: Boards },
  { path: "/boards/:boardId", name: "> Board ", Component: OneBoard },
  { path: "/calendar", name: "> Calendar ", Component: Calendar},
  { path: "/activity", name: "> Activity ", Component: Activity},
  { path: "/contacts", name: "> Contacts ", Component: Contacts},
  { path: "/contacts/:contactId", name: "> My Contact ", Component: ModalContact},
  { path: "/companies", name: "> Companies ", Component: Companies},
  { path: "/feeds", name: "> Feeds ", Component: Feeds},
  { path: "/feeds/:feedId", name: "> Feed ", Component: JobsTable},
  { path: "/letters", name: "> Letters ", Component: Letters},
  { path: "/letters/:letterId", name: "> Letter ", Component: OneLetter },
  { path: "/metrics", name: "> Metrics ", Component: Metrics},
  { path: "/login", name: "ᐳ Login ", Component: Login },
  { path: "/signup", name: "ᐳ Signup ", Component: Signup },
  { path: "/Terms", name: "ᐳ Terms ", Component: Terms },
  { path: "/profile", name: "ᐳ Profile ", Component: Profile },
  { path: "/login/forgot-password", name: "ᐳ Forgot Password", Component: ForgotPassword },
  { path: "/login/reset-password", name: "ᐳ Reset Password", Component: ResetPassword },
  { path: "/confirm/user/:idToken", name: "ᐳ Confirm Email", Component: ConfirmEmail },
  { name: "No match", Component: NoMatch },
];