import { tableUserContactOptions } from '../ContactTable/ContactData';
// import { defaultusercompany, defaultactivity, defaultboard, defaultfeed, 
//   defaultjob, defaultlanes, defaultletter, 
//   defaultnote, defaultusercontact } from "./../BoardComponent/defaultDummyObjects"; //create some dummy IDs for the default values

// export const thisusercreate = (thisuser) => {
//   // Create a new object that is a copy of the original board
//   const newUser = JSON.parse(JSON.stringify(thisuser)); 
//   console.log("thisuser initial create user", thisuser, newUser);
//   if (newUser.userboards != null && newUser.userboards.length > 0) {
//   // Define the additional usercompanies to add
//   const newusercompanies = JSON.parse(JSON.stringify(defaultusercompany));
//   // Add the new newusercompanies in the usercompanies array
//   newUser.usercompanies.push(newusercompanies);
//   console.log({newUser})
//   // Return the new board
//   return newUser;}
//   else {
//     return thisuser
//   }
// }
export  const findContactTitleFromId = (TableData, ContactIdValue) => {
  let ContactTitle = TableData.filter(item => item.value === ContactIdValue).map(item => item.label) 
  let findContactTitle = ContactTitle.toString()
  // console.log({findContactTitle})
  return ( 
    findContactTitle//the Contact title
       )
}

const culturedatacreate = (thisusercompany) => {
  // array1.toString()
    let companyCultureArray = thisusercompany.companyCulture //takes the companyCulture object array for a company
      return ( 
        companyCultureArray //the culture array
           )
    };
    
const valuesdatacreate = (thisusercompany) => {
  // array1.toString()
  let companyValuesArray = thisusercompany.companyValues //takes the companyValues object array for a company
  return ( 
    companyValuesArray //the Values array
       )
      };

const contactsdatacreate = (thisusercompany, contactsdata) => {
  let companyContactsArray = thisusercompany.usercontacts //takes the usercontact object array for a company
    if (companyContactsArray != null) {
      let contactLength = companyContactsArray.length;
      let contactsIdArray = []
      let contactsNameArray = []
      if (contactsdata != null) {   
        for (let i = 0; i < contactLength; i += 1) {
        let contactsId = companyContactsArray[i]._id  
        let contactsName = findContactTitleFromId(contactsdata, contactsId)
        contactsIdArray.push(contactsId);
        contactsNameArray.push(contactsName);
        }
         //console.log({contactsIdArray}, {contactsNameArray});
        //  console.log({contactsNameArray});
         let newdata= contactsNameArray

       return ( 
        newdata
        )
      }
      else
      {
       let newdata= contactsNameArray
        return ( 
          newdata
        )
      }
    }
    else
    {
        //console.log("This activity has no card")
        let newdata = "no contact"
        return ( 
          newdata //the cards value
             )
    }
};

// const companyIdcreate = (thisusercompany) => {
//     let basecompany = thisusercompany.usercompany //takes the company object array for a jobdata
//     if (basecompany != null) {
//     let companyId = basecompany._id
//         //define the new company
//       return ( 
//         companyId //the company value
//            )
//       }
//     };

// const notedatacreate = (thisusercompany) => {
//     let basenote = thisusercompany.usernote //takes the note object array for a jobdata
//     if (basenote != null) {
//     let noteText = basenote.noteText
//         //define the new note
//       return ( 
//         noteText //the note value
//            )
//       }
//     };

// const noteIdcreate = (thisusercompany) => {
//     let basenote = thisusercompany.usernote //takes the note object array for a jobdata
//     if (basenote != null) {
//     let noteId = basenote._id
//         //define the new note
//       return ( 
//         noteId //the note value
//              )
//       }
//     };


export  const tableDataCreate = (userdata) => {
  let allusercompanydata = userdata.usercompanies;
  let contactsdata = tableUserContactOptions(userdata)
  //console.log("data passed to table data create function", allusercompanydata)
  if (allusercompanydata != null && allusercompanydata.length > 0) {
      let allusercompanydataLength = allusercompanydata.length;//finds the length of the lanes object array
      let usercompany = [] //create a new usercompany array
      let usercompanyobject = {}
      // console.log( {allusercompanydata}, {allusercompanydataLength} )
      for (let i = 0; i < allusercompanydataLength; i += 1) {
        usercompanyobject[i] = {
          _id:allusercompanydata[i]._id,
          companyName: allusercompanydata[i].companyName || "1",
          companyUrl: allusercompanydata[i].companyUrl,
          companyLocation: allusercompanydata[i].companyLocation,
          companyFoundedYear: allusercompanydata[i].companyFoundedYear,
          companyDomain: allusercompanydata[i].companyDomain,
          companyDescription: allusercompanydata[i].companyDescription,
          companySize: allusercompanydata[i].companySize,
          companyCulture: culturedatacreate(allusercompanydata[i]) || ["Default"],
          companyValues: valuesdatacreate(allusercompanydata[i]) || ["Temporary"],
          usercontacts: contactsdatacreate(allusercompanydata[i], contactsdata) || ["None"],
          } //define the new usercompany object
          usercompany.push(usercompanyobject[i]);
          // console.log("usercompany item", allusercompanydata[i], "usercompanyobject item", usercompanyobject[i], "basic cards", baseusercompany[i].cards )
        }
        //console.log("data for table",{usercompany});
        let newdata= usercompany
        //console.log("table data",{newdata});
      return ( 
        newdata
        )
  }
}

export  const tableUserCompanyOptions = (userdata) => {
  let allusercompanydata = userdata.usercompanies;
  if (allusercompanydata != null && allusercompanydata.length > 0) {
      let allusercompanydataLength = allusercompanydata.length;//finds the length of the lanes object array
      let usercompany = [] //create a new usercompany array
      let usercompanyobject = {}
      // console.log( {allusercompanydata}, {allusercompanydataLength} )
      for (let i = 0; i < allusercompanydataLength; i += 1) {
        usercompanyobject[i] = {
          value:allusercompanydata[i]._id,
          label: allusercompanydata[i].companyName || "1"
          } //define the new usercompany object
          usercompany.push(usercompanyobject[i]);
          // console.log("usercompany item", allusercompanydata[i], "usercompanyobject item", usercompanyobject[i], "basic cards", baseusercompany[i].cards )
        }
         //console.log({usercompany});
        let newdata= usercompany
         //console.log({newdata});
      return ( 
        newdata
        )
  }
}


const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];				
            ctr++;
        });
    result += lineDelimiter;
    });

    return result;
}

export  const downloadCSV = (array) => {
	const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
