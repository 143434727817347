// "usercompanies": [
//   {
//     "_id": "dca04b8e83418ab252565bcb",
//     "companyName": "Peppercorn.ai",
//     "companyUrl": "https://www.peppercorn.ai",
//     "companyLocation": "California",
//     "companyFoundedYear": "2020",
//     "companyDomain": "www.peppercorn.ai",
//     "companyDescription": "Peppercorn provides the guidance and structure for you to develop and articulate your story, turning you into a better self-marketer and champion of your professional path.",
//     "companySize": "20",
//     "companyCulture": [
//       "transparent",
//       "nurturing"
//     ],
//     "companyValues": [
//       "loyalty",
//       "honesty"
//     ],
//     "usercontacts": [],
//     "contacts": []
//   },

const culturedatacreate = (thisusercompany) => {
  // array1.toString()
    let companyCultureArray = thisusercompany.companyCulture //takes the companyCulture object array for a company
      return ( 
        companyCultureArray //the culture array
           )
    };
    
const valuesdatacreate = (thisusercompany) => {
  // array1.toString()
  let companyValuesArray = thisusercompany.companyValues //takes the companyValues object array for a company
  return ( 
    companyValuesArray //the Values array
       )
      };

// const companyIdcreate = (thisusercompany) => {
//     let basecompany = thisusercompany.usercompany //takes the company object array for a jobdata
//     if (basecompany != null) {
//     let companyId = basecompany._id
//         //define the new company
//       return ( 
//         companyId //the company value
//            )
//       }
//     };

// const notedatacreate = (thisusercompany) => {
//     let basenote = thisusercompany.usernote //takes the note object array for a jobdata
//     if (basenote != null) {
//     let noteText = basenote.noteText
//         //define the new note
//       return ( 
//         noteText //the note value
//            )
//       }
//     };

// const noteIdcreate = (thisusercompany) => {
//     let basenote = thisusercompany.usernote //takes the note object array for a jobdata
//     if (basenote != null) {
//     let noteId = basenote._id
//         //define the new note
//       return ( 
//         noteId //the note value
//              )
//       }
//     };


export  const GeneraltableDataCreate = (allusercompanydata) => {
  if (allusercompanydata != null && allusercompanydata.length > 0) {
      let allusercompanydataLength = allusercompanydata.length;//finds the length of the lanes object array
      let usercompany = [] //create a new usercompany array
      let usercompanyobject = {}
      // console.log( {allusercompanydata}, {allusercompanydataLength} )
      for (let i = 0; i < allusercompanydataLength; i += 1) {
        usercompanyobject[i] = {
          _id:allusercompanydata[i]._id,
          companyName: allusercompanydata[i].companyName || 1,
          companyUrl: allusercompanydata[i].companyUrl,
          companyLocation: allusercompanydata[i].companyLocation,
          companyFoundedYear: allusercompanydata[i].companyFoundedYear,
          companyDomain: allusercompanydata[i].companyDomain,
          companyDescription: allusercompanydata[i].companyDescription,
          companySize: allusercompanydata[i].companySize,
          companyCulture: culturedatacreate(allusercompanydata[i]) || ["Default"],
          companyValues: valuesdatacreate(allusercompanydata[i]) || ["Temporary"]
          } //define the new usercompany object
          usercompany.push(usercompanyobject[i]);
          // console.log("usercompany item", allusercompanydata[i], "usercompanyobject item", usercompanyobject[i], "basic cards", baseusercompany[i].cards )
        }
         //console.log({usercompany});
        let newdata= usercompany
         //console.log({newdata});
      return ( 
        newdata
        )
  }
}

const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];				
            ctr++;
        });
    result += lineDelimiter;
    });

    return result;
}

export  const downloadCSV = (array) => {
	const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
