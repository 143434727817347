import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Spinner, Container } from 'react-bootstrap';
import GenerateDoc from '../components/GenerateDoc';
import InlineEdit from '../components/InlineEdit';
import { useQuery, useMutation } from '@apollo/client';
import { QUERY_ME } from '../utils/queries';
import { UPDATE_USER_LETTER } from '../utils/mutations';
import { usePepperContext } from '../utils/GlobalState';
import { USER_DATA } from '../utils/actions';
import { idbPromise } from '../utils/helpers';
import { useAuthContext } from '../utils/AuthContext';

const OneLetter = () => {
  const { isAuthenticated } = useAuthContext();
  const loggedIn = isAuthenticated;
  const [state, dispatch] = usePepperContext();
  const { loading: userLoading, data: userData, refetch: userRefetch } = useQuery(QUERY_ME);
  const { letterId } = useParams();
  const [letter, setLetter] = useState(null);

  // State for inline edit fields
  const [storedTextContent, setStoredTextContent] = useState('');
  const [storedTextName, setStoredTextName] = useState('');
  const [storedTextCategory, setStoredTextCategory] = useState('');

  const [updateUserLetter] = useMutation(UPDATE_USER_LETTER, {
    onCompleted: async () => userRefetch(),
  });

  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      });
      idbPromise('userdata', 'put', userData.me);
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        });
      });
    }
  }, [userData, userLoading, dispatch]);

  useEffect(() => {
    if (state.userdata && state.userdata.userletters) {
      const foundLetter = state.userdata.userletters.find(
        (letter) => letter._id === letterId
      );
      if (foundLetter) {
        setLetter(foundLetter);
        setStoredTextContent(foundLetter.letterContent);
        setStoredTextName(foundLetter.letterName);
        setStoredTextCategory(foundLetter.letterCategory);
      }
    }
  }, [state.userdata, letterId]);

  const handleNewTextContent = async (textValue) => {
    if (textValue !== storedTextContent) {
      setStoredTextContent(textValue);
      try {
        await updateUserLetter({
          variables: { letterId, letterContent: textValue },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleNewTextCategory = async (textValue) => {
    if (textValue !== storedTextCategory) {
      setStoredTextCategory(textValue);
      try {
        await updateUserLetter({
          variables: { letterId, letterCategory: textValue },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleNewTextName = async (textValue) => {
    if (textValue !== storedTextName) {
      setStoredTextName(textValue);
      try {
        await updateUserLetter({
          variables: { letterId, letterName: textValue },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div>
      {loggedIn ? (
        <Container className="my-4">
          <main className="pb-4">
            <div>
              {userLoading ? (
                <div className="text-center">
                  <Spinner animation="border" />
                </div>
              ) : letter ? (
                <Card className="letter-card">
                  <Card.Body>
                    <h2 className="letter-title">
                      <InlineEdit
                        text={storedTextName}
                        onSetText={handleNewTextName}
                      />
                    </h2>
                    <div className="dynamic-content-div">
                      <InlineEdit
                        text={storedTextContent}
                        onSetText={handleNewTextContent}
                      />
                    </div>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    <InlineEdit
                      text={storedTextCategory}
                      onSetText={handleNewTextCategory}
                    />
                  </Card.Footer>
                  <GenerateDoc letterdata={letter} />
                </Card>
              ) : (
                <div className="text-center">
                  <p>No letter found.</p>
                </div>
              )}
            </div>
          </main>
        </Container>
      ) : (
        <>
          {localStorage.setItem('referring_url', window.location.href)}
          <h4 className="text-center">
            You need to <Link to="/login">Login</Link> before you can access
            the content of this page.
          </h4>
        </>
      )}
    </div>
  );
};

export default OneLetter;
