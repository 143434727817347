import React, { useEffect } from 'react'
//Client Data
import moduleData from '../data/moduleData'
//components
import ModulesRender from '../components/ModulesRender'
//GraphQL
import { useQuery } from '@apollo/client'
import { QUERY_ME } from '../utils/queries'
//utils and GlobalState
import { usePepperContext } from '../utils/GlobalState'
import { UPDATE_MODULES, USER_DATA } from '../utils/actions'
import { idbPromise } from '../utils/helpers'
import { useAuthContext } from '../utils/AuthContext'
import { Spinner } from 'react-bootstrap'

const Home = () => {
  // eslint-disable-next-line
  const [ state, dispatch] = usePepperContext();
  const { toggleAuth } = useAuthContext();
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME)
  const moduleLoading = false
  
  useEffect(() => {
    toggleAuth()
    if (moduleData) {
      dispatch({
        type: UPDATE_MODULES,
        modules: moduleData,
      })
    }

    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      })
      idbPromise('userdata', 'put', userData.me)
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        })
      })
    }

  }, [userData, userLoading, dispatch, toggleAuth])

  return (
    <div className='container'>
      <main>
        <div>{moduleLoading ? <div className='container text-center'>
    <Spinner />
  </div> : <ModulesRender />}</div>
      </main>
      <hr />
    </div>
  )
}

export default Home
