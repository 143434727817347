// _id
// firstName
// lastName
// profilePic
// gender
// title
// role
// seniority
// location
// city
// state
// country
// linkedIn
// twitter
// crunchbase
// facebook
// github
// bio
// about
// usercompany {
//   _id
//   companyName
//   companyUrl
//   companyLocation
//   companySize
//   companyDescription
//   companyFoundedYear
// }
// company {
//   _id
//   companyName
//   companyUrl
//   companyLocation
//   companySize
//   companyDescription
//   companyFoundedYear
// }
// }


const culturedatacreate = (thisusercontact) => {
  // array1.toString()
    let contactCultureArray = thisusercontact.contactCulture //takes the contactCulture object array for a contact
      return ( 
        contactCultureArray //the culture array
           )
    };
    
const valuesdatacreate = (thisusercontact) => {
  // array1.toString()
  let contactValuesArray = thisusercontact.contactValues //takes the contactValues object array for a contact
  return ( 
    contactValuesArray //the Values array
       )
      };



export  const tableDataCreate = (userdata) => {
  let allusercontactdata = userdata.usercontacts;
  if (allusercontactdata != null && allusercontactdata.length > 0) {
      let allusercontactdataLength = allusercontactdata.length;//finds the length of the lanes object array
      let usercontact = [] //create a new usercontact array
      let usercontactobject = {}
      // console.log( {allusercontactdata}, {allusercontactdataLength} )
      for (let i = 0; i < allusercontactdataLength; i += 1) {
        usercontactobject[i] = {
          _id:allusercontactdata[i]._id,
          firstName: allusercontactdata[i].firstName || "a",
          lastName: allusercontactdata[i].lastName || "default",
          profilePic: allusercontactdata[i].profilePic || "default",
          gender: allusercontactdata[i].gender || "default",
          title: allusercontactdata[i].title || "default",
          role: allusercontactdata[i].role || "default",
          seniority: allusercontactdata[i].seniority || "default",
          location: allusercontactdata[i].location || "default",
          city: allusercontactdata[i].city || "default",
          state: allusercontactdata[i].state || "default",
          country: allusercontactdata[i].country || "default",
          linkedIn: allusercontactdata[i].linkedIn || "default",
          twitter: allusercontactdata[i].twitter || "default",
          crunchbase: allusercontactdata[i].crunchbase || "default",
          facebook: allusercontactdata[i].facebook || "default",
          github: allusercontactdata[i].github || "default",
          bio: allusercontactdata[i].bio || "default",
          about: allusercontactdata[i].about || "default"
          // contactCulture: culturedatacreate(allusercontactdata[i]) || ["Default"],
          // contactValues: valuesdatacreate(allusercontactdata[i]) || ["Temporary"]
          } //define the new usercontact object
          usercontact.push(usercontactobject[i]);
          // console.log("usercontact item", allusercontactdata[i], "usercontactobject item", usercontactobject[i], "basic cards", baseusercontact[i].cards )
        }
         //console.log({usercontact});
        let newdata= usercontact
         //console.log({newdata});
      return ( 
        newdata
        )
  }
}

//this function creates the usercontact options for the select component
export  const tableUserContactOptions = (userdata) => { 
  let allusercontactdata = userdata.usercontacts;
  if (allusercontactdata != null && allusercontactdata.length > 0) {
      let allusercontactdataLength = allusercontactdata.length;//finds the length of the lanes object array
      let usercontactOptions = [{value:"1delete", label:"Delete Contact"}] //create a new usercontact array with a default option
      let usercontactOptionsobject = {}
      for (let i = 1; i < allusercontactdataLength + 1; i += 1) {
        usercontactOptionsobject[i] = {
          value :allusercontactdata[i-1]._id,
          // label: [allusercontactdata[i].firstName, allusercontactdata[i].lastName].toString()
          label: `${allusercontactdata[i-1].firstName} ${allusercontactdata[i-1].lastName}`
          } //define the new usercontact Options object
          usercontactOptions.push(usercontactOptionsobject[i]);
        }
        //console.log({usercontactOptions});
        let newdata= usercontactOptions
         //console.log({newdata});
      return ( 
        newdata
        )
  }
}

const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];				
            ctr++;
        });
    result += lineDelimiter;
    });

    return result;
}

export  const downloadCSV = (array) => {
	const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
