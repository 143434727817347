import React from 'react';
import { Button, InputGroup, FormControl, Card, Col } from 'react-bootstrap';


  let expandedCardClass = {
	fontFamily: "Roboto, sans-serif)",
	fontSize: "13px",
	fontWeight: "100",
	filter: "grayscale(100%)"
  };
  
  const ExpandedComponent = ({data}) => {

    return (
    <div>
   		{/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    <Card style={(expandedCardClass)}>
		<Card.Header className="text-muted">
		  <br/>
			<div className="divider"/>
			  	Company:  {data.companyName}
			<div className="divider"/>
			<div className="divider"/>
				Size:  {data.companySize}
			<div className="divider"/>
			<div className="divider"/>
				Founded:  {data.companyFoundedYear}
			<div className="divider"/>
				Location:  {data.companyLocation}
		  <br/>
	  </Card.Header>
		<Card.Body>
		  <Card.Text>
			  {data.companyDescription}
		  </Card.Text>
		  <Card.Text>
			  {data.companyCulture.join(" , ")}
		  </Card.Text>
		  <Card.Text>
			  {data.companyValues.join(" , ")}
		   </Card.Text>
		   {data.usercontacts && data.usercontacts.map((contacts,k) => (
        	<Col key={k}>
				Contact:{contacts}
		    </Col>  
           ))} 
		</Card.Body>
		<Card.Footer className="text-muted">
			URL & Domain:
			<br/>
				{data.companyUrl}
			<br/>
				{data.companyDomain}
	  </Card.Footer>
    </Card>
  </div>
    );
};

export default  ExpandedComponent;