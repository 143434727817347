import React from 'react';
import { Card } from 'react-bootstrap';

const Terms = () => {
  return (
    <div className="container">
      <Card>
        <Card.Body>
          <div className="dynamic-content-div">
            <div id="tems">
              <h5><strong>Peppercorn.ai Application’s Terms Of Use</strong></h5>
              <p>Last updated July 09, 2024</p>

              <h7><strong>AGREEMENT TO TERMS</strong></h7>
              <p>By accessing the Peppercorn.ai Web Application or related services, you agree to these Terms of Use. If you disagree, discontinue use immediately. We reserve the right to modify these terms at any time, with changes indicated by the “Last Updated” date. Continued use after updates means acceptance of the new terms.</p>

              <h7><strong>INTELLECTUAL PROPERTY RIGHTS</strong></h7>
              <p>All content and trademarks on the site are owned or licensed by us and protected by law. You are granted a limited license to use the site for personal, non-commercial purposes. Unauthorized use is prohibited.</p>

              <h7><strong>USER REPRESENTATIONS</strong></h7>
              <p>You agree to provide accurate registration information, comply with these terms, and use the site lawfully. We reserve the right to suspend or terminate accounts for violations.</p>

              <h7><strong>USER REGISTRATION</strong></h7>
              <p>You must keep your password confidential and are responsible for all account activity. We may change usernames deemed inappropriate.</p>

              <h7><strong>FEES AND PAYMENT</strong></h7>
              <p>We accept major credit cards for purchases. You agree to provide accurate payment information and authorize recurring charges if applicable. Prices may change, and all payments are in U.S. dollars.</p>

              <h7><strong>CANCELLATION</strong></h7>
              <p>Cancel your subscription anytime via your account or by contacting us. Cancellations take effect at the end of the current term.</p>

              <h7><strong>PROHIBITED ACTIVITIES</strong></h7>
              <p>Users must not engage in unlawful, harmful, or unauthorized activities on the site, including but not limited to data mining, fraud, harassment, or interference with site operations.</p>

              <h7><strong>USER-GENERATED CONTRIBUTIONS</strong></h7>
              <p>By posting content, you grant us a license to use it as described in these terms. You retain ownership but waive moral rights. We are not responsible for user content.</p>

              <h7><strong>PRIVACY POLICY</strong></h7>
              <p>We prioritize data privacy and security. By using the site, you agree to our <a href="link to privacy policy">Privacy Policy</a>, which explains our data practices.</p>

              <h7><strong>MODIFICATIONS AND INTERRUPTIONS</strong></h7>
              <p>We may update or discontinue the site without notice. We are not liable for any interruptions or changes.</p>

              <h7><strong>GOVERNING LAW</strong></h7>
              <p>These terms are governed by the laws of Delaware, USA.</p>

              <h7><strong>CONTACT US</strong></h7>
              <p>For any issues or further information, please contact us at info@peppercorn.ai.</p>

              <h7><strong>DISCLAIMER</strong></h7>
              <p>The site is provided “as is” without warranties. We are not liable for damages resulting from site use.</p>

              <p><strong>Disclosure:</strong> Peppercorn.ai's use and transfer of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements. For more details, please review the <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>.</p>

            </div>
          </div>
        </Card.Body>
      </Card> 
    </div>
  );
};

export default Terms;
