import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Card, Row, Col, Container, Modal, Button } from 'react-bootstrap';
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_USER_FEED } from "../../utils/mutations";
import { QUERY_ME } from '../../utils/queries';
import { usePepperContext } from '../../utils/GlobalState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const FeedList = ({ data }) => {
  // eslint-disable-next-line
  const [ state, dispatch] = usePepperContext()
  const {refetch } = useQuery(QUERY_ME, {
      fetchPolicy: "network-only",
  });

  const [deleteFeed] = useMutation(DELETE_USER_FEED, {
    onCompleted: () => {
      setShowModal(false);
      dispatch({ type: 'DELETE_FEED', payload: searchFeedId });
      refetch();
    },
    refetchQueries: [{ query: QUERY_ME }],
  });

  const [isRotating, setIsRotating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchFeedId, setSearchFeedId] = useState(null);

  const handleClose = () => setShowModal(false);

  const handleShow = ({ feedId, searchId }) => {
    setSearchFeedId(searchId);
    setShowModal(true);
  };

  const handleDelete = async () => {
    if (searchFeedId) {
      try {
        await deleteFeed({ variables: { feedId: searchFeedId } });
      } catch (error) {
        console.error("Error deleting feed:", error);
      }
    }
  };

  useEffect(() => {
    const anyRunning = data.some(feed => feed.feedStatus !== "Completed");
    setIsRotating(anyRunning);

    let interval = null;
    if (anyRunning) {
      // Dispatch START_SYNC action
      dispatch({ type: 'START_SYNC' });

      // Setup an interval to refetch feed data every 2 seconds if any feed is "Running"
      interval = setInterval(() => {
        refetch().catch(error => console.error("Refetch error:", error));
      }, 2000);
    } else {
      // Dispatch STOP_SYNC action
      dispatch({ type: 'STOP_SYNC' });
    }

    // Cleanup function to clear interval and dispatch STOP_SYNC action when component unmounts
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      dispatch({ type: 'STOP_SYNC' });
    };
  }, [data, refetch, dispatch]);  // Depend on data, refetch function, and dispatch

  return (
    <Container>
      <div className="form-card">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="flex-grow-1">Your Previous Feeds</h3>
          <FontAwesomeIcon
            icon={faSync}
            color='green'
            className={`mx-2 ${isRotating ? 'rotate-animation' : ''}`}
          />
        </div>
        <Row xs={1} md={2} lg={3} className="g-4">
          {data.map((feed, index) => (
            <Col key={index}>
              <div style={{ position: 'relative' }}>
                <div 
                  style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', zIndex: '1000' }}
                  onClick={() => handleShow({ feedId: feed.feedId, searchId: feed._id })}
                >
                  ✖️
                </div>
                {feed.feedStatus === "Running" || !feed.feedStatus ? (
                  <div className="h-100 text-center mb-4" style={{ borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Card className="h-100 text-center mb-4" style={{ borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Card.Body>
                        <Card.Title>{feed.feedName}</Card.Title>
                        <Card.Text>
                          Status: {feed.feedStatus} <br />
                          <span className='disabled-span'>Disabled until complete</span>< br />
                        </Card.Text>                        
                      </Card.Body>
                    </Card>
                  </div>
                ) : (
                  <Link to={`/feeds/${feed.feedId}`}>
                    <Card className="h-100 text-center mb-4" style={{ borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Card.Body>
                        <Card.Title>{feed.feedName}</Card.Title>
                        <Card.Text className='enabled-span'>
                          Status: {feed.feedStatus} <br />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Feed</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this feed?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FeedList;
