import React, { useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Auth from '../utils/auth';
import { Navbar, Nav, Button, Container } from 'react-bootstrap'; // Ensure Container is imported
import SearchForm from './SearchForm';
import useOutsideClick from '../hooks/useOutsideClick';
import ToolTipCustom from './ToolTipCustom';
import { useAuthContext } from '../utils/AuthContext';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../utils/mutations';

const AppNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const { isAuthenticated } = useAuthContext();
  const [logout] = useMutation(LOGOUT, { onCompleted: () => Auth.logout() });
  const ref = useRef();

  useOutsideClick(ref, () => setExpanded(false));

  const handleLogoutClick = async () => {
    await logout();
    setExpanded(false);
  };

  const NavigationLinks = () => {
    if (isAuthenticated) {
      return (
        <>
          <Nav.Link className="Nav_link" onClick={() => window.location.assign(`https://www.peppercorn.ai/community`)}>
            <ToolTipCustom tooltipContent='Community' overlayContent='Community' badgeVariant='light' placement='bottom' />
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/profile`} onClick={() => setExpanded(false)}>
            <ToolTipCustom tooltipContent='Profile' badgeVariant='light' placement='bottom' overlayContent={<i className='fas fa-user-circle'></i>} />
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/`} onClick={handleLogoutClick}>
            <ToolTipCustom tooltipContent='Logout' badgeVariant='light' placement='bottom' overlayContent={<i className='fas fa-sign-out-alt'></i>} />
          </Nav.Link>
          <div className='my-auto justify-content-end'>
            <Button onClick={handleLogoutClick} variant='secondary'>Logout</Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Nav.Link as={NavLink} to={`/login`} onClick={() => setExpanded(false)}>
            <ToolTipCustom tooltipContent='Login' overlayContent='Login' badgeVariant='light' placement='bottom'/>
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/signup`} onClick={() => setExpanded(false)}>
            <ToolTipCustom tooltipContent='Signup' overlayContent='Signup' badgeVariant='light' placement='bottom'/>
          </Nav.Link>
        </>
      );
    }
  };

  return (
    <Navbar fixed='top' collapseOnSelect expand='lg' variant='dark' expanded={expanded} ref={ref}>
      <Container className='my-2'>
        <Navbar.Brand as={Link} to='/'>
          <img src='https://discovery-journey.s3.us-east-2.amazonaws.com/logo-file-01.png' width='127' height='37' alt='logo' />
        </Navbar.Brand>
        {Auth.isAuthenticated && <div className='search-form-container'><SearchForm /></div>}
        <Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={() => setExpanded(prevExpanded => !prevExpanded)} />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className="ms-auto"> {/* Use ms-auto here to align items to the right */}
            <NavigationLinks />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
