import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Modal, Tab, Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap';
import ContactFormMoreInputs from './ContactFormMoreInputs';
import ExpandedComponent from './ExpandedComponent';
import ExpandedComponentEdit from './ExpandedComponentEdit';
//GraphQL
import {useMutation} from '@apollo/client';
import { DELETE_USERCONTACT } from '../../utils/mutations';
//Utils and GlobalState
import { tableDataCreate, downloadCSV } from './ContactData';

const ContactTable = (props) => {
	const {
		tableDataItemA, 
		userData,
		userRefetch
		} = props
	const data = tableDataItemA;

	const [deleteusercontact] = useMutation(DELETE_USERCONTACT, {
			onCompleted: async data => userRefetch()});	
	
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
	const [modalValueDelete, setModalValueDelete] = useState({ show: false, selectedRows:{}});
	const [modalValueEdit, setModalValueEdit] = useState({ show: false, selectedRows:{}});

	//Handle Delete
    const handleDelete = async (data) => {  
	  // clear value
	  //console.log("data", data)
		try {
			// delete
			await deleteusercontact({
			  variables: { 
				usercontactId: data._id, 
			  }
			});
	  
			// clear form value
			 setModalValueDelete({ show: false, selectedRows:{}})
		  } catch (e) {
			console.error(e);
		  }
	}
  
  	//Handle Escape Delete
  	const handleEscapeDelete = async (data) => {
	  // clear value
	   setModalValueDelete({ show: false, selectedRows: {}})
  	}

  	//Handle Escape Edit
  	const handleEscapeEdit = async () => {
	  // clear value
	  setModalValueEdit({ show: false, selectedRows: {}})
  	}

	useEffect(() => {		
		//console.log('state', selectedRows);
	}, [selectedRows]);

	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
		//console.log("handleRowSelected", selectedRows)
	}, []);


	const contextActions = React.useMemo(() => {
		const handleDelete = () => {
				setToggleCleared(!toggleCleared);
				setModalValueDelete({ show: true, selectedRows: selectedRows})
		};
		const handleEdit = () => {
				setToggleCleared(!toggleCleared);
				setModalValueEdit({ show: true, selectedRows: selectedRows})
		};
		return (
		<div>
			<Button key="edit" onClick={handleEdit} style={{ backgroundColor: 'green' }} icon>
				Edit
			</Button>
			<div className='divider'/>
			<Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
				Delete
			</Button>
		</div>
		);
	}, [data, selectedRows, toggleCleared]);
//data removed from depend messes with check box selection

	const Export = ({ onExport }) => <Button variant='success' onClick={e => onExport(e.target.value)}>Export</Button>;
	const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(tableDataCreate(userData.me))} />, []);
	//Organizing data for Export as CSV Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr

    const URLWrap = row => (
		<a href= {`${row.linkedIn}`} style={{ wordBreak: "break-all" }}>
		  {row.linkedIn}
		</a>
	  );
//Begin define columns and row rendering conditionals
	const columns = useMemo(
		() => [
			// {	
			// 	cell: () => <button onClick={handleAction()}>Action</button>,
			// 	ignoreRowClick: true,
			// 	allowOverflow: true,
			// 	button: true,
			// },
			{
				name: 'First',
				selector: row => `${row.firstName.slice(0, 10)}...`,
				sortable: true,
				right: true,
			},
			{
				name: 'Last',
				selector: row => `${row.lastName.slice(0, 10)}...`,
				sortable: true,
				right: true,
			},
			{
				name: 'Title',
				selector: row => row.title,
				sortable: true,
				right: true,
			},
			{
				name: 'Role',
				selector: row => row.role,
				sortable: true,
				right: true,
			},
			{
				name: 'Location',
				selector: row => row.location,
				sortable: true,
				right: true,
			},
			{
				name: 'About',
				selector: row => `${row.about.slice(0, 10)}...`,
				sortable: true,
				right: true,
			},
			{
				name: 'LinkedIn',
				selector: row => <URLWrap {...row} />,
				sortable: true,
				right: true,
			},
			{
				name: 'Bio',
				selector: row => row.bio,
				sortable: true,
				right: true,
			}
		],
		[],
	);
//End define columns and row rendering conditionals

//Begin Define Filter Component
const FilterComponent = ({ filterText, onFilter, onClear }) => {
	const inputRef = useRef(null);

// Set focus on the input field when filterText changes
	useEffect(() => {
		  inputRef.current.focus();
	}, [filterText]);

	return (
		  <InputGroup className="mb-3">
			<FormControl
			id="search"
			type="text"
			placeholder="Filter By Contact Last Name" //what can you filter by?
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
			aria-describedby="basic-addon2"
			ref={inputRef} // Attach the ref to the input field
			autoComplete="off" // Disable autofill
			/>
		<Button variant="outline-secondary" id="button-addon2" type="button" onClick={onClear}>
			  Clear
		</Button>
		  </InputGroup>
	);
  };

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
//End Define Filter Component
	
//main code extracts filtered data for rendering in table	
	const filteredItems = data.filter(
		item => item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase()), //what can you filter by?
		);
		// console.log("filteredItems", filteredItems)
//End main code extracts filtered data for rendering in table

// //Begin Define Filter Component
// 		const FilterComponent = ({ filterText, onFilter, onClear }) => (
// 			<InputGroup className="mb-3">
// 				<FormControl
// 					id="search"
// 					type="text"
// 					placeholder="Filter By Contact Last Name"
// 					aria-label="Search Input"
// 					value={filterText}
// 					onChange={onFilter}
// 					aria-describedby="basic-addon2"
// 				/>
// 				<Button variant="outline-secondary" id="button-addon2" type="button" onClick={onClear}>
// 					  Clear
// 				</Button>
// 		   </InputGroup>
// 			);

// 		const [filterText, setFilterText] = React.useState('');
//     	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

// 		const subHeaderComponentMemo = React.useMemo(() => {
//     		const handleClear = () => {
//     			if (filterText) {
//     				setResetPaginationToggle(!resetPaginationToggle);
//     				setFilterText('');
//     			}
//     		};
   
//     		return (
//     			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
//     		);
//     	}, [filterText, resetPaginationToggle]);
// //End Define Filter Component
		
// //main code extracts filtered data for rendering in table	
	
// 		const filteredItems = data.filter(
// 			item => item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase()),
// 			);


	return (
		<div>
		<ContactFormMoreInputs userRefetch={userRefetch}/>
	  <div className='table-container'>
      <DataTable
			title="My Contacts"
			data={filteredItems}
			columns={columns}
			actions={actionsMemo}
			expandableRows={true}
		    expandableRowsComponent={ExpandedComponent}
			expandOnRowClicked={false}
			expandOnRowDoubleClicked={false}
			expandableRowsHideExpander={false}
			pagination
			fixedHeader={true}
			fixedHeaderScrollHeight={"500px"}
			// conditionalRowStyles={conditionalRowStyles}
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
			persistTableHead
		 	selectableRows
		 	contextActions={contextActions}
		 	onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      </div>
      <Modal
            {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="text-center"
              show={modalValueDelete.show}
			  style={{overflowY: "scroll"}}
          >
            <Modal.Body>
			 <h3 className="py-4"> You can delete only one contact at a time</h3>
			 <Row>
				{selectedRows && selectedRows.map((data, k) => (
		   		<Col key={k}>
			 		<ExpandedComponent data={data}/>
					 <h2 className="py-4"> delete this contact? </h2>
                 <Tab.Container id="left-tabs-example" defaultActiveKey={k}>
                  <Row>
                    <div className="py-4 d-flex justify-content-center">
                      <button className="btn-tutorial " onClick={() => handleDelete(data)} > 
                        Yes 
                      </button>
                      <div className="divider"/>
                      <button className="btn-tutorial " onClick={() => handleEscapeDelete(data)} > 
                        No 
                      </button>
                    </div>
                  </Row>
                 </Tab.Container>
		   		</Col> ))}
	   		 </Row>
            </Modal.Body>
          </Modal>
		  <Modal
            {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="text-center"
              show={modalValueEdit.show}
			  style={{overflowY: "scroll"}}
          >
            <Modal.Body>
			 <h2 className="py-4"> edit contact? </h2>
			  <Row>
				{selectedRows && selectedRows.map((data, k) => (
		   		  <Col key={k}>					 
                    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                     <Row>
					   <ExpandedComponentEdit data={data}/>
                     </Row>
                    </Tab.Container>
		   		  </Col> ))}
	   		  </Row>
				 <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
                     <Row>
                       <div className="py-4 d-flex justify-content-center">
                        <button className="btn-tutorial " onClick={() => handleEscapeEdit(modalValueEdit)} > 
                        Done
                        </button>
                       </div>
                     </Row>
                  </Tab.Container>
            </Modal.Body>
          </Modal>
		</div>
			);
};

export default  ContactTable;