import React, { useState, useEffect } from 'react';
import Auth from '../utils/auth';
import { Link } from 'react-router-dom';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { RECOVER_PASSWORD } from '../utils/mutations'
import queryString from 'query-string';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Login = () => {
    // const [login, {error} ] = useMutation(LOGIN);
    const [errorName, setErrorName] = useState('');
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [validated] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [tokenError, setTokenError] = useState(false);
    const [recoverPassword, {error} ] = useMutation(RECOVER_PASSWORD);
    const parsed = queryString.parse(window.location.search);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
   
    useEffect(() => {
      if(parsed) {
        try {
          Auth.verifyToken(parsed.token)
        } catch(e) {
          console.error(e)
          setTokenError(true)
        }
       
      }
    },[parsed])
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

    const handleFormSubmit = async event => {
        event.preventDefault();
        // check if form has everything (as per react-bootstrap docs)
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setSubmitting(true);

        try {
            
            await recoverPassword({
                variables: {...formState}
            });
            setSubmitting(false)
            setSuccess(true);
            setTimeout(function(){window.location.assign('/login')}, 1000)
        } catch (e) {
            setErrorName(`${e}`);
            console.error(e);
            setShowAlert(true);
        }

        setFormState({
            email: '',
            password: '',
        });
    };

    return (
        <>
            <Col className="logincard" md={{ span: 6, offset: 3}}>
            <h3 className=" my-2 text-center">Reset Password</h3>
            {tokenError ? (
              <>
              <div className="text-center mx-2 my-2">
              <h2>Token is not valid to reset password</h2>
              <Link to={`/login/forgot-password`}>Go Back to Forgot Password</Link>
              </div>
              </>
            ) : ( 
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                {errorName.split(":")[2]}
                </Alert>
                <Form.Group>
                    <Form.Label htmlFor='email'>Email</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Your email address'
                        name='email'
                        onChange={handleInputChange}
                        value={formState.email}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>Email is required!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <Form.Label htmlFor='password'>New Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Your New password'
                        name='password'
                        onChange={handleInputChange}
                        value={formState.password}
                        required
                    />
                    <Form.Control.Feedback type='invalid'>Password is required!</Form.Control.Feedback>
                </Form.Group>
                
                <Button
                    disabled={!(formState.email && formState.password)}
                    type='submit'
                    variant='success'
                    className='w-100'
                    >
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  {isSuccess ? <FontAwesomeIcon icon={faCheck} /> : 'Reset Password'}
                </Button>

                {error && <div>Reset failed.</div>}
            </Form>
            )}
            <div>
            </div>
         
            </Col>
           
        </>
    );
};

export default Login;