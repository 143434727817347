import React, { useEffect } from 'react'
//Client Data
//Components
import BoardActivityTable from './BoardTableComponent/BoardActivityTable';
//GraphQL
import { useQuery } from '@apollo/client'
import { QUERY_ME, GET_BOARD} from '../../utils/queries';
//Utils and GlobalState
import { tableActivityDataCreate} from '../BoardComponent/BoardTableComponent/BoardTableData';
import { tableJobsOptions } from '../BoardComponent/BoardJobsComponent/BoardJobsData';
import { usePepperContext } from '../../utils/GlobalState'
import { USER_DATA,  BOARD_DATA } from '../../utils/actions'
import { idbPromise } from '../../utils/helpers'
import { Spinner } from 'react-bootstrap';

let SimpleDefaultTableDataItemA = [{

  }];

let Simplejobtableoptions = [{

  }];

const BoardAllActivity = (props) => {
  const {boardId} = props;
  // eslint-disable-next-line
  const [state, dispatch] = usePepperContext()
  const moduleLoading = false
	const { loading: userLoading, data: userData} = useQuery(QUERY_ME);
	  //console.log({boardId});

  const { data: boardData, loading: boardLoading, refetch: boardRefetch} = useQuery(GET_BOARD, 
    {variables: { boardBoardId:boardId },
      //fetchPolicy: "network-only",
      //nextFetchPolicy: "cache-first" // Used for subsequent executions
      // pollInterval: 500,
    }
  );

  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      })
      idbPromise('userdata', 'put', userData.me)
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        })
      })
    }
    //console.log('update')
  }, [userData, userLoading, dispatch])

  useEffect(() => {
		if (boardData) {
      //console.log("VALUE IN BOARDACTIVITY", {boardData})
		  dispatch({
			type: BOARD_DATA,
			boarddata: boardData.board
		  });
		//console.log("boarddata", boardData.board)
		idbPromise('boarddata','put', boardData.board);
		} else if (!boardLoading) {
		  idbPromise('boarddata','get').then(boarddata => {
			dispatch({
			  type: BOARD_DATA,
			  boarddata: boarddata
			});
			 //console.log({boarddata})
		  });
		}
	  }, [boardData, boardLoading, dispatch ]); 


	if (boardData){
    if (!boardData.board.useractivity) {
      return (
        <>
          <p> No activity data available...</p>
          {/* <CloudSpinner/> */}
        </>
      );
      }
      else {
      // let stateBoardActivityData = boardData.board.useractivity || state.boarddata.useractivity;
       //console.log("activity data available", {stateBoardActivityData});
       let tableDataItemA; 
       let jobsdataoptions;
        if (!tableActivityDataCreate(boardData.board) && !tableJobsOptions(boardData.board)){
            //console.log("Activity and Jobs not computed");
            tableDataItemA = SimpleDefaultTableDataItemA
            jobsdataoptions = Simplejobtableoptions
            
            
            
         }
        else if (!tableActivityDataCreate(boardData.board)){
            //console.log("Activity not computed");
            tableDataItemA = SimpleDefaultTableDataItemA
            jobsdataoptions = tableJobsOptions(boardData.board)
            
            
            //console.log("Activity Data for Table option 2", test1, test2 )
         }
        else if (!tableJobsOptions(boardData.board)){
          //console.log("Jobs not computed");
          tableDataItemA = tableActivityDataCreate(boardData.board)
          jobsdataoptions = Simplejobtableoptions
          
          
          //console.log("Activity Data for Table option 3", test1, test2 )
       }
        else {
           tableDataItemA = tableActivityDataCreate(boardData.board)
           jobsdataoptions = tableJobsOptions(boardData.board)
            //console.log("Activity Data for Table2", tableDataItemA)
            //console.log("job data Options", jobsdataoptions)
       };

        return (
          <div>
                  <div className='container'>
                    <main>
                      <div>
                        {moduleLoading ? (
                          <div className='container text-center'>
    <Spinner />
  </div>
                          ) : (
                        <BoardActivityTable boardData={boardData} tableDataItemA ={tableDataItemA} boardRefetch={boardRefetch} jobsdataoptions={jobsdataoptions}/>
                        )}
                      </div>
                    </main>
                    <hr />
                  </div>
          </div>
            );
          }
           }
        else{
         return (
          <>
             <p> No Board Data available...</p>
             {/* <CloudSpinner/> */}
          </>)
        }
}

export default BoardAllActivity