import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_JOBS } from "../../utils/mutations";
import { GET_CITY_BY_NAME, QUERY_ME } from "../../utils/queries";
import "./FeedForm.scss";
import { Container } from "react-bootstrap";
import { usePepperContext } from '../../utils/GlobalState';

const FeedForm = () => {
    const [formData, setFormData] = useState({ location: "", role: "" });
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const formRef = useRef(null);
    const { refetch } = useQuery(QUERY_ME, {
        fetchPolicy: "network-only",
    });
    
    const [{}, dispatch] = usePepperContext();

    const [getJobs, { loading }] = useMutation(GET_JOBS, {
        onCompleted: () => {
            setErrorMessage('');
            refetch().then(() => {
                dispatch({ type: 'START_SYNC' });
            });
        },
        onError: (error) => setErrorMessage(error.message),
    });

    const { data, error } = useQuery(GET_CITY_BY_NAME, {
        variables: { cityName: formData.location },
        skip: formData.location.trim().length < 2,
    });

    useEffect(() => {
        if (error) {
            setErrorMessage(error.message);
        }
        if (data && data.getCityByName) {
            setFilteredLocations(data.getCityByName.map(({ city, state }) => `${city}, ${state}`));
        } else {
            setFilteredLocations([]);
        }
    }, [data, error]);

    const handleLocationChange = (e) => {
        const input = e.target.value;
        setFormData({ ...formData, location: input });
        setIsDropdownVisible(input.trim().length >= 2);
    };

    const handleLocationSelect = (location) => {
        setFormData({ ...formData, location });
        setIsDropdownVisible(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.role.length > 2 && formData.location.length > 5) {
            try {
                getJobs({ variables: { ...formData, location: formData.location } });
                setFormData({ location: "", role: "" });
            } catch (error) {
                console.error("Error submitting feed:", error);
            }
        } else {
            setErrorMessage("You must enter a valid job title and location.");
        }
    };

    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <Container ref={formRef}>
            <div className="form-card">
                <form onSubmit={handleSubmit}>
                    <h3>Customize Your Job Feed</h3>
                    <label className="mx-2 input-feed">
                        <input
                            placeholder="Job Title"
                            type="text"
                            name="role"
                            value={formData.role}
                            onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                        />
                    </label>
                    <label className="mx-2 input-feed">
                        <div className="input-field-container">
                            <input
                                placeholder="Enter City Name"
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleLocationChange}
                                autoComplete="off"
                            />
                            {isDropdownVisible && (
                                <ul className="dropdown">
                                    {filteredLocations.map((location, index) => (
                                        <li key={index} onClick={() => handleLocationSelect(location)} className="dropdown-item">
                                            {location}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </label>
                    {errorMessage && (
                        <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
                    )}
                    <button type="submit" disabled={loading}>Submit</button>
                </form>
            </div>
        </Container>
    );
};

export default FeedForm;
