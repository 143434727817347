
const culturedatacreate = (thisusercontact) => {
  // array1.toString()
    let contactCultureArray = thisusercontact.contactCulture //takes the contactCulture object array for a contact
      return ( 
        contactCultureArray //the culture array
           )
    };
    
const valuesdatacreate = (thisusercontact) => {
  // array1.toString()
  let contactValuesArray = thisusercontact.contactValues //takes the contactValues object array for a contact
  return ( 
    contactValuesArray //the Values array
       )
      };


export  const GeneraltableDataCreate = (allusercontactdata) => {
  if (allusercontactdata != null && allusercontactdata.length > 0) {
    let allusercontactdataLength = allusercontactdata.length;//finds the length of the lanes object array
    let usercontact = [] //create a new usercontact array
    let usercontactobject = {}
    // console.log( {allusercontactdata}, {allusercontactdataLength} )
    for (let i = 0; i < allusercontactdataLength; i += 1) {
      usercontactobject[i] = {
        _id:allusercontactdata[i]._id,
        firstName: allusercontactdata[i].firstName || "a",
        lastName: allusercontactdata[i].lastName || "default",
        profilePic: allusercontactdata[i].profilePic || "default",
        gender: allusercontactdata[i].gender || "default",
        title: allusercontactdata[i].title || "default",
        role: allusercontactdata[i].role || "default",
        seniority: allusercontactdata[i].seniority || "default",
        location: allusercontactdata[i].location || "default",
        city: allusercontactdata[i].city || "default",
        state: allusercontactdata[i].state || "default",
        country: allusercontactdata[i].country || "default",
        linkedIn: allusercontactdata[i].linkedIn || "default",
        twitter: allusercontactdata[i].twitter || "default",
        crunchbase: allusercontactdata[i].crunchbase || "default",
        facebook: allusercontactdata[i].facebook || "default",
        github: allusercontactdata[i].github || "default",
        bio: allusercontactdata[i].bio || "default",
        about: allusercontactdata[i].about || "default"
        // contactCulture: culturedatacreate(allusercontactdata[i]) || ["Default"],
        // contactValues: valuesdatacreate(allusercontactdata[i]) || ["Temporary"]
        } //define the new usercontact object
        usercontact.push(usercontactobject[i]);
        // console.log("usercontact item", allusercontactdata[i], "usercontactobject item", usercontactobject[i], "basic cards", baseusercontact[i].cards )
      }
       //console.log({usercontact});
      let newdata= usercontact
       //console.log({newdata});
    return ( 
      newdata
      )
}
}

const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];				
            ctr++;
        });
    result += lineDelimiter;
    });

    return result;
}

export  const downloadCSV = (array) => {
	const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;

		const filename = 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}

		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
