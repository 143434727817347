import React from "react";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import expressions from 'angular-expressions';
import assign from "lodash/assign";
import last from "lodash/last";
import { saveAs } from "file-saver";
import { Button } from 'react-bootstrap';
import letterdatatemplate from './letterdatatemplate.docx';

function loadFile(url, callback) {
PizZipUtils.getBinaryContent(url, callback);
}

const GenerateDoc = (props) => {

const { letterdata} = props;
//console.log(letterdata)

if(letterdata == null){
return null;
}


// // Is shared amongst beneficiary's
// let checkShared = false;
// if(!letterdata.beneficiaryOrg.length) {
// checkShared = false;
// } else if (letterdata.beneficiaryOrg[0].share === "equal") {
// checkShared = true
// }

// if(!letterdata.beneficiaryPerson.length) {
// checkShared = false;
// } else if (letterdata.beneficiaryPerson[0].share === "equal") {
// checkShared = true
// }
// // Trustees
// let checkTrust = false;
// if(letterdata.trustee.length) {
// checkTrust = true;
// }

const generateDocument = () => {
loadFile(`${letterdatatemplate}`, function(
error,
content
) {
if (error) {
throw error;
}
function angularParser(tag) {
if (tag === '.') {
return {
get: function(s){ return s;}
};
}
const expr = expressions.compile(
tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
);
return {
get: function(scope, context) {
let obj = {};
const index = last(context.scopePathItem);
const scopeList = context.scopeList;
const num = context.num;
for (let i = 0, len = num + 1; i < len; i++) {
obj = assign(obj, scopeList[i]);
}
obj = assign(obj, {"$index": index});
return expr(scope, obj);
}
};
}
const zip = new PizZip(content);
const doc = new Docxtemplater(zip, {parser:angularParser});

doc.setData({
letterName: letterdata.letterName,
letterCategory: letterdata.letterCategory,
idNumber: letterdata._id,
letterContent: letterdata.letterContent,
});
try {
// render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
doc.render();
} catch (error) {
// The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).

// console.log(JSON.stringify({ error: error }, replaceErrors));

if (error.properties && error.properties.errors instanceof Array) {
// const errorMessages = error.properties.errors
//     .map(function(error) {
//     return error.properties.explanation;
// })
// .join("\n");
//console.log("errorMessages", errorMessages);
// errorMessages is a humanly readable message looking like this :
// 'The tag beginning with "foobar" is unopened'
}
throw error;
}
const out = doc.getZip().generate({
type: "blob",
mimeType:
"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}); //Output the document using Data-URI
saveAs(out, `userdata_${letterdata.letterName}.docx`);
});
}

return (
<div className="p-2">
<Button 
    // className="btn-success btn-lg" 
    onClick={generateDocument}
    className='btn d-block w-100' 
    // type='submit'
    variant='custom'
    >
        Download Your Data
</Button>
</div>
);
};

export default GenerateDoc;