import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
//Client Data

//Components
import CalendarFull from '../components/Calendar/CalendarFull'

// import SideBarParagraph from '../components/Sidebar/SidebarParagraph';
//GraphQL
import { useQuery } from '@apollo/client'
import { QUERY_ME } from '../utils/queries'
//Utils and GlobalState
import { usePepperContext } from '../utils/GlobalState'
import { USER_DATA } from '../utils/actions'
import { idbPromise } from '../utils/helpers'
import { useAuthContext } from '../utils/AuthContext'
import { Spinner } from 'react-bootstrap'

//boards should display just that module information
const Calendar = () => {
  // activemodule is hardcoded in JSX below as moduleData[0]; //hardcoded current module instead of useParams
  const { isAuthenticated } = useAuthContext()
  const loggedIn = isAuthenticated
  const [state, dispatch] = usePepperContext()
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME)
  const moduleLoading = false
  /*******/
  const { userdata } = state
  let googleUser =
    //  true;
    userdata.googleUser
  /********/
  useEffect(() => {
    if (userData) {
      dispatch({
        type: USER_DATA,
        userdata: userData.me,
      })
      idbPromise('userdata', 'put', userData.me)
    } else if (!userLoading) {
      idbPromise('userdata', 'get').then((userdata) => {
        dispatch({
          type: USER_DATA,
          userdata: userdata,
        })
      })
    }
    console.log('update')
  }, [userData, userLoading, dispatch])

  
  return (
    <div>
      {loggedIn ? (
        <>
          {googleUser ? (
            <>
              <div className='container'>
                <main>
                  <div>
                    {moduleLoading ? <div className='container text-center'>
    <Spinner />
  </div> : <CalendarFull />}
                  </div>
                </main>
                <hr />
              </div>
            </>
          ) : (
            <h4 className='text-center'>
              You need to use Google to authenticate{' '}
              {<Link to={`/login`}>Login</Link>} and allow access to your GSuite
              account to use this feature
            </h4>
          )}
        </>
      ) : (
        <>
          {localStorage.setItem('referring_url', window.location.href)}
          <h4 className='text-center'>
            You need to {<Link to={`/login`}>Login</Link>} before you can access
            the content of this page.
          </h4>
        </>
      )}
    </div>
  )
}

export default Calendar
