import React, { useState, useEffect } from 'react'
import Auth from '../utils/auth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Form, Button, Alert, Col, Row } from 'react-bootstrap'
import { LOGIN } from '../utils/mutations'
import { useMutation } from '@apollo/client'
import { useAuthContext } from '../utils/AuthContext'
import { ConfigServer} from '../utils/helpers'

const Login = () => {
  const [login, { error }] = useMutation(LOGIN)
  const { isAuthenticated } = useAuthContext()
  const loggedIn = isAuthenticated
  const [errorName, setErrorName] = useState('')
  const [formState, setFormState] = useState({ email: '', password: '' })
  const [passwordShown, setPasswordShown] = useState(false)
  const [validated] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  // Check if user is loggedIn
  useEffect(() => {
    if (loggedIn) {
      navigate('/home')
    }
  }, [loggedIn, navigate])

  useEffect(() => {
    if (location.search.split('?')[1] === 'error') {
      console.log(location.search.split('?')[1])
      setErrorName(`:: Try logging in another method`)
      return setShowAlert(true)
    }
    return setShowAlert(false)
  }, [location])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormState({
      ...formState,
      [name]: value,
    })
  }

  // const googleAuth = (googleUser) => {
  //     let id_token = google
  // }

  // Show/ Hide password
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    // check if form has everything (as per react-bootstrap docs)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    // console.table(formState)

    try {
      // console.log({...formState})
      const { data } = await login({
        variables: { ...formState },
      })
      Auth.login(data.login.token)
      // console.log(data.login.token)
    } catch (e) {
      setErrorName(`:${e}`)
      console.error(e)
      setShowAlert(true)
    }

    setFormState({
      username: '',
      email: '',
      password: '',
    })
  }

  return (
    <div className='container'>
      <Row>
      <Col className='logincard' md={{ span: 9, offset: 2 }}>
          <div className='d-flex justify-content-between'>
            <Link className='btn btn-link justify-left' to='/signup'>
              ← Go to Sign Up
            </Link>
            <Link
              to={`login/forgot-password`}
              className='btn btn-link justify-right'
            >
              Forgot Password →
            </Link>
          </div>

          <h3 className='my-2 text-center'>Login</h3>
          <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <Alert
              dismissible
              onClose={() => setShowAlert(false)}
              show={showAlert}
              variant='danger'
            >
              {errorName.split(':')[2]}
            </Alert>
            <Form.Group className='py-2'>
              <Form.Label htmlFor='email'>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Your email address'
                name='email'
                onChange={handleInputChange}
                value={formState.email}
                required
              />
              <Form.Control.Feedback type='invalid'>
                Email is required!
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='py-2 '>
              <Form.Label htmlFor='password'>Password</Form.Label>
              <div className='pass-wrapper'>
                <Form.Control
                  type={passwordShown ? 'text' : 'password'}
                  placeholder='Your password'
                  name='password'
                  onChange={handleInputChange}
                  value={formState.password}
                  required
                />
                <i
                  style={{ cursor: 'pointer' }}
                  className={
                    passwordShown
                      ? 'px-2 my-auto fa fa-eye'
                      : 'my-auto px-2 fa fa-eye-slash'
                  }
                  onClick={togglePasswordVisiblity}
                ></i>
              </div>
              <Form.Control.Feedback type='invalid'>
                Password is required!
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              disabled={!(formState.email && formState.password)}
              type='submit'
              variant='success'
              className='w-100'
            >
              Login
            </Button>
            {error && <div>Login failed.</div>}
          </Form>
          <div className='my-2 text-center'>
            <a
              className='button-google'
              style={{
                color: 'black',
                textDecoration: 'none',
              }}
              // href='http://localhost:3001/auth/google'
              href={`${ConfigServer.API_URL}/auth/google`}

            >
              <img
                src='images/Google_Calendar_icon.svg'
                alt='google login'
                className='icon'
              ></img>
              Login with Google to Connect Google Calendar
            </a>
          </div>

          <div></div>
        </Col>
      </Row>
    </div>
  )
}

export default Login
