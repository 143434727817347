import React from 'react';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';

const ToolTipCustom = ({ placement, tooltipContent, overlayContent, tooltipVariant, badgeVariant }) => {
  const renderTooltip = (props) => (
    <Tooltip id={`tooltip-${placement}`} {...props} className={`bg-${tooltipVariant}`}>
      {tooltipContent}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={placement}
      overlay={renderTooltip}
    >
      <Badge className="pill-center mx-2 my-2 px-2 py-2" bg={badgeVariant} style={{ fontSize: '0.9em' }}>
        {overlayContent}
      </Badge>
    </OverlayTrigger>
  );
};

export default ToolTipCustom;
