import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Row, Col, Card, Button, Modal, Form, Pagination, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { GET_FEEDS_BY_FEEDID } from '../../utils/queries';
import { ADD_JOB_TO_BOARD, UPDATE_SEARCH_FEED } from '../../utils/mutations';
import { QUERY_ME } from '../../utils/queries';

const JobsTable = () => {
  const { feedId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [boardName, setBoardName] = useState('');
  const [selectedBoard, setSelectedBoard] = useState('');
  const [laneTitle, setLaneTitle] = useState('Wishlist');
  const [toastMessage, setToastMessage] = useState('');
  const [favoriteJobs, setFavoriteJobs] = useState({});

  const { loading: userLoading, data: userData, refetch: userRefetch } = useQuery(QUERY_ME);

  const [updatesearchfeedtagged] = useMutation(UPDATE_SEARCH_FEED, {
    onCompleted: (data) => {
      if (selectedJob) {
        setFavoriteJobs((prevFavorites) => ({
          ...prevFavorites,
          [selectedJob._id]: true
        }));
      }
      userRefetch()
    },
    onError: (error) => {
      console.error('Error updating search feed:', error);
    },
  });

  const [addJobToBoard] = useMutation(ADD_JOB_TO_BOARD, {
    onCompleted: (data) => {
      setToastMessage(`Check out <a href="/boards/${data.addfeedjobtoboard._id}">${data.addfeedjobtoboard.boardName}</a>`);
      if (selectedJob) {
        // Update the feed search after adding the job to the board
        updatesearchfeedtagged({
          variables: {
            id: selectedJob._id,
            tags: "gold"
          }
        });
      }
    },
    onError: (error) => {
      console.error('Error adding job to board:', error);
      alert('Error adding job to board.');
    },
  });

  const { data, loading, error } = useQuery(GET_FEEDS_BY_FEEDID, {
    variables: { feedId },
  });

  useEffect(() => {
    setBoardName(selectedJob?.keyword + ' in ' + selectedJob?.location);
  }, [selectedJob]);

  useEffect(() => {
    if (userData) {
      setSelectedBoard(userData.me.userboards[0]?._id || '');
    }
  }, [userData]);

  if (loading || userLoading) return <div className='container text-center'>
    <Spinner />
  </div>
  if (error) return <p>Error: {error.message}</p>;

  const jobs = data?.searchfeedsbyfeedid || [];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleShowModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleAddJobToBoard = () => {
    if (!selectedJob) {
      console.error('No job selected.');
      return;
    }

    console.log(selectedJob.jobLocationCity)
    addJobToBoard({
      variables: {
        boardName: boardName,
        laneTitle,
        boardId: selectedBoard,
        jobTitle: selectedJob.keyword,
        description: selectedJob.keyword + ' at ' + selectedJob.company + ' in ' + selectedJob.jobLocationCity + ', ' + selectedJob.jobLocationState + '.', 
        actionType: 1,
        jobLocation: selectedJob.jobLocationCity + ', ' + selectedJob.jobLocationState,
        jobSalary: `${selectedJob.maxSalary}`,
        jobUrl: selectedJob.jobkey,
        jobnote: "Write your own notes..."
      }
    }).then(() => {
      handleCloseModal();
    }).catch(error => {
      console.error('Error adding job to board:', error);
      alert('Error adding job to board.');
      handleCloseModal();
    });
  };

  return (
    <>
      <Container className="mt-4">
        {toastMessage && (
          <Alert variant="success" dismissible onClose={() => setToastMessage('')}>
            <span dangerouslySetInnerHTML={{ __html: toastMessage }}></span>
          </Alert>
        )}
        <Row xs={1} md={2} lg={3} className="g-4">
          {currentJobs.map((job) => (
            <Col key={job._id}>
              <Card className="position-relative">
                <Button variant="link" className="position-absolute top-0 end-0 m-2" onClick={() => handleShowModal(job)}>
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    color={favoriteJobs[job._id] || job.tags.includes("gold") ? "gold" : "grey"}
                  />
                </Button>
                <Card.Body>
                  <Card.Title className="w-75">{job.jobTitle}</Card.Title>
                  <Card.Text>
                    <strong>Company:</strong> {job.company} <br />
                    <strong>Location:</strong> {job.jobLocationCity}, {job.jobLocationState} <br />
                    <strong>Job Type:</strong> {job.salaryType} <br />
                    <strong>Salary Range:</strong> {job.minSalary} - {job.maxSalary}
                    <br />
                    <a
                      href={`https://indeed.com/jobs?q=${encodeURIComponent(
                        job.role
                      )}&l=${encodeURIComponent(job.jobLocationCity + ', ' + job.jobLocationState)}&from=searchOnHP&vjk=${job.jobkey}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Job <FontAwesomeIcon size="xs" icon={faExternalLinkAlt} />
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination className="justify-content-center my-4">
          {Array.from({ length: Math.ceil(jobs.length / jobsPerPage) }, (_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Job to Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="boardSelect">
              <Form.Label>Select Board</Form.Label>
              <Form.Control
                as="select"
                value={selectedBoard}
                onChange={(e) => setSelectedBoard(e.target.value)}
              >
                <option value={boardName}>Create New Board</option>
                {userData?.me.userboards.map(board => (
                  <option key={board._id} value={board._id}>{board.boardName}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="laneTitle">
              <Form.Label>Lane title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter lane title"
                value={laneTitle}
                onChange={(e) => setLaneTitle(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddJobToBoard}>
            Add to Board
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobsTable;
