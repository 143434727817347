import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const FeatureCarousel = () => {
  const features = [
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-0.jpg',
      title: 'Fully leverage your contacts inside the Peppercorn Plan Application',
      description: 'Store your professional network in My Contacts. Organize your connections, and customize details for each.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-1.jpg',
      title: 'Create company profiles to plan your peppercorn journey',
      description: 'Research and track target companies in My Companies. Add custom notes, application status, contacts at each company, and more. Keep all your prospect information organized in one spot.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-2.jpg',
      title: 'Kanban Board for your job search',
      description: 'Easily track your progress on a visual board. Create customized Kanban boards to track your job search.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-3.jpg',
      title: 'Integrate your Google Calendar',
      description: 'The My Calendar feature integrates with your Google Calendar, automatically syncing any job search activities and events you schedule. Get notifications and reminders for interviews, applications, and more.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-4.jpg',
      title: 'Review your activities from inside Plan',
      description: 'My Activities gives you a big picture overview of all your job search tasks in one place. Sort and filter by date, priority, or status to stay organized and on top of next steps.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-5.jpg',
      title: 'Review live job listings from inside Plan',
      description: 'Feeds can offer you the latest job listings, matched to the requirements you ask. Stay on pulse.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-6.jpg',
      title: 'Save time with letters from inside Plan',
      description: 'Create customized email and letter templates in My Letters. Access and edit cover letters, follow ups, introductions, and more to reuse with a personal touch.'
    },
    {
      img: 'https://discovery-journey.s3.us-east-2.amazonaws.com/thumbnail-module-7.jpg',
      title: 'Visualize your progress using a metrics tracker',
      description: 'My Metrics provides a way to visualize your efforts with data on your progress.'
    }
  ];

  const scrollLeft = () => {
    const carousel = document.querySelector('.feature-carousel');
    if (carousel) {
      carousel.scrollBy({
        left: -carousel.offsetWidth,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    const carousel = document.querySelector('.feature-carousel');
    if (carousel) {
      carousel.scrollBy({
        left: carousel.offsetWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Container className="my-5">
      <div className="feature-carousel">
        {features.map((feature, index) => (
          <div className="feature-item" key={index}>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={6}>
                <img src={feature.img} alt={feature.title} className="w-100 carousel-img" />
              </Col>
              <Col xs={12} md={6}>
                <div className="text-section">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
      <div className="carousel-arrows text-center mt-3">
        <span onClick={scrollLeft} className="arrow">←</span>
        <span onClick={scrollRight} className="arrow">→</span>
      </div>
    </Container>
  );
}

export default FeatureCarousel;
