import React, { useState } from 'react';
import { Link } from "react-router-dom";
// components
import { Modal, Tab, Card, Row, Col, CardGroup, Button, Badge, Container } from 'react-bootstrap';
// GraphQL
import { useMutation } from '@apollo/client';
import { DELETE_USER_LETTER } from '../utils/mutations';

const LettersRender = (props) => {
  const { user, activemodule, userRefetch } = props;
  const letters = user.userletters;
  let deleteCard = true;
  const [modalValue, setModalValue] = useState({ show: false, letterID: "1" });

  const [deleteuserletter] = useMutation(DELETE_USER_LETTER, {
    onCompleted: async () => userRefetch()
  });

  // Handle Delete
  const handleDelete = async (modalValue) => {
    try {
      // remove from database
      await deleteuserletter({
        variables: { letterId: modalValue.letterID }
      });

      // clear value
      setModalValue({ show: false, letterID: "1" });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        {letters && letters.map((letter) => (
          <Col key={letter._id} className="d-flex justify-content-center">
            <CardGroup>
              <Link to={`/${activemodule.moduleLink}/${letter._id}`} className="text-decoration-none">
                <Card className="text-center modulecard">
                  {deleteCard && (
                    <Button variant='' onClick={(e) => { e.preventDefault(); setModalValue({ show: true, letterID: `${letter._id}` }) }}>
                      <i className="fas fa-trash-alt"></i>
                    </Button>
                  )}
                  <Card.Body>
                    <Card.Title>{letter.letterName}</Card.Title>
                    <Card.Text style={{ textAlign: "left" }}>
                      {letter.letterContent.slice(0, 200)}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className='justify-center'>
                    <small className="text-muted" style={{ fontSize: "0.6rem", textAlign: "left" }}>{letter.letterCategory}</small>
                    <Badge className="pill-center mx-2 my-2" bg='none' style={{ fontSize: "0.6rem" }}>
                      Click to view or edit
                    </Badge>
                  </Card.Footer>
                </Card>
              </Link>
            </CardGroup>
          </Col>
        ))}
      </Row>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        show={modalValue.show}
      >
        <Modal.Body>
          <h5 className="py-4"> Are you sure you want to delete? </h5>
          <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <Row>
              <div className="py-4 d-flex justify-content-center">
                <Button className="btn-tutorial mx-2" onClick={() => handleDelete(modalValue)}>
                  Yes
                </Button>
                <Button className="btn-tutorial mx-2" onClick={() => setModalValue({ show: false, letterID: "1" })}>
                  No
                </Button>
              </div>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default LettersRender;
